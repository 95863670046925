import * as React from "react";

import { matchSorter } from "match-sorter";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { useApp } from "../../../context/App";

function TicketMessageUserSelect({
    receiver,
    setReceiver,
    readOnly,
    drawerWidth,
}) {
    const { client } = useApp();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    //const [inputValue, setInputValue] = React.useState("");

    const fixedOptions = [
        {
            email: "support@livespotting.com",
            first_name: "",
            last_name: "livespotting",
        },
    ];

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active && options.length === 0) {
                const data = await client.GetCustomerUser();
                const newData = data
                    .map(({ email, first_name, last_name }) => ({
                        email,
                        first_name,
                        last_name,
                    }))
                    .sort((a, b) => (a.email > b.email ? 1 : -1))
                    .filter((obj, index, array) => {
                        const currentIndex = array.findIndex(
                            (item) => item.email === obj.email,
                        );
                        return currentIndex === index;
                    });
                setOptions([
                    ...newData.filter(
                        (option) => fixedOptions.indexOf(option) === -1,
                    ),
                ]);
                //setOptions(newData)
            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, {
            keys: ["email", "first_name", "last_name"],
        });

    return (
        <Autocomplete
            id="ticket-receiver"
            style={{ minWidth: drawerWidth - 155 }}
            multiple
            size="medium"
            //value={receiver}
            value={[...fixedOptions, ...receiver]}
            // onChange={(event, newValue) => {
            //     console.log(newValue)
            //     setReceiver(newValue);
            // }}
            onChange={(event, newValue) => {
                setReceiver([
                    ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1,
                    ),
                ]);
                console.log(newValue);
            }}
            // inputValue={[...fixedOptions, ...inputValue]}
            // onInputChange={(event, newInputValue) => {
            //     console.log(newInputValue)
            //     setInputValue(newInputValue);
            // }}

            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            groupBy={(option) => option.email.split("@")[1]}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) =>
                option.email === value.email
            }
            getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
            }
            options={options}
            loading={loading}
            disableClearable
            //readOnly={readOnly}
            fullWidth
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        size="small"
                        xs={{ disabled: { color: "#000" } }}
                        style={{ textTransform: "initial", fontWeight: 500 }}
                        label={`${option.first_name} ${option.last_name}`}
                        {...getTagProps({ index })}
                        disabled={fixedOptions.indexOf(option) !== -1}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant="standard"
                    label="Beteiligte"
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

TicketMessageUserSelect.propTypes = {
    receiver: PropTypes.object.isRequired,
    setReceiver: PropTypes.any.isRequired,
    readOnly: PropTypes.bool,
    drawerWidth: PropTypes.number,
};

TicketMessageUserSelect.defaultProps = {
    readOnly: true,
    drawerWidth: 540,
};

export default TicketMessageUserSelect;
