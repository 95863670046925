import * as React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function RestreamSchedulerDates({ formValues, handleChange }) {
	return (
		<Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2} width={'100%'}>
			<TextField
				id="datetime-local"
				label="Start"
				type="datetime-local"
				name="schedule_start"
				value={formValues.schedule_start}
				onChange={handleChange}
				InputLabelProps={{
					sx: {
						backgroundColor: '#fff',
						shrink: true,
					},
				}}
				fullWidth
				variant="outlined"
				size="small"
				color="secondary"
			/>
			<TextField
				id="datetime-local"
				label="Ende"
				type="datetime-local"
				name="schedule_end"
				value={formValues.schedule_end}
				onChange={handleChange}
				InputLabelProps={{
					sx: {
						backgroundColor: '#fff',
						shrink: true,
					},
				}}
				fullWidth
				variant="outlined"
				size="small"
				color="secondary"
			/>
		</Stack>
	);
}

function RestreamScheduler({ formValues, setFormValues, handleChange }) {
	React.useEffect(() => {
		if (formValues.schedule && formValues.schedule_start !== '') {
			if (formValues.schedule_end !== '') {
				const start = Date.parse(formValues.schedule_start);
				const end = Date.parse(formValues.schedule_end);
				const differenceInSeconds = (end - start) / 1000;
				setFormValues((prevValues) => ({
					...prevValues,
					scheduler: new Date(formValues.schedule_start).toISOString(),
					runtime_duration_seconds: differenceInSeconds,
				}));
			} else {
				setFormValues((prevValues) => ({
					...prevValues,
					scheduler: new Date(formValues.schedule_start).toISOString(),
					runtime_duration_seconds: null,
				}));
			}
		} else if (!formValues.schedule) {
			setFormValues((prevValues) => ({
				...prevValues,
				scheduler: null,
				runtime_duration_seconds: null,
			}));
		}
		// eslint-disable-next-line
	}, [formValues.schedule, formValues.schedule_start, formValues.schedule_end]);

	return (
		<>
			<FormGroup>
				<FormControlLabel
					color="success"
					control={<Checkbox checked={formValues.schedule ? formValues.schedule : false} onChange={handleChange} name="schedule" />}
					sx={{
						color: '#000!important',
						'&.Mui-checked': {
							color: '#000!important',
						},
						'&.MuiCheckbox-indeterminate': {
							color: '#000!important',
						},
					}}
					label="Planen"
				/>
			</FormGroup>
			{formValues.schedule && <RestreamSchedulerDates formValues={formValues} handleChange={handleChange} />}
		</>
	);
}

RestreamScheduler.propTypes = {
	formValues: PropTypes.object.isRequired,
	setFormValues: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
};

RestreamScheduler.defaultProps = {};

export default RestreamScheduler;
