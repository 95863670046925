/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        root: {
            backgroundColor: base.palette.primary.main,
            "&.Mui-selected": {
                backgroundColor: base.palette.background.dark1,
            },
        },
        button: {
            "&:hover": {
                backgroundColor: `${base.palette.background.darker1} !important`,
            },
        },
    },
};
