import * as React from "react";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import Logo from "../../images/livespotting.svg";

import Start from "./Steps/Start";
import Profile from "./Steps/Profile";
import AcceptPrivacy from "./Steps/AcceptPrivacy";
import PasswordChange from "./Steps/PasswordChange";
import TwoFactorAuth from "./Steps/TwoFactorAuth";
import End from "./Steps/End";

import { useApp } from "../../context/App";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

const useStyles = makeStyles({
    customStepper: {
        width: "100%",
        padding: "0px!important",
    },
});

function Wizard() {
    const { client } = useApp();

    // STEPPER
    const theme = useTheme();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const maxSteps = 5;
    const [disableBack, setDisableBack] = React.useState(false);
    const [disableNext, setDisableNext] = React.useState(false);

    // PW CHANGE
    const generatePassword = (length) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        let password = "";
        for (let i = 0; i < length; i++) {
            password += characters.charAt(
                Math.floor(Math.random() * characters.length),
            );
        }

        return password;
    };
    const password =
        generatePassword(6) +
        "-" +
        generatePassword(6) +
        "-" +
        generatePassword(6);

    // FORM
    const user = client.UserConfig();

    // PROFILE
    const [userData, setUserData] = React.useState(null);
    const [registered, setRegistered] = React.useState(false);
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [formValues, setFormValues] = React.useState({
        avatar: null,
        first_name: null,
        last_name: null,
        company: null,
        role: "",
        description: null,
        email_opt_in: false,
        phone: null,
        phone_opt_in: false,
        mobile: null,
        mobile_opt_in: false,
        password: password,
        password_confirm: "",
        terms_accepted: false,
    });
    const handleChangeFormValues = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    };

    const getUserData = async () => {
        const data = await client.GetUser({ email: user.email });
        if (data) {
            setUserData(data);
            setFormValues({
                first_name: data.first_name,
                last_name: data.last_name,
                role: data.role,
                company: data.company,
                description: data.description,
                avatar_url: data.avatar_url,
                email_opt_in: data.email_opt_in,
                phone: data.phone,
                phone_opt_in: data.phone_opt_in,
                mobile: data.mobile,
                mobile_opt_in: data.mobile_opt_in,
                password: formValues.password,
                password_confirm: formValues.password_confirm,
                terms_accepted: formValues.terms_accepted,
            });
            setUploadedFile(data.avatar_url);
        }
    };

    React.useEffect(() => {
        if (!userData) {
            getUserData();
        }
        // eslint-disable-next-line
    }, []);

    const putUserRegistration = async () => {
        if (userData && !registered) {
            const payload = {
                first_name: formValues.first_name,
                last_name: formValues.last_name,
                role: formValues.role,
                company: formValues.company,
                description: formValues.description,
                email_opt_in: formValues.email_opt_in,
                phone: formValues.phone,
                phone_opt_in: formValues.phone_opt_in,
                mobile: formValues.mobile,
                mobile_opt_in: formValues.mobile_opt_in,
                password: formValues.password,
                password_confirm: formValues.password_confirm,
                terms_accepted: formValues.terms_accepted,
            };
            try {
                let isReged = await client.PutUserRegistration({
                    data: payload,
                });
                if (uploadedFile !== formValues.avatar_url) {
                    isReged = await client.PutUserAvatar({
                        image: uploadedFile,
                    });
                }
                if (isReged && !isReged.detail) {
                    setRegistered(true);
                } else {
                    handleBack();
                }
            } catch (error) {
                handleBack();
            }
        }
    };

    React.useEffect(() => {
        if (activeStep === 4) {
            putUserRegistration();
        }
        // eslint-disable-next-line
    }, [activeStep]);

    // 2FA
    const [otpEnabled, setOtpEnabled] = React.useState(false);
    const generateOtpSecet = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        let secret = "";
        for (let i = 0; i < length; i++) {
            secret += characters.charAt(
                Math.floor(Math.random() * characters.length),
            );
        }

        return secret;
    };
    const [otpData, setOtpData] = React.useState({
        opt_enable: false,
        otp_secret: generateOtpSecet(16),
        otp_reset_secret: generatePassword(160),
        otp_token: "",
    });
    const handleChangeOtpData = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setOtpData((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
    };
    const postOtpData = async () => {
        if (otpData && !otpEnabled) {
            try {
                let otp_create = await client.PostUserTwoFactorAuth({
                    data: {
                        otp_secret: otpData.otp_secret,
                        otp_reset_secret: otpData.otp_reset_secret,
                        otp_token: otpData.otp_token,
                    },
                });
                if (otp_create && !otp_create.detail) {
                    setOtpEnabled(true);
                } else {
                    setOtpEnabled(false);
                    handleBack();
                }
            } catch (error) {
                setOtpEnabled(false);
                handleBack();
            }
        }
    };

    React.useEffect(() => {
        if (otpData.opt_enable && activeStep === 5) {
            postOtpData();
        }
        // eslint-disable-next-line
    }, [otpData, activeStep]);

    // END
    const logout = async () => {
        await client.logout();
    };

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            height={"100vh"}
            width={"100%"}
            margin={"-8px -8px -8px 0px"}
        >
            <Stack></Stack>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-left"
                spacing={1}
            >
                <ServiceIcon
                    style={{
                        width: 40,
                        height: 40,
                        position: "fixed",
                        left: "1.1em",
                        top: "1.3em",
                    }}
                />
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper
                        elevation={1}
                        sx={{
                            borderRadius: 2,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            width={450}
                            marginBottom={1}
                        >
                            {activeStep === 0 && (
                                <Start
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                />
                            )}
                            {activeStep === 1 && (
                                <Profile
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                    user={user}
                                    formValues={formValues}
                                    handleChangeFormValues={
                                        handleChangeFormValues
                                    }
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                />
                            )}
                            {activeStep === 2 && (
                                <PasswordChange
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                    user={user}
                                    formValues={formValues}
                                    handleChangeFormValues={
                                        handleChangeFormValues
                                    }
                                />
                            )}
                            {activeStep === 3 && (
                                <AcceptPrivacy
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                    terms_accepted={formValues.terms_accepted}
                                    handleChangeFormValues={
                                        handleChangeFormValues
                                    }
                                />
                            )}
                            {/* {activeStep === 4 && (
                                <TwoFactorAuth
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                    disableBack={disableBack}
                                    setDisableBack={setDisableBack}
                                    registered={registered}
                                    user={user}
                                    otpEnabled={otpEnabled}
                                    setOtpEnabled={setOtpEnabled}
                                    otpData={otpData}
                                    handleChangeOtpData={handleChangeOtpData}
                                />
                            )} */}
                            {activeStep === 4 && (
                                <End
                                    disableNext={disableNext}
                                    setDisableNext={setDisableNext}
                                    disableBack={disableBack}
                                    setDisableBack={setDisableBack}
                                    otpEnabled={otpEnabled}
                                    otpData={otpData}
                                />
                            )}
                            <MobileStepper
                                className={classes.customStepper}
                                variant="text"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    activeStep !== 4 ? (
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            onClick={handleNext}
                                            disabled={
                                                activeStep === maxSteps - 1 ||
                                                disableNext
                                            }
                                        >
                                            {activeStep <= 2 && (
                                                <span>Weiter</span>
                                            )}
                                            {activeStep === 3 && (
                                                <span>Abschließen</span>
                                            )}
                                            {theme.direction === "rtl" ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button>
                                    ) : (
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            onClick={logout}
                                        >
                                            Erneut einloggen
                                        </Button>
                                    )
                                }
                                backButton={
                                    <Button
                                        size="small"
                                        color="success"
                                        variant="outlined"
                                        onClick={handleBack}
                                        disabled={
                                            (activeStep === 0 && !registered) ||
                                            disableBack
                                        }
                                    >
                                        {theme.direction === "rtl" ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Zurück
                                    </Button>
                                }
                            />
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
            <Stack></Stack>
        </Stack>
    );
}

Wizard.propTypes = {};

Wizard.defaultProps = {};

export default Wizard;
