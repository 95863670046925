import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

const findPlayer = (players, player_id) => {
    if (!players) {
        return { id: "", name: "" };
    }

    const player = players.find((element) => {
        return element.id === player_id;
    });

    return player ? player : { id: "", name: "" };
};

function AnalyticsPlayerSelect({ players, apiQuery, setApiQuery }) {
    const [isSlected, setIsSelected] = React.useState(
        findPlayer(players, apiQuery.player_id ? apiQuery.player_id : ""),
    );

    if (!players) {
        return null;
    }

    const options = players
        .map(({ id, name }) => ({
            id: id,
            name: name,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((obj, index, array) => {
            const currentIndex = array.findIndex(
                (item) => item.name === obj.name,
            );
            return currentIndex === index;
        })
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

    const selectedPlayerValue = (player) => {
        const { id, name } = player;
        return {
            id: id,
            name: name,
        };
    };

    return (
        <Autocomplete
            value={isSlected && isSlected.id ? selectedPlayerValue(isSlected) : null}
            size="small"
            id="player-select"
            options={options ? options : []}
            getOptionLabel={(option) => `${option.name}`}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
                setIsSelected(newValue);
                setApiQuery((prevApiQuery) => ({
                    ...prevApiQuery,
                    player_id: newValue ? newValue.id : null,
                }));
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={isSlected.id ? null : "Player"}
                    color={isSlected.id ? "secondary" : "primary"}
                    sx={{
                        width: isSlected.id ? "200px" : null,
                    }}
                />
            )}
        />
    );
}

AnalyticsPlayerSelect.propTypes = {
    players: PropTypes.array,
    setApiQuery: PropTypes.func.isRequired,
};

AnalyticsPlayerSelect.defaultProps = {};

export default AnalyticsPlayerSelect;
