import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import UserRole from "../../../components/UserButton/UserRole";
import UserAvatarUpload from "../../../components/UserButton/UserAvatarUpload";

function Profile({
    disableNext,
    setDisableNext,
    user,
    formValues,
    uploadedFile,
    setUploadedFile,
    handleChangeFormValues,
}) {
    React.useEffect(() => {
        if (!formValues.email_opt_in) {
            setDisableNext(true);
        } else {
            setDisableNext(false);
        }
    }, [formValues.email_opt_in]);

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginBottom={2}
            spacing={1.5}
        >
            <Grid item xs={12}>
                <Typography variant="h4">Ihr Profil</Typography>
                <Divider height={1} />
            </Grid>
            <Grid item xs={4}>
                <Paper
                    elevation={2}
                    sx={{
                        borderRadius: 2,
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        height: 100,
                        width: 100,
                        marginBottom: 1,
                    }}
                >
                    <UserAvatarUpload
                        id="avatar"
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    autoComplete="ls-profile given-name"
                    label="Vorname"
                    name="first_name"
                    value={formValues.first_name || ""}
                    onChange={handleChangeFormValues}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    autoComplete="ls-profile family-name"
                    label="Nachname"
                    name="last_name"
                    value={formValues.last_name || ""}
                    onChange={handleChangeFormValues}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Firma"
                    name="company"
                    value={formValues.company || ""}
                    onChange={handleChangeFormValues}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <UserRole
                    value={formValues.role || ""}
                    handleChange={handleChangeFormValues}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Zusätzliche Beschreibung"
                    name="description"
                    value={formValues.description || ""}
                    onChange={handleChangeFormValues}
                    fullWidth
                    autoFocus
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="email"
                    readOnly
                    label="E-Mail Adresse"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={user.email}
                    error={user.email && !formValues.email_opt_in}
                    helperText={
                        user.email && !formValues.email_opt_in
                            ? "Bitte den E-Mail Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                    marginTop={"8px"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            control={
                                <Checkbox
                                    color="success"
                                    name="email_opt_in"
                                    checked={formValues.email_opt_in}
                                    onChange={handleChangeFormValues}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="phone"
                    readOnly
                    label="Telefonnummer"
                    type="phone"
                    fullWidth
                    variant="standard"
                    value={formValues.phone || ""}
                    onChange={handleChangeFormValues}
                    error={formValues.phone && !formValues.phone_opt_in}
                    helperText={
                        formValues.phone && !formValues.phone_opt_in
                            ? "Bitte den Telefon Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                    marginTop={"8px"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            disabled={
                                !formValues.phone ||
                                formValues.phone.length === 0
                            }
                            control={
                                <Checkbox
                                    color="success"
                                    name="phone_opt_in"
                                    checked={formValues.phone_opt_in}
                                    onChange={handleChangeFormValues}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="mobile"
                    readOnly
                    label="Handynummer"
                    type="mobile"
                    fullWidth
                    variant="standard"
                    value={formValues.mobile || ""}
                    onChange={handleChangeFormValues}
                    error={formValues.mobile && !formValues.mobile_opt_in}
                    helperText={
                        formValues.mobile && !formValues.mobile_opt_in
                            ? "Bitte den Handy Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                    marginTop={"8px"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            disabled={
                                !formValues.mobile ||
                                formValues.mobile.length === 0
                            }
                            control={
                                <Checkbox
                                    color="success"
                                    name="mobile_opt_in"
                                    checked={formValues.mobile_opt_in}
                                    onChange={handleChangeFormValues}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
        </Grid>
    );
}

Profile.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    handleChangeFormValues: PropTypes.func.isRequired,
    uploadedFile: PropTypes.array,
    setUploadedFile: PropTypes.func.isRequired,
};

Profile.defaultProps = {};

export default Profile;
