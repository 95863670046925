/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            "&.Mui-selected": {
                backgroundColor: "rgba(0, 0, 0,.055)!important",
            },
            "&.MuiTableRow-hover:hover": {
                backgroundColor: "rgba(0, 0, 0,.025)!important",
            },
        },
    },
};
