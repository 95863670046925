import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CopyURLButton = () => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [tooltipText, setTooltipText] = React.useState("Kopieren");

    const handleCopyClick = () => {
        const currentURL = window.location.href;

        navigator.clipboard
            .writeText(currentURL)
            .then(() => {
                setTooltipText("Kopiert");
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                    setTooltipText("Kopieren");
                }, 2000);
            })
            .catch((error) => {
                setTooltipText("Kopieren");
            });
    };

    return (
        <Tooltip title={tooltipText} arrow open={tooltipOpen} placement="top">
            <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                disableRipple
                disableFocusRipple
                sx={{ boxShadow: 2 }}
            >
                <Button
                    size="medium"
                    onClick={handleCopyClick}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                >
                    <ContentCopyIcon fontSize="inherit" />
                </Button>
            </ButtonGroup>
        </Tooltip>
    );
};

function PageSidebarHeader(props) {
    // NO FULLSCREEN OPTION ON MOBILE
    const theme = useTheme();
    const upSm = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            marginTop={{ xs: 2, sm: 0.5 }}
            marginLeft={{ xs: 4, sm: 0 }}
            marginBottom={{ xs: -1.5, sm: 3.5 }}
            paddingRight={0}
        >
            <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                disableRipple
                disableFocusRipple
                sx={{ boxShadow: 2 }}
            >
                {props.handleSidebarFullscreenToggle &&
                    !props.sidebarFullScreen &&
                    upSm && (
                        <Button
                            size="medium"
                            onClick={props.handleSidebarFullscreenToggle}
                        >
                            <OpenInFullIcon fontSize="inherit" />
                        </Button>
                    )}
                {props.handleSidebarFullscreenToggle &&
                    props.sidebarFullScreen &&
                    upSm && (
                        <Button
                            size="medium"
                            onClick={props.handleSidebarFullscreenToggle}
                        >
                            <CloseFullscreenIcon fontSize="inherit" />
                        </Button>
                    )}
                {props.handleSidebarToggle && (
                    <Button size="medium" onClick={props.handleSidebarToggle}>
                        <CloseIcon fontSize="inherit" />
                    </Button>
                )}
            </ButtonGroup>
            {props.share && <CopyURLButton />}
        </Stack>
    );
}

PageSidebarHeader.propTypes = {
    sidebarFullScreen: PropTypes.bool,
    handleSidebarFullscreenToggle: PropTypes.func,
    handleSidebarToggle: PropTypes.func,
    share: PropTypes.bool,
};

PageSidebarHeader.defaultProps = {
    sidebarFullScreen: false,
    handleSidebarFullscreenToggle: null,
    handleSidebarToggle: null,
    share: true,
};

export default PageSidebarHeader;
