/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        tooltip: {
            fontSize: ".9rem",
            fontWeight: "normal",
            color: "#000",
            textAlign: "center",
            boxShadow: base.shadows[2],
            backgroundColor: "#fff",
        },
        arrow: {
            color: "#fff",
        },
    },
};
