/* eslint-disable import/no-anonymous-default-export */

export default {
    styleOverrides: {
        root: {
            "&:focus": {
                border: "0px solid rgba(0,0,0,.18)",
            },
            "&:hover": {
                border: "0px solid rgba(0,0,0,.18)",
            },
        },
        colorPrimary: {
            border: "0px solid #fff",
            borderWidth: 0,
            "&:focus": {
                border: "0px solid rgba(0,0,0,.18)",
            },
            "&:hover": {
                border: "0px solid rgba(0,0,0,.18)",
            },
        },
        colorSecondary: {
            border: "1px solid rgba(0,0,0,.18)",
            borderWidth: "1px",
            "&:focus": {
                border: "0px solid rgba(0,0,0,.18)",
            },
            "&:hover": {
                border: "1px solid rgba(0,0,0,.18)",
            },
            notchedOutline: {
                borderWidth: 0,
            },
        },
        notchedOutline: {
            borderWidth: 0,
        },
        input: {
            "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #fff inset",
                WebkitTextFillColor: "#000",
            },
            "&:focus": {
                border: "0px solid rgba(0,0,0,.18)",
            },
            "&:hover": {
                border: "0px solid rgba(0,0,0,.18)",
            },
        },
    },
};
