import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

function VideoTagSelect({ tags, disabled, readOnly }) {
    return (
        <Autocomplete
            multiple
            size="small"
            id="tags-filled"
            value={tags.filter((tag) => tag !== "")}
            options={tags.filter((tag) => tag !== "")}
            fullWidth
            disabled={disabled}
            readOnly={readOnly}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="standard"
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} variant="standard" label="Tags" />
            )}
        />
    );
}

VideoTagSelect.propTypes = {
    tags: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

VideoTagSelect.defaultProps = {
    tags: [],
    disabled: true,
    readOnly: true,
};

export default VideoTagSelect;
