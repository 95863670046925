import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";

export default function AnalyticsChartSeriesSelect({ chartSeriesSelect, setChartSeriesSelect }) {
  const handleChange = (event) => {
    setChartSeriesSelect(event.target.value ? event.target.value : null);
  };

  return (
    <TextField
      select
      size="small"
      id="anlytics-chartseries-select"
      value={chartSeriesSelect}
      //label="Schritt"
      onChange={handleChange}
      color="secondary"
      variant="outlined"
    >
      <MenuItem value="session_hits">Zugriffe</MenuItem>
      <MenuItem value="session_hits_avg">Durchschnittliche Zugriffe</MenuItem>
      <MenuItem value="session_seconds">Wiedergabedauer</MenuItem>
      <MenuItem value="session_seconds_avg">Durchschnittliche Wiedergabezeit</MenuItem>
      {/* <MenuItem value="session_parallel">Maximale gleichzeitige Zuschauer</MenuItem> */}
    </TextField>
  );
}