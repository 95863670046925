import * as React from "react";

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import VideocamIcon from "@mui/icons-material/Videocam";

function TicketObjectDetail(props) {
    // camera: {status: str, alert_msg: str, alert_created: date, alert_updated: date}

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    if (!props.item_details) {
        return null;
    }

    let color = "primary";
    if (!props.color) {
        if (
            props.item_details.status &&
            props.item_details.status !== "healthy"
        ) {
            color = "danger";
        } else {
            color = "success";
        }
    } else {
        color = props.color;
    }

    return (
        <React.Fragment>
            <Chip
                size={props.size}
                icon={<VideocamIcon />}
                label={props.item_details.name}
                color={color}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ marginTop: props.marginTop }}
            />
            {props.item_details.sla && props.item_details.sla !== "STD" && (
                <Chip
                    size={props.size}
                    label={`SLA ${props.item_details.sla}`}
                    color="primary"
                />
            )}
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                width={"100%"}
            >
                <Grid
                    container
                    justifyContent="flex-start"
                    fontSize={14}
                    style={{
                        backgroundColor: "#fff",
                        borderRadius: "6px",
                        padding: 10,
                        width: 250,
                    }}
                >
                    <Grid item xs={12} fontWeight={600}>
                        Aktueller Verbindungsstatus:
                    </Grid>
                    <Grid item xs={12}>
                        {props.item_details.status === "healthy"
                            ? "Verbunden"
                            : props.item_details.status === "unhealthy"
                            ? "Verbindungsfehler"
                            : "Unbekannt"}
                    </Grid>
                </Grid>
            </Popover>
        </React.Fragment>
    );
}

TicketObjectDetail.propTypes = {
    item_details: PropTypes.object,
    size: PropTypes.string,
    marginTop: PropTypes.number,
    color: PropTypes.string,
};

TicketObjectDetail.defaultProps = {
    item_details: null,
    size: "medium",
    marginTop: 0,
    color: null,
};

export default TicketObjectDetail;
