/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            width: "100%",
            marginTop: "0.35em",
            marginBottom: "0.35em",
            borderTop: `2px solid #B3B3B3`,
        },
    },
};
