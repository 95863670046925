import * as React from "react";

import "moment-timezone";
import moment from "moment";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import Moment from "react-moment";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import SupportIcon from "@mui/icons-material/Support";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import SidebarTabPanel from "../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../components/SidebarTabPanelProps";
import VideoJS from "../../../components/VideoJS";

import CameraMeta from "./CameraMeta";
import CameraTag from "./CameraTag";
import CameraSettings from "./CameraSettings";
import CameraStream from "./CameraStream";

import CameraAlert from "./CameraAlert";
import CameraSidebarMenu from "./CameraSidebarMenu";

import { useApp } from "../../../context/App";


function CameraSidebar({ drawerWidth, camera, playerConfig }) {
    const { hasPermission } = useApp();
    // TABS
    const [tab, setTab] = React.useState(0);
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    // PERMISSION CHECK
    const cameraAdminRoles = ["admin", "camera:admin"];
    const cameraEditorRoles = cameraAdminRoles.concat([
        "editor",
        "camera:editor",
    ]);

    // VIDEOJS
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        poster: playerConfig.url + camera.id + "/live/main_240.jpg",
        playsinline: true,
        muted: true,
        // html5: {
        //     hls: {
        //         overrideNative: true,
        //         limitRenditionByPlayerDimensions: true,
        //         useDevicePixelRatio: true
        //     },
        //     nativeAudioTracks: false,
        //     nativeVideoTracks: false,
        //     useBandwidthFromLocalStorage: true
        // },
        sources: [
            {
                src:
                    playerConfig.url +
                    camera.id +
                    "/live/main.m3u8?token=" +
                    playerConfig.token,
                type: "application/vnd.apple.mpegurl",
            },
        ],
    };

    return (
        <Stack sx={{ maxWidth: drawerWidth }} width={"100%"}>
            <Stack
                style={{
                    padding: "10px 0px 0px 0px",
                }}
                marginLeft={"-20px"}
                marginRight={"-20px"}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    textTransform={"uppercase"}
                    lineHeight={"normal"}
                    padding={"0px 20px 10px 20px"}
                >
                    {camera.meta.name}
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    padding={"0px 20px 20px 20px"}
                >
                    {camera.status === "healthy" && (
                        <Chip
                            size="small"
                            icon={<CheckCircleIcon style={{ color: "#fff" }} />}
                            color="success"
                            label="Verbunden"
                        />
                    )}
                    {camera.status === "unhealthy" && (
                        <Chip
                            size="small"
                            icon={<ErrorIcon style={{ color: "#fff" }} />}
                            color="danger"
                            label="Verbindungsfehler"
                        />
                    )}
                    {camera.status === "unknown" && (
                        <Chip
                            size="small"
                            icon={<CancelIcon style={{ color: "#fff" }} />}
                            color="danger"
                            label="Unbekannter Zustand"
                        />
                    )}
                    {camera.sla && (
                        <Chip
                            size="small"
                            icon={<SupportIcon style={{ color: "#fff" }} />}
                            style={{
                                color: "#fff",
                                backgroundColor: "#0085E9",
                            }}
                            label={
                                camera.sla > 0 ? `SLA ${camera.sla}` : `SLA`
                            }
                        />
                    )}
                </Stack>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    spacing={1}
                    padding={"0px 20px 20px 20px"}
                >
                    <VideoJS {...videoJsOptions} />
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                marginTop={0}
                borderTop={"1px solid rgba(0, 0, 0, 0.12)"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
            >
                <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label="Meta" {...SidebarTabPanelProps(0)} />
                    <Tab label="Stream" {...SidebarTabPanelProps(1)} />
                    <Tab label="Störung" {...SidebarTabPanelProps(2)} />
                    <Tab
                        label="Einstellung"
                        {...SidebarTabPanelProps(3)}
                        disabled={!hasPermission(cameraEditorRoles)}
                    />
                </Tabs>
                <CameraSidebarMenu
                    cameraAdminRoles={cameraAdminRoles}
                    cameraEditorRoles={cameraEditorRoles}
                    camera={camera}
                />
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        marginBottom: 5,
                    }}
                    spacing={0}
                >
                    <Stack
                        style={{
                            padding: "10px 15px 10px 15px",
                            marginTop: 10,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: 15,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0.5}
                    >
                        <Typography fontSize={14}>
                            <strong>ID:</strong> {camera.id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Alias ID:</strong> {camera.alias_id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(camera.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {camera.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(camera.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </Stack>
                    <Typography marginTop={0.5} marginBottom={1.5} variant="h4">
                        Standort
                    </Typography>
                    <Stack
                        style={{
                            padding: "10px 15px 15px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0}
                    >
                        <CameraMeta meta={camera.meta} />
                    </Stack>
                    <Typography marginTop={2} marginBottom={1.5} variant="h4">
                        Tagging
                    </Typography>
                    <Stack
                        style={{
                            padding: "10px 15px 15px 15px",
                            marginBottom: 20,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0}
                    >
                        <CameraTag meta={camera.meta} />
                    </Stack>
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    style={{
                        marginBottom: 5,
                    }}
                    spacing={0}
                >
                    <CameraStream camera={camera} />
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={2} padding={0}>
                <CameraAlert camera={camera} />
            </SidebarTabPanel>
            {hasPermission(cameraEditorRoles) && (
                <SidebarTabPanel value={tab} index={3} padding={0}>
                    <CameraSettings camera={camera} playerConfig={playerConfig} />
                </SidebarTabPanel>
            )}
        </Stack>
    );
}

CameraSidebar.propTypes = {
    drawerWidth: PropTypes.number,
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
};

CameraSidebar.defaultProps = {
    drawerWidth: 450,
};

export default CameraSidebar;
