/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            marginBottom: "-.1em",
            marginTop: "-.3em",
        },
        label: {
            fontSize: "14px", // Hier kannst du die gewünschte Schriftgröße setzen
        },
    },
};
