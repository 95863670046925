import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Moment from "react-moment";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import TicketMessageMenu from "./TicketMessageMenu";

function TicketMessage(props) {
    if (!props.message || !props.backgroundColor) {
        return null;
    }
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
            backgroundColor={props.backgroundColor}
            padding={"20px 20px 30px 20px"}
            style={{ fontSize: 15, fontWeight: 400 }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Paper
                    elevation={2}
                    sx={{
                        borderRadius: 2,
                        padding: 1,
                        p: "0px 0px",
                        display: "flex",
                        alignItems: "center",
                        height: 25,
                    }}
                >
                    <Avatar
                        variant="rounded"
                        alt={`${props.message.creator.first_name} ${props.message.creator.last_name}`}
                        src={props.message.creator.avatar}
                        sx={{
                            width: 25,
                            height: 25,
                            borderRadius: 1,
                            backgroundColor: "rgba(0, 0, 0, .65)",
                        }}
                    ></Avatar>
                </Paper>
            </Stack>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                width={"100%"}
                paddingLeft={1}
                paddingRight={1}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <span>
                        <strong>
                            {props.message.creator.first_name}{" "}
                            {props.message.creator.last_name}
                        </strong>{" "}
                        am
                        <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                            {moment(props.message.created_at).tz(
                                moment.tz.guess(),
                            )}
                        </Moment>
                    </span>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    fontSize={16}
                >
                    {props.message.body}
                </Stack>
                {props.message.created_at !== props.message.updated_at && (
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        fontSize={12}
                    >
                        <span>
                            <strong>Bearbeitet</strong> am
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(props.message.updated_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </span>
                    </Stack>
                )}
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    height={30}
                >
                    {props.latest && (
                        <Chip size="small" label="NEUSTE" color="primary" />
                    )}
                    <TicketMessageMenu />
                </Stack>
            </Stack>
        </Stack>
    );
}

TicketMessage.propTypes = {
    message: PropTypes.object,
    backgroundColor: PropTypes.string,
    latest: PropTypes.bool,
};

TicketMessage.defaultProps = {
    message: null,
    backgroundColor: null,
    latest: false,
};

export default TicketMessage;
