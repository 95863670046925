import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { visuallyHidden } from '@mui/utils';

import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import ErrorIcon from '@mui/icons-material/Error';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import fallback from '../../images/fallback.png';

import PublicationProviderRegistry from './PublicationProviderRegistry';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name',
	},
	{
		id: 'camera',
		numeric: false,
		disablePadding: false,
		label: 'Kamera',
	},
	{
		id: 'publication_provider',
		numeric: false,
		disablePadding: true,
		label: 'Plattform',
	},
	{
		id: 'schedule_start',
		numeric: false,
		disablePadding: true,
		label: 'Geplant',
	},
	{
		id: 'status',
		numeric: true,
		disablePadding: false,
		label: 'Status',
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
};

function CameraImage({ id, playerConfig }) {
	const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

	React.useEffect(() => {
		const img = new Image();
		img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
		img.onload = () => {
			setBackgroundImage(`url(${img.src})`);
		};
	}, []);

	return (
		<Paper
			elevation={0}
			sx={{
				backgroundImage: backgroundImage,
				backgroundSize: 'cover',
				padding: 0,
				height: 30,
				minWidth: 55,
				borderRadius: 1,
				border: '1px solid #f1f1f1',
			}}
		/>
	);
}

function ExhancedRow({ restreams, cameras }) {
	if (!restreams || !cameras) {
		return null;
	}
	const publicationProviderRegistry = PublicationProviderRegistry();

	for (let i = 0; i < restreams.length; i++) {
		for (let j = 0; j < cameras.length; j++) {
			if (restreams[i].camera_id === cameras[j].id) {
				restreams[i].camera = cameras[j];
			}
		}
		for (let j = 0; j < publicationProviderRegistry.length; j++) {
			if (restreams[i].publication_provider === publicationProviderRegistry[j].id) {
				restreams[i].publication_provider = publicationProviderRegistry[j];
			}
		}
	}
	return restreams;
}

export default function RestreamTable({ restreams, setRestream, restreamSelected, setRestreamSelected, cameras, playerConfig }) {
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('name');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);

	const rows = ExhancedRow({ restreams, cameras });

	const navigate = useNavigate();
	const isSelected = (id) => restreamSelected === id;

	const handleSelected = (restream) => {
		setRestreamSelected(restream.id);
		setRestream(restream);
		navigate(`/restream/${restream.id}`);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [searchText, setSearchText] = React.useState('');

	const handleChangeSearch = (event) => {
		setSearchText(event.target.value);
		setPage(0);
	};

	const filteredRows = React.useMemo(() => {
		const lowerSearchText = searchText.toLowerCase();
		return rows.filter((row) => row.name.toLowerCase().includes(lowerSearchText));
	}, [rows, searchText]);

	const visibleRows = React.useMemo(
		() => stableSort(filteredRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[filteredRows, order, orderBy, page, rowsPerPage],
	);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
						<EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										onClick={() => handleSelected(row)}
										tabIndex={-1}
										key={row.id}
										aria-checked={isItemSelected}
										selected={isItemSelected}
										sx={{ cursor: 'pointer' }}
									>
                                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                                            {row.name}
										</TableCell>
										<TableCell component="th" id={labelId} scope="row" padding="normal">
											<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
												<CameraImage id={row.camera_id} playerConfig={playerConfig} />
												<span>{row.camera.meta.name}</span>
											</Stack>
										</TableCell>
										<TableCell component="th" id={labelId} scope="row" padding="normal">
											<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
												{row.publication_provider.icon}
												<span>{row.publication_provider.name}</span>
											</Stack>
										</TableCell>
										<TableCell component="th" id={labelId} scope="row" padding="normal">
											{row.scheduler}
										</TableCell>
										<TableCell align="right">
											{row.status === 'healthy' && (
												<Chip
													size="small"
													icon={<CheckCircleIcon style={{ color: '#fff', margin: '0px -10px 0px 5px' }} />}
													color="success"
													style={{ padding: 0 }}
												/>
											)}
											{row.status === 'unhealthy' && (
												<Chip
													size="small"
													icon={<ErrorIcon style={{ color: '#fff', margin: '0px -10px 0px 5px' }} />}
													color="danger"
													style={{ padding: 0 }}
												/>
											)}
											{row.status === 'unknown' && (
												<Chip
													size="small"
													icon={<CancelIcon style={{ color: '#fff', margin: '0px -10px 0px 5px' }} />}
													color="danger"
													style={{ padding: 0 }}
												/>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage="Player pro Seite"
					rowsPerPageOptions={[50, 100, 250]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
}
