import * as React from "react";

import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Verwende den Adapter für moment.js
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import moment from "moment";
import "moment/locale/de";


export function DateSelect({ label, value, setValue, views, color, minDateTime, maxDateTime }) {

    const handleChange = (newValue) => {
        if (newValue.isValid()) {
            const date = new Date(newValue);
            if (!views.includes("minutes")) {
                date.setMinutes(0, 0, 0);
            }
            setValue(date);
        }
    };

    // Erstelle eine moment.js-Zeitzoneinstanz für die lokale Zeitzone
    const localTimeZone = moment.tz.guess(); // Ermittelt die lokale Zeitzone

    // Konvertiere den bestehenden 'value' in die lokale Zeitzone
    const valueInLocalTimeZone = value ? moment.tz(value, localTimeZone) : null;

    moment.locale("de");
    const momentConfig = { moment };

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateFormats={momentConfig}
            adapterLocale="de"
        >
            <MobileDateTimePicker
                label={label}
                color={color}
                variant="outlined"
                selectedSections={"day"}
                minutesStep={!views.includes("minutes") ? 0 : 1}
                hoursStep={!views.includes("hours") ? 0 : 1}
                displayWeekNumber
                orientation="landscape"
                timezone={localTimeZone}
                value={valueInLocalTimeZone}
                maxDateTime={maxDateTime}
                minDateTime={minDateTime}
                onChange={handleChange}
                slotProps={{ 
                    textField: {
                        InputLabelProps: {
                            sx: {
                                backgroundColor: '#fff',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                            }
                        },
                        inputProps: { 
                            sx: { 
                                padding: "10px 14px",
                            }
                        },
                        sx: {
                            borderRadius: 1, 
                            border: color === "secondary" ? "1px solid rgba(0,0,0,.2)" : null,
                        }
                    }
                }}
            />
        </LocalizationProvider>
    );
}

DateSelect.propTypes = {
    label : PropTypes.string.isRequired,
    value : PropTypes.instanceOf(moment),
    setValue : PropTypes.func.isRequired,
    views : PropTypes.array,
    color: PropTypes.string,
    minDateTime: PropTypes.instanceOf(moment),
    maxDateTime: PropTypes.instanceOf(moment),
};

DateSelect.defaultProps = {
    views : ['year', 'day', 'hours', 'minutes'],
    color: "primary",
    minDateTime: moment().seconds(0).subtract(14, "days"),
    maxDateTime: moment().seconds(0),
};

export default DateSelect;