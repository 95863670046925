import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

export default function Facebook(props) {
	return {
		id: 'facebook',
		verion: '1.0',
		name: 'Facebook',
		description: 'Stream to Facebook',
		category: 'Social',
		help_link: 'https://www.facebook.com/help/587160588142067',
		icon: <FontAwesomeIcon icon={faFacebook} style={{ color: '#2D88FF', ...props }} />,
		stream_key_link: 'https://www.facebook.com/live/producer',
		stream_requirements: {
			video_codec: ['h264'],
			audio_codec: ['aac'],
			video_resolution: ['1280x720'],
			video_fps: {
				min: 15,
				max: 60,
			},
			video_bitrate: {
				min: 300,
				max: 4000,
			},
			audio_bitrate: {
				min: 64,
				max: 160,
			},
			audio_channels: 2,
		},
		stream_targets: [
			{
				address: 'rtmps://live-api-s.facebook.com:443/rtmp/{token}',
				options: ['-f', 'flv'],
				type: 'primary',
				protocol: 'rtmp',
				region: 'international',
			},
		],
	};
}
