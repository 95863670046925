import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import Paper from "@mui/material/Paper";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Stack from "@mui/material/Stack";

import MarkdownEditor from "../../../components/MarkdownEditor";

import TicketObjectDetail from "../TicketObjectDetail";

import TicketMessage from "./TicketMessage";
import TicketMessageUserSelect from "./TicketMessageUserSelect";

export default function TicketSidebar(props) {
    const [messages, setMessages] = React.useState(false);
    const [receiver, setReceiver] = React.useState(false);
    if (!props.ticket) {
        return null;
    }

    if (!messages) {
        setMessages(
            props.ticket.messages.sort((a, b) =>
                a.created_at > b.created_at ? 1 : -1,
            ),
        );
    }
    if (!receiver) {
        let receiver_list = [];
        for (const i in props.ticket.last_message.receiver) {
            receiver_list.push({
                title: props.ticket.last_message.receiver[i].name,
            });
        }
        setReceiver(props.ticket.last_message.receiver);
    }

    function MsgBg(index) {
        const isEven = index % 2 === 0;
        if (isEven) {
            return "rgba(0,0,0,.035)";
        } else {
            return "rgba(0,0,0,.0)";
        }
    }

    return (
        <Stack width={props.drawerWidth}>
            <DialogTitle
                id="scroll-dialog-title"
                style={{
                    padding: "10px 0px 0px 0px",
                    //boxShadow: '0px 14px 14px -14px rgba(0,0,0,0.6)',
                    //borderBottom: '2px solid rgba(0,0,0,.18)',
                }}
                marginLeft={"-20px"}
                marginRight={"-20px"}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    textTransform={"uppercase"}
                    lineHeight={"normal"}
                    padding={"0px 20px 10px 20px"}
                >
                    {props.ticket.topic}
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    padding={"0px 20px 0px 20px"}
                >
                    {props.ticket.item_details && (
                        <TicketObjectDetail
                            marginTop={2}
                            size="small"
                            item_details={props.ticket.item_details}
                        />
                    )}
                </Stack>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    spacing={1}
                    padding={"0px 20px 20px 20px"}
                    style={{
                        marginTop: 8,
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    <Chip
                        size="small"
                        icon={<PlayCircleOutlineIcon />}
                        label="Offen"
                        color="success"
                    />
                    <span>
                        Erstellt von{" "}
                        <strong>{props.ticket.creator.name}</strong> am
                        <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                            {moment(props.ticket.created_at).tz(
                                moment.tz.guess(),
                            )}
                        </Moment>
                    </span>
                </Stack>
            </DialogTitle>
            <Stack
                style={{
                    padding: "0px 8px 0px 8px",
                    marginLeft: -25,
                    marginRight: -25,
                    marginBottom: -16,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <Stack>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        fontSize={14}
                        marginTop={-2}
                    >
                        <Grid item xs={12} padding={2}>
                            {messages &&
                                messages.length > 0 &&
                                messages.map((message, index) => (
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            borderRadius: 0,
                                            padding: 0,
                                            backgroundColor: "#fff",
                                            width: "100%",
                                        }}
                                    >
                                        <TicketMessage
                                            key={message.id}
                                            value={message.id}
                                            latest={
                                                index === messages.length - 1
                                            }
                                            message={message}
                                            backgroundColor={MsgBg(index)}
                                        />
                                    </Paper>
                                ))}
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            <Stack
                style={{
                    padding: "20px 25px 25px 25px",
                    //backgroundColor: 'rgba(0,0,0,0.035)',
                    //padding: '12px 0 24px 0',
                    marginLeft: -25,
                    marginRight: -25,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    padding={"0px 0px 42px 0px"}
                    style={{
                        fontSize: 14,
                        fontWeight: 400,
                    }}
                >
                    {/* <TicketDialogReceiver receiver={receiver} /> */}
                    <TicketMessageUserSelect
                        receiver={receiver}
                        setReceiver={setReceiver}
                    />
                </Stack>
                <Stack
                    padding={0}
                    sx={{
                        backgroundColor: "#fff",
                        borderTop: "1px solid rgba(0,0,0,.15)",
                        width: "100%",
                        marginTop: -4,
                        marginBottom: -30,
                    }}
                >
                    <MarkdownEditor />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        paddingTop={1}
                        paddingBottom={3}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Ticket schließen"
                            />
                        </FormGroup>
                        <Button variant="outlined" color="success">
                            Antworten
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
