import * as React from "react";

import "moment-timezone";
import moment from "moment";
import Moment from "react-moment";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import CoreSelect from "../CoreSelect";
import AvatarUpload from "../AvatarUpload";

function initCustomer(customer) {
    const initCustomer = {
        crm_id: "",
        billing_id: "",
        core_id: "",
        name: "",
        website: "",
        avatar_url: "",
        email: "",
        billing_email: null,
        sla: null,
        id: null,
        updated_at: null,
        created_at: null,
        ...customer,
    };

    return initCustomer;
}

function CustomerForm(props) {
    const customer = initCustomer(props.customer);

    const handleChange = (what) => (event) => {
        const customer = {
            ...props.customer,
            [what]: event.target.value,
        };

        props.onChange(customer);
    };

    const handleAvatarChange = (url) => {
        const customer = {
            ...props.customer,
            avatar_url: url,
        };

        props.onChange(customer);
    };

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginTop={1}
            marginBottom={2}
            spacing={1.5}
        >
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        borderRadius: 2,
                        padding: 1,
                        p: "0px 0px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        height: 100,
                        width: 100,
                    }}
                >
                    <AvatarUpload
                        id="avatar"
                        url={customer.avatar_url}
                        onChange={handleAvatarChange}
                    />
                </Paper>
            </Grid>
            {customer.id && (
                <Grid item xs={12}>
                    <Typography fontSize={14}>
                        <strong>ID:</strong> {customer.id}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Name"
                    value={customer.name || ""}
                    onChange={handleChange("name")}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Email"
                    value={customer.email || ""}
                    onChange={handleChange("email")}
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Webseite"
                    value={customer.website || ""}
                    onChange={handleChange("website")}
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="text"
                    label="CRM ID"
                    value={customer.crm_id || ""}
                    onChange={handleChange("crm_id")}
                    fullWidth
                    disabled={props.mode !== "add"}
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="text"
                    label="Billing ID"
                    value={customer.billing_id || ""}
                    onChange={handleChange("billing_id")}
                    fullWidth
                    disabled={props.mode !== "add"}
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    type="text"
                    label="Billing Email"
                    value={customer.billing_email || ""}
                    onChange={handleChange("billing_email")}
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <CoreSelect
                    value={customer.core_id || ""}
                    onChange={handleChange("core_id")}
                    disabled={props.mode !== "add"}
                />
            </Grid>
            {(customer.created_at || customer.updated_at) && (
                <Grid item xs={12}>
                    {customer.created_at && (
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(customer.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                    )}
                    {customer.updated_at && (
                        <Typography fontSize={14}>
                            <strong>Aktualisiert:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(customer.updated_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

CustomerForm.propTypes = {
    mode: PropTypes.oneOf(["add", "edit"]),
    customer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

CustomerForm.defaultProps = {
    mode: "",
    customer: {},
    onChange: () => {},
};

export default CustomerForm;
