import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// import Autocomplete from "@mui/material/Autocomplete";
// import InputAdornment from '@mui/material/InputAdornment';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import Paper from '@mui/material/Paper';
// import SearchIcon from "@mui/icons-material/Search";
import Stack from '@mui/material/Stack';
// import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

import { useApp } from '../../context/App';

import Page from '../../components/Page';
import PublicationProviderRegistry from './PublicationProviderRegistry';
import { RestreamCreateDialog, RestreamCreateForm } from './RestreamCreate';
import RestreamSidebar from './RestreamSidebar';
import RestreamTable from './RestreamTable';

function ContentHeader({ cameras }) {
	return (
		<React.Fragment>
			{/* <Autocomplete
                size="small"
                //options={options ? options : []}
                //onInputChange={onInputChange}
                clearOnBlur={false}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                }
                onChange={(e, v) => {
                    //setMetaSearch(v ? v.title : null);
                }}
                sx={{
                    width: "100%",
                    maxWidth: 450,
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="restream_search"
                        label=""
                        color="primary"
                        variant="outlined"
                        placeholder="Suche..."
                        sx={{
                            m: '.5em 1em 0.5em 0em',
                            boxShadow: 1,
                            borderRadius: 1,
                            background: "#fff",
                            border: "1px solid rgba(0,0,0,.2)"
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                noOptionsText={"Kein Ergebnis gefunden"}
                fullWidth
            /> */}
			<RestreamCreateDialog cameras={cameras} />
		</React.Fragment>
	);
}

function RestreamWelcome({ restreams, cameras, publicationProviderRegistrySorted, hidePublicationProvider }) {
	if (!restreams) {
		return null;
	}
	return (
		<Stack direction="column" justifyContent="center" alignItems="center" spacing={2} width={'100%'} height={'calc(100vh - 12vw)'}>
			<Paper
				elevation={0}
				sx={{
					width: 500,
					border: '1px solid rgba(0,0,0,.1)',
					backgroundColor: 'rgb(255,255,255,1)',
					boxShadow:
						'0px 2px 1px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 10px 30px 0px rgba(0,0,0,0.12), 0px 15px 20px 0px rgba(0,0,0,0.12)',
				}}
			>
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} paddingTop={-1} paddingBottom={3}>
					<MissedVideoCallIcon sx={{ color: '#0085E9', fontSize: 38 }} />
					<Typography variant="h4" color="textSecondary" sx={{ textAlign: 'left' }}>
						Restream erstellen
					</Typography>
				</Stack>
				{cameras && <RestreamCreateForm cameras={cameras} abortBtn={false} />}
			</Paper>
			<Typography variant="body2" color="textSecondary" paddingTop={2} sx={{ textAlign: 'center' }}>
				Starte oder plane dein erstes Multistreaming. Kinderleicht <br />
				und superschnell auf folgende Plattformen:
			</Typography>
			<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} paddingTop={0}>
				{publicationProviderRegistrySorted.map(
					(provider, index) =>
						!hidePublicationProvider.some((id) => provider.id.includes(id)) && (
							<span key={index} top={2}>
								{provider.icon}
							</span>
						),
				)}
			</Stack>
		</Stack>
	);
}

function Restream() {
	const navigate = useNavigate();
	const { client, backdrop, reload, setSelectedPage, hasPermission } = useApp();
	const [ready, setReady] = React.useState(false);

	// PAGE SIDEBAR
	const [drawerWidth, setDrawerWidth] = React.useState(550);
	const [openSidebar, setOpenSidebar] = React.useState(false);

	const handleSidebarToggle = () => {
		setOpenSidebar(false);
		setRestream(null);
		setRestreamSelected(null);
		navigate(`/restream`);
	};

	// PLAYER OBJECT
	const [playerConfig, setPlayerConfig] = React.useState(false);
	const getPlayerConfig = async () => {
		if (!playerConfig) {
			const data = client.PlayerConfig();
			setPlayerConfig(data);
		}
		return playerConfig;
	};

	// PERMISSION CHECK
	const restreamAdminRoles = ['admin', 'restream:admin'];
	const restreamEditorRoles = restreamAdminRoles.concat(['editor', 'restream:editor']);

	// RESTREAM VALUES
	const [restreams, setRestreams] = React.useState(null);
	const { restream_id } = useParams();
	const [restream, setRestream] = React.useState(null);
	const [restreamSelected, setRestreamSelected] = React.useState(null);

	const statsRef = React.useRef(restreams);
	const loadingRef = React.useRef(!restreams);

	const [cameras, setCameras] = React.useState(null);
	const getCameras = async () => {
		return await client.GetCustomerCamera({ limit: 0 });
	};

	const getRestreams = async () => {
		return await client.GetCustomerRestream({ limit: 0, hide: 'ingest' });
	};

	React.useEffect(() => {
		(async () => {
			setSelectedPage('restream');
			const data = await getCameras();
			getPlayerConfig();
			setCameras(data);
			const restreams = await getRestreams();
			setRestreams(restreams);
			if (restream_id && !restream) {
				for (const r in data) {
					if (data[r].id === restream_id) {
						setRestream(data[r]);
						setOpenSidebar(true);
					}
				}
			}
			handleSidebarToggle();
			setReady(true);
			return () => {
				setReady(false);
			};
		})();
		// eslint-disable-next-line
	}, [reload]);

	React.useEffect(() => {
		backdrop(!ready);
	}, [ready, backdrop]);

	React.useEffect(() => {
		if (restreams && restream) {
			setOpenSidebar(true);
		}
	}, [restream]);

	React.useEffect(() => {
		const fetchStats = async () => {
			try {
				if (!statsRef.current && restreams) {
					try {
						const stats = await client.GetCustomerRestreamsStats({
							metric: ['healthy'],
						});
						if (stats && restreams && cameras && ready) {
							for (const restream of restreams) {
								const restreamStats = stats.find((stat) => stat.restream_id === restream.id);
								if (restreamStats) {
									restream.status = restreamStats.healthy ? 'healthy' : 'unhealthy';
								} else {
									restream.status = 'unknown';
								}
							}
							setRestreams(restreams);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} catch (error) {
				console.log(error.response.data);
			}
		};

		if (loadingRef.current) {
			fetchStats();
			const intervalId = setInterval(fetchStats, 10000);
			return () => clearInterval(intervalId);
		}
	}, []);

	const publicationProviderRegistry = PublicationProviderRegistry({ width: 50, height: 25, color: 'rgba(0,0,0,.75)' });
	const publicationProviderRegistrySorted = publicationProviderRegistry.sort((a, b) => a.name.localeCompare(b.name));
	const hidePublicationProvider = ['rtmp', 'srt', 'hls'];

	return (
		<Page
			drawerWidth={550}
			openSidebar={openSidebar}
			handleSidebarToggle={handleSidebarToggle}
			sidebarContent={
				restream && (
					<RestreamSidebar
						key={restream.id}
						drawerWidth={drawerWidth}
						restream={restream}
						playerConfig={playerConfig}
						adminRoles={restreamAdminRoles}
						editorRoles={restreamEditorRoles}
					/>
				)
			}
			headerContentLeft={
				restreams && restreams.length > 0 && hasPermission(restreamAdminRoles) && <ContentHeader cameras={cameras} />
			}
		>
			<Stack
				direction="row"
				justifyContent={!restreams ? 'center' : 'space-between'}
				alignItems="center"
				spacing={2}
				marginTop={0}
				marginLeft={'-25px'}
				marginRight={'-25px'}
			>
				{restreams && restreams.length > 0 && (
					<RestreamTable
						restreams={restreams}
						setRestream={setRestream}
						restreamSelected={restreamSelected}
						setRestreamSelected={setRestreamSelected}
						cameras={cameras}
						playerConfig={playerConfig}
					/>
				)}
				{!restreams ||
					(restreams.length === 0 && hasPermission(restreamAdminRoles) && (
						<RestreamWelcome
							restreams={restreams}
							cameras={cameras}
							publicationProviderRegistrySorted={publicationProviderRegistrySorted}
							hidePublicationProvider={hidePublicationProvider}
						/>
					))}
			</Stack>
		</Page>
	);
}

Restream.propTypes = {
	openMenu: PropTypes.bool,
	handleMenuToggle: PropTypes.func,
	setSelectedPage: PropTypes.func,
};

Restream.defaultProps = {
	openMenu: false,
};

export default Restream;
