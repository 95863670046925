import * as React from "react";

import { visuallyHidden } from "@mui/utils";

import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

import AdminSubMenu from "./AdminSubMenu";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Beschreibung",
    },
    {
        id: "cameras",
        numeric: true,
        disablePadding: false,
        label: "Kameras",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function AdminACL({ acls, setACL }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const rows = acls;

    const [selected, setSelected] = React.useState(null);
    const isSelected = (id) => {
        return selected === id;
    };
    const handleClick = (event, row) => {
        setSelected(row.id);
        setACL(row);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchText, setSearchText] = React.useState("");

    const handleChangeSearch = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    };

    const filteredRows = React.useMemo(() => {
        const lowerSearchText = searchText.toLowerCase();
        return rows.filter(
            (row) =>
                row.id.includes(lowerSearchText) ||
                row.name.toLowerCase().includes(lowerSearchText) ||
                row.description.toLowerCase().includes(lowerSearchText),
        );
    }, [rows, searchText]);

    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [filteredRows, order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "flex-start", sm: "space-between" }}
                alignItems="flex-start"
                spacing={2}
                minHeight={75}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="flex-left"
                    spacing={2}
                    maxWidth={550}
                    padding={"0px 25px 30px 25px"}
                >
                    <span>
                        Über Zugriffssteuerungslisten (ACL) können sowohl die
                        Zugänge, als auch die Videoplayer auf eine gewünschte
                        Auswahl an Kameras limitiert werden.
                    </span>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="center"
                    spacing={2}
                >
                    <Paper
                        sx={{
                            //borderRadius: 2,
                            padding: "1em 0em 1em 0em",
                            width: 350,
                            minWidth: { xs: 50, md: 250 },
                            display: "flex",
                            alignItems: "center",
                            height: 40,
                        }}
                    >
                        <TextField
                            //label="Suche"
                            placeholder="Suche"
                            variant="outlined"
                            value={searchText}
                            onChange={handleChangeSearch}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                        />
                    </Paper>
                    {/* <AdminSubMenu /> */}
                </Stack>
            </Stack>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row)
                                        }
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isSelected(row.id)}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell align="right">
                                            {row.cameras.length}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="ACL's pro Seite"
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
