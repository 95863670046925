import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import 'moment-timezone';

import Autocomplete from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import ErrorIcon from '@mui/icons-material/Error';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import Page from '../../components/Page';

import CameraSidebar from './CameraSidebar';

import fallback from '../../images/fallback.png';

import CameraFilter from './CameraFilter';

import { useApp } from '../../context/App';

const CameraHealth = ({ health }) => {
	const [hover, setHover] = React.useState(false);
	return (
		<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.5} paddingBottom={0.8} paddingRight={1.2} width={'100%'}>
			{health === 'unhealthy' && (
				<Chip
					size="small"
					icon={<ErrorIcon style={{ color: '#fff' }} />}
					color="danger"
					label={hover ? 'Verbindungsfehler' : null}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					sx={{ p: hover ? '.5em .2em' : '.5em 0em .5em .4em', zIndex: 1 }}
				/>
			)}
			{health === 'unknown' && (
				<Chip
					size="small"
					icon={<CancelIcon style={{ color: '#fff' }} />}
					color="danger"
					label={hover ? 'Unbekannter Zustand' : ''}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					sx={{ p: hover ? '.5em .2em' : '.5em 0em .5em .4em', zIndex: 1 }}
				/>
			)}
		</Stack>
	);
};

const CameraResolution = ({ streams }) => {
	// [
	//     {
	//         "format": "hls",
	//         "codec": "h264, aac",
	//         "resolution": "1280x720",
	//         "bitrate": 530.22,
	//         "fps": 24.87
	//     }
	// ]

	if (streams.length === 0) {
		return null;
	}
	streams.sort((a, b) => a.resolution.split('x')[1] - b.resolution.split('x')[1]);

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} paddingTop={0.8} paddingLeft={1.2} width={'100%'}>
			{streams.map((stream, index) => {
				if (stream.profile !== 'tv') {
					return (
						<Chip
							key={index}
							size="small"
							label={stream.resolution.split('x')[1]}
							sx={{ backgroundColor: '#0085E9', color: '#fff', fontWeight: 800, p: '.5em .1em', zIndex: 1 }}
						/>
					);
				} else {
					return (
						<Chip
							key={index}
							size="small"
							label="TV"
							sx={{ backgroundColor: '#1DAD22', color: '#fff', fontWeight: 800, p: '.5em .1em', zIndex: 1 }}
						/>
					);
				}
			})}
		</Stack>
	);
};

function CameraItem({ camera, playerConfig, handleSelectCamera }) {
	const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

	React.useEffect(() => {
		const img = new Image();
		const ts = Date.now();
		img.src = `${playerConfig.url}${camera.id}/live/main_240.jpg?${ts}`;
		img.onload = () => {
			setBackgroundImage(`url(${img.src})`);
		};
	}, []);

	return (
		<Grid
			item
			key={camera.id}
			xs={12}
			sm={12}
			md={6}
			lg={4}
			xl={3}
			xxl={2}
			alignItems="flex-start"
			style={{ cursor: 'pointer' }}
			onClick={handleSelectCamera(camera)}
		>
			<Paper
				elevation={2}
				sx={{
					position: 'relative',
					backgroundImage: backgroundImage,
					backgroundSize: 'cover',
					backgroundColor: 'transparent',
					padding: 0,
					paddingTop: '56.25%',
					height: 0,
					width: 'auto',
					borderRadius: 1,
				}}
			>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					width={'100%'}
					height={'100%'}
					sx={{ position: 'absolute', top: 0, left: 0 }}
				>
					<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
						<CameraResolution key={camera.id} streams={camera.streams} />
					</Stack>
					<Stack direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
						<CameraHealth health={camera.status} />
					</Stack>
				</Stack>
			</Paper>
			<Stack marginTop={0.8} marginBottom={1.4} spacing={0}>
				<Typography
					variant="h5"
					//marginBottom={0}
					sx={{
						textTransform: 'uppercase',
						fontSize: '1rem',
						fontWeight: 500,
						color: 'rgba(0, 0, 0, 0.7)',
						lineHeight: '1.1rem',
						marginBottom: '0.3em',
						marginTop: '0.3em',
					}}
				>
					{camera.meta.name}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{camera.meta.city}
				</Typography>
			</Stack>
		</Grid>
	);
}

function CameraList({ cameras, page, pageSize, playerConfig, handleSelectCamera }) {
	const paginateArray = (array, pageNumber, pageSize) => {
		const page = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
		return page;
	};

	const ts = Math.floor(Date.now() / 1000 / 60); // timestamp in minutes

	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} marginTop={'-7px'}>
			{paginateArray(cameras, page, pageSize).map((camera) => (
				<CameraItem key={camera.id} camera={camera} playerConfig={playerConfig} handleSelectCamera={handleSelectCamera} />
			))}
		</Grid>
	);
}

CameraList.propTypes = {
	cameras: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	playerConfig: PropTypes.object.isRequired,
	handleSelectCamera: PropTypes.func.isRequired,
};

CameraList.defaultProps = {};

function Camera() {
	const navigate = useNavigate();
	const { client, backdrop, setSelectedPage, reload } = useApp();
	const [ready, setReady] = React.useState(false);

	// PAGE SIDEBAR
	const [openSidebar, setOpenSidebar] = React.useState(false);
	const handleSidebarToggle = () => {
		setOpenSidebar(!openSidebar);
		navigate(`/camera`);
	};

	// PLAYER OBJECT
	const [playerConfig, setPlayerConfig] = React.useState(false);
	const getPlayerConfig = async () => {
		if (!playerConfig) {
			const data = client.PlayerConfig();
			setPlayerConfig(data);
		}
		return playerConfig;
	};

	// CAMERA OBJECTS
	const { camera_id } = useParams();
	const [cameras, setCameras] = React.useState(false);
	const [camera, setCamera] = React.useState(false);
	const handleSelectCamera = React.useCallback(
		(camera) => () => {
			setCamera(camera);
			navigate(`/camera/${camera.id}`);
			setOpenSidebar(true);
		},
		[navigate],
	);

	const statsRef = React.useRef(cameras);
	const loadingRef = React.useRef(!cameras);

	// PAGINATION
	const [page, setPage] = React.useState(1);
	const handleChange = (event, value) => {
		setPage(value);
	};
	const [pageSize, setPageSize] = React.useState(50);
	const handlePageSize = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};

	// META SEARCH AND SORT
	const [meta_search, setMetaSearch] = React.useState(null);
	const [sort, setSort] = React.useState('city');
	const handleSort = (event) => {
		setSort(event.target.value);
	};
	const [sortDirection, setSortDirection] = React.useState('asc');
	const handleSortDirection = (event) => {
		setSortDirection(event.target.value);
	};
	const [status, setStatus] = React.useState('none');
	const handleStatusChange = (event) => {
		setStatus(event.target.value);
	};
	const [producer, setProducer] = React.useState(true);
	const handleProducerChange = (event) => {
		setProducer(event.target.checked);
	};
	const onInputChange = (event, value, reason) => {
		if (event && event.type === 'blur') {
			setMetaSearch('');
		} else if (reason !== 'reset') {
			setMetaSearch(value);
		}
	};
	const [options, setOptions] = React.useState(null);

	const autoCompleteOptions = React.useCallback(() => {
		if (!meta_search || meta_search.length === 0 || !cameras) {
			setOptions(null);
			return;
		}
		const newOptions = new Set();
		cameras.forEach((camera) => {
			const { meta } = camera;
			if (meta) {
				const { name, city, region, country, tags, producer } = meta;
				const metaSearchLower = meta_search.toLowerCase(); // meta_search in Kleinbuchstaben umwandeln
				if (name && name.toLowerCase().includes(metaSearchLower)) {
					newOptions.add(name);
				}
				if (city && city.toLowerCase().includes(metaSearchLower)) {
					newOptions.add(city);
				}
				if (region && region.toLowerCase().includes(metaSearchLower)) {
					newOptions.add(region);
				}
				if (country && country.toLowerCase().includes(metaSearchLower)) {
					newOptions.add(country);
				}
				if (tags) {
					tags.forEach((tag) => {
						if (tag.toLowerCase().includes(metaSearchLower)) {
							newOptions.add(tag);
						}
					});
				}
				if (producer) {
					if (producer.toLowerCase().includes(metaSearchLower)) {
						newOptions.add(producer);
					}
				}
			}
		});
		const formattedOptions = Array.from(newOptions).map((option) => ({
			title: option,
		}));
		setOptions(formattedOptions);
	}, [meta_search, cameras]);

	const filterCameras = (cameras) => {
		return cameras
			.filter((item) => (status === 'none' || item.status === status) && (producer || !item.meta.producer))
			.sort((a, b) => {
				let aValue = sort === 'created_at' ? a.created_at : a.meta[sort];
				let bValue = sort === 'created_at' ? b.created_at : b.meta[sort];
				return (sortDirection === 'asc' ? aValue > bValue : aValue < bValue) ? 1 : -1;
			});
	};

	const filteredCameras = React.useMemo(() => {
		if (!Array.isArray(cameras)) {
			return [];
		}
		setPage(1);
		return filterCameras(cameras);
		// eslint-disable-next-line
	}, [cameras, status, producer, sort, sortDirection]);

	const getCameras = async () => {
		const params = {
			meta_search: meta_search,
		};
		const data = await client.GetCustomerCamera(params);
		setCameras(data);
		autoCompleteOptions();
		if (camera_id && !camera) {
			for (const c in data) {
				if (data[c].id === camera_id) {
					setCamera(data[c]);
					setOpenSidebar(true);
				}
			}
		}
		setPage(1);
	};

	React.useEffect(() => {
		(async () => {
			setSelectedPage('camera');
			await getCameras();
			await getPlayerConfig();
			setReady(true);
			return () => {
				setReady(false);
			};
		})();
		// eslint-disable-next-line
	}, [reload]);

	React.useEffect(() => {
		backdrop(!ready);
	}, [ready, backdrop]);

	React.useEffect(() => {
		if (cameras) {
			getCameras();
		}
		// eslint-disable-next-line
	}, [meta_search]);

	React.useEffect(() => {
		const fetchStats = async () => {
			try {
				if (!statsRef.current && cameras) {
					try {
						const stats = await client.GetCustomerCamerasStats({
							metric: ['healthy'],
						});
						if (stats && cameras) {
							for (const camera of cameras) {
								const cameraStats = stats.find((stat) => stat.camera_id === camera.id);
								if (cameraStats) {
									camera.status = cameraStats.healthy ? 'healthy' : 'unhealthy';
								} else {
									camera.status = 'unknown';
								}
							}
							setCameras(cameras);
						}
					} catch (error) {
						console.log(error);
					}
				}
				if (cameras) {
					
				}
			} catch (error) {
				console.log(error.response.data);
			}
		};

		if (loadingRef.current) {
			fetchStats();
			const intervalId = setInterval(fetchStats, 10000);
			return () => clearInterval(intervalId);
		}
	}, []);

	return (
		<Page
			drawerWidth={550}
			openSidebar={openSidebar}
			handleSidebarToggle={handleSidebarToggle}
			headerContentLeft={
				<React.Fragment>
					<Autocomplete
						size="small"
						options={options ? options : []}
						onInputChange={onInputChange}
						clearOnBlur={false}
						getOptionLabel={(option) => option.title}
						isOptionEqualToValue={(option, value) => option.title === value.title}
						onChange={(e, v) => {
							setMetaSearch(v ? v.title : null);
						}}
						sx={{
							width: '100%',
							maxWidth: 450,
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								name="camera_search"
								label=""
								color="primary"
								variant="outlined"
								placeholder="Suche..."
								sx={{
									m: '.5em 1em 0.5em 0em',
									boxShadow: 1,
									borderRadius: 1,
									background: '#fff',
									border: '1px solid rgba(0,0,0,.2)',
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{params.InputProps.endAdornment}
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
										</React.Fragment>
									),
								}}
							/>
						)}
						noOptionsText={'Kein Ergebnis gefunden'}
						fullWidth
					/>
					<CameraFilter
						sort={sort}
						handleSort={handleSort}
						sortDirection={sortDirection}
						handleSortDirection={handleSortDirection}
						status={status}
						handleStatusChange={handleStatusChange}
						producer={producer}
						handleProducerChange={handleProducerChange}
					/>
				</React.Fragment>
			}
			sidebarContent={camera ? <CameraSidebar key={camera.id} drawerWidth={550} camera={camera} playerConfig={playerConfig} /> : null}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2} marginTop={1.5}>
				<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} width={'100%'}>
					<Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={0} paddingBottom={5}>
						{cameras && (
							<CameraList
								cameras={filteredCameras}
								page={page}
								pageSize={pageSize}
								playerConfig={playerConfig}
								handleSelectCamera={handleSelectCamera}
							/>
						)}
					</Stack>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} marginBottom={2}>
						<Paper
							sx={{
								p: '4px 8px',
								borderRadius: 2,
								display: 'flex',
								alignItems: 'center',
							}}
							component="form"
						>
							<Pagination
								count={filteredCameras ? Math.ceil(filteredCameras.length / pageSize) : 0}
								page={page}
								onChange={handleChange}
								variant="outlined"
								shape="rounded"
							/>
							<FormControl size="small" variant="outlined" color="primary">
								<Select value={pageSize} onChange={handlePageSize}>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={50}>50</MenuItem>
									<MenuItem value={100}>100</MenuItem>
									<MenuItem value={250}>250</MenuItem>
								</Select>
							</FormControl>
						</Paper>
					</Stack>
				</Stack>
			</Stack>
		</Page>
	);
}

Camera.propTypes = {
	openMenu: PropTypes.bool,
	handleMenuToggle: PropTypes.func,
	setSelectedPage: PropTypes.func,
};

Camera.defaultProps = {
	openMenu: false,
};

export default Camera;
