import * as React from "react";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";


export function AnalyticsTableFilterRows(rows, searchText) {
    const lowerSearchText = searchText.toLowerCase();
    return rows.filter(
        (row) =>
            (row.name?.toLowerCase().includes(lowerSearchText) ?? false) ||
            (row.city?.toLowerCase().includes(lowerSearchText) ?? false) ||
            (row.region?.toLowerCase().includes(lowerSearchText) ?? false) ||
            (row.country?.toLowerCase().includes(lowerSearchText) ?? false) ||
            (row.domain_whitelist?.some(domain => domain.toLowerCase().includes(lowerSearchText)) ?? false) ||
            (row.producer?.toLowerCase().includes(lowerSearchText) ?? false),
    );
}

AnalyticsTableFilterRows.propTypes = {
    rows: PropTypes.array.isRequired,
    searchText: PropTypes.string.isRequired,
};

AnalyticsTableFilterRows.defaultProps = {};


export function AnalyticsTableFilter({
    searchText, setSearchText, setPage, apiQuery
}) {
    const handleChangeSearch = (event) => {
        setSearchText(event.target.value);
        setPage(0);
    }

    let placeholder = "Meta-Daten suchen...";
    if (apiQuery.dimension1 === "camera_id") {
        placeholder = "Filtere das Ergebnis nach dem Namen der Kamera, der Stadt, Region, Land.";
    } else if (apiQuery.dimension1 === "player_id") {
        placeholder = "Filtere das Ergebnis nach dem Namen des Players oder einer Domain.";
    } else if (apiQuery.dimension1 === "user_email") {
        placeholder = "Filtere das Ergebnis nach dem Namen des Zugangs.";
    } else if (apiQuery.dimension1 === "playback_user_id") {
        placeholder = "Filtere das Ergebnis nach dem Namen des Video-Zugangs.";
    } else if (apiQuery.dimension1 === "content_type") {
        placeholder = "Filtere das Ergebnis nach dem Content Typ.";
    } else if (apiQuery.dimension1 === "content_profile") {
        placeholder = "Filtere das Ergebnis nach dem Content Profil.";
    } else if (apiQuery.dimension1 === "device_type") {
        placeholder = "Filtere das Ergebnis nach dem Geräte Typ.";
    } else if (apiQuery.dimension1 === "os_family") {
        placeholder = "Filtere das Ergebnis nach dem Betriebssystem.";
    } else if (apiQuery.dimension1 === "browser_family") {
        placeholder = "Filtere das Ergebnis nach dem Browser.";
    } else if (apiQuery.dimension1 === "country_iso") {
        placeholder = "Filtere das Ergebnis nach dem Land.";
    }

    return (
        <TextField
            //label="Suche"
            placeholder={placeholder}
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleChangeSearch}
            fullWidth
            sx={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "4px",
            }}
            InputProps={{
                endAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                ),
            }}
        />
    );
};

AnalyticsTableFilter.propTypes = {
    searchText: PropTypes.string.isRequired,
    setSearchText: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
};

AnalyticsTableFilter.defaultProps = {};

export default AnalyticsTableFilter;
