/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            //minWidth: '0px!important',
            //textTransform: 'initial',
            //margin: 'unset!important',
            //color: '#fff',
            //textAlign: 'center!important',
            //minHeight: 'unset!important',
            //borderRadius: '4px 4px 0px 0px!important',
            textTransform: "initial",
            minHeight: 50,
            "&.Mui-selected": {
                color: "#0084E8",
            },
            padding: "12px 25px",
        },
        wrapper: {
            //alignItems: 'flex-start!important',
        },
    },
};
