import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

function AcceptPrivacy({
    disableNext,
    setDisableNext,
    terms_accepted,
    handleChangeFormValues,
}) {
    const [scrollReachedEnd, setScrollReachedEnd] = React.useState(false);
    const textFieldRef = React.useRef(null);
    const handleChange = (event) => {
        setDisableNext(!disableNext);
        handleChangeFormValues(event);
    };

    React.useEffect(() => {
        if (!terms_accepted) {
            setDisableNext(true);
        }
    }, [terms_accepted, setDisableNext]);

    React.useEffect(() => {
        const textFieldElement = textFieldRef.current;
        if (textFieldElement) {
            const handleScroll = () => {
                const { scrollHeight, scrollTop, clientHeight } =
                    textFieldElement;
                const isScrollReachedEnd =
                    scrollHeight - scrollTop === clientHeight;
                setScrollReachedEnd(isScrollReachedEnd);
            };
            textFieldElement.addEventListener("scroll", handleScroll);
            return () => {
                textFieldElement.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    const value = `Ich erkläre hiermit mein ausdrückliches Einverständnis zur Verarbeitung meiner personenbezogenen Daten durch FOSS GmbH gemäß den nachfolgend aufgeführten Bedingungen:
    
Die Verarbeitung umfasst folgende Datenkategorien:  
Kontaktdaten (Name, E-Mail-Adresse und IP-Adresse bei der Anlage/Änderung/Löschung von Datensätzen, Name und E-Mail-Adresse im Ticketsystem)
Sicherheitsrelevante Informationen (IP-Adresse, Geodaten, Browser-Agent)

Die Verarbeitung meiner Daten erfolgt zu folgenden Zwecken:
- Speicherung der Kontaktdaten in einer Datenbank
- Protokollierung der Schreiboperationen
- Speicherung von Namen und E-Mail-Adressen in Tickets

Die Aufbewahrungsfristen für meine Daten sind wie folgt:    
- Kontaktdaten: Werden mit der Löschung meines Accounts vollständig entfernt.
- Protokollierung von Datensätzen: Speicherung für.
- Ticketsystem: Die Personeninformationen bleiben in den Tickets bis zur Löschung eines Tickets vorhanden.
- Sicherheitsrelevante Informationen: Diese werden meist temporär gespeichert und verfallen spätestens mit der Löschung meines Accounts. Sie werden lediglich noch in der Protokollierung der Datensätze verwendet.

Folgende Dienstleister haben Zugriff auf bestimmte personenbezogene Daten:
- Sendgrid: Sendgrid erhält zur Zustellung von E-Mails ausschließlich meine E-Mail-Adresse sowie Vor- und Nachnamen.
- Grafana Cloud: Grafana Cloud erhält alle sicherheitsrelevanten Informationen, jedoch ohne Angabe von Personendaten, sodass kein Rückschluss auf meiner Ebene möglich ist.
- DigitalOcean: DigitalOcean ist der Hoster für das Caching, die Datenbank und die Webservices.

Mir ist bewusst, dass meine Einwilligung freiwillig ist und dass ich das Recht habe, meine Einwilligung jederzeit ohne Angabe von Gründen zu widerrufen. Der Widerruf meiner Einwilligung berührt nicht die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung.

Ich habe die Datenschutzerklärung von der FOSS GmbH zur Kenntnis genommen und stimme der Verarbeitung meiner personenbezogenen Daten gemäß den darin beschriebenen Bedingungen zu.`;

    return (
        <Stack
            direction="column"
            spacing={1}
            justifyContent="flex-start"
            alignItems="flex-start"
            width={"100%"}
        >
            <Typography variant="h4">
                Einwilligungserklärung zur Verarbeitung
                <br /> personenbezogener Daten gemäß DSGVO
            </Typography>
            <Divider height={1} />
            <TextField
                value={value}
                multiline
                rows={16}
                variant="outlined"
                inputRef={textFieldRef}
                fullWidth
                inputProps={{
                    style: {
                        fontSize: 14,
                        whiteSpace: "pre-wrap",
                    },
                }}
            />
            <FormGroup>
                <FormControlLabel
                    disabled={!scrollReachedEnd && !terms_accepted}
                    style={{ maxWidth: 400 }}
                    control={
                        <Checkbox
                            name="terms_accepted"
                            checked={terms_accepted}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                        />
                    }
                    label="Ich stimme der Verarbeitung meiner personenbezogenen Daten zu"
                />
            </FormGroup>
        </Stack>
    );
}

AcceptPrivacy.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
    terms_accepted: PropTypes.bool.isRequired,
    handleChangeFormValues: PropTypes.func.isRequired,
};

AcceptPrivacy.defaultProps = {
    terms_accepted: false,
};

export default AcceptPrivacy;
