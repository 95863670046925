import * as React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import WarningIcon from '@mui/icons-material/Warning';

import PublicationProviderRegistry from './PublicationProviderRegistry';

const findCamera = (cameras, camera_id) => {
	if (!cameras) {
		return { id: '', meta: { name: '', city: '', country: '' } };
	}

	const camera = cameras.find((element) => {
		return element.id === camera_id;
	});

	return camera ? camera : { id: '', meta: { name: '', city: '', country: '' } };
};

function RestreamCameraSelect({ cameras, formValues, setFormValues }) {
	const [isSlected, setIsSelected] = React.useState(findCamera(cameras, formValues.camera_id ? formValues.camera_id : ''));

	if (!cameras) {
		return null;
	}

	const publicationProviderRegistry = PublicationProviderRegistry();
	const PublicationProviderSelected = publicationProviderRegistry.find((service) => service.id === formValues.publication_provider);

	if (!PublicationProviderSelected) {
		return null;
	}

	const video_resolution = PublicationProviderSelected.stream_requirements.video_resolution;
	const video_codec = PublicationProviderSelected.stream_requirements.video_codec;

	function isCompatible(streams) {
		if (!streams) {
			return false;
		}
		for (let i = 0; i < streams.length; i++) {
			if (video_codec.includes(streams[i].vcodec) && video_resolution.includes(streams[i].resolution)) {
				return true;
			}
		}
		return false;
	}

	const options = cameras
		.map(({ id, sla, status, streams, meta }) => ({
			id: id,
			sla: sla,
			status: status,
			streams: streams,
			name: meta.name,
			city: meta.city,
			country: meta.country,
		}))
		.sort((a, b) => (a.name > b.name ? 1 : -1))
		.filter((obj) => obj.sla > 0)
		.filter((obj) => isCompatible(obj.streams))
		.filter((obj, index, array) => {
			const currentIndex = array.findIndex((item) => item.name === obj.name && item.city === obj.city);
			return currentIndex === index;
		})
		.sort((a, b) => -(a.country || '').localeCompare(b.country || ''));

	const selectedCameraValue = (camera) => {
		if (!camera.meta) {
			return camera;
		}
		const { id, meta } = camera;
		return {
			id: id,
			name: meta.name,
			city: meta.city,
			country: meta.country,
		};
	};

	return (
		<Autocomplete
			fullWidth
			value={isSlected && isSlected.id ? selectedCameraValue(isSlected) : null}
			size="small"
			id="camera-select"
			disabled={!formValues.publication_provider || formValues.publication_provider === ''}
			options={options ? options : []}
			groupBy={(option) => option.country}
			getOptionLabel={(option) => (option.id ? `${option.name} ${option.city ? '(' + option.city + ')' : ''}` : '')}
			renderOption={(props, option) => (
				<li {...props}>
					{option.status !== 'healthy' && <WarningIcon color="danger" style={{ marginRight: 10 }} />}
					{option.name} {option.city ? '(' + option.city + ')' : ''}
				</li>
			)}
			getOptionKey={(option) => option.id}
			onChange={(event, newValue) => {
				setIsSelected(newValue);
				setFormValues((prevApiQuery) => ({
					...prevApiQuery,
					camera_id: newValue ? newValue.id : null,
				}));
			}}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					color={'secondary'}
					label={'Kamera'}
					sx={{
						width: '100%',
					}}
					InputLabelProps={{
						sx: {
							backgroundColor: '#fff',
						},
					}}
					helperText={`Es werden nur mit der Plattform kompatible Kameras angezeigt.`}
					FormHelperTextProps={{
						sx: {
							marginTop: 1,
							color: '#000!important',
							fontSize: '12px',
							'.MuiLink-root': { color: '#000' },
						},
					}}
				/>
			)}
		/>
	);
}

RestreamCameraSelect.propTypes = {
	cameras: PropTypes.array,
	setFormValues: PropTypes.func.isRequired,
};

RestreamCameraSelect.defaultProps = {};

export default RestreamCameraSelect;
