import * as React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import ACLSelect from '../../../components/ACLSelect';

function RestreamSidebarSettings({ restream }) {
	const [acl, setACL] = React.useState(restream.acl);

	return (
		<React.Fragment>
			<Stack
				style={{
					padding: '15px 15px 15px 15px',
					marginTop: 10,
					marginBottom: 5,
					backgroundColor: 'rgba(0,0,0,.035)',
					borderRadius: 4,
				}}
				spacing={2}
			>
				<Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1} marginTop={0.3}>
					<Grid item xs={12}>
						<TextField label="Name" value={restream.name} disabled={!restream.name} size="small" readOnly variant="standard" fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Beschreibung"
							value={restream.description}
							disabled={!restream.description}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack
				style={{
					padding: '15px 15px 15px 15px',
					marginBottom: 5,
					backgroundColor: 'rgba(0,0,0,.035)',
					borderRadius: 4,
				}}
				spacing={2}
			>
				<Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1} marginTop={0.3}>
					<Grid item xs={12}>
						<TextField
							label="Kamera ID"
							value={restream.camera_id}
							disabled={!restream.camera_id}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Kamera Stream Profil"
							value={restream.camera_input_id}
							disabled={!restream.camera_input_id}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Plattform"
							value={restream.publication_provider.name}
							disabled={!restream.publication_provider.name}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Plattform Token"
							value={restream.publication_token}
							disabled={!restream.publication_token}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Plattform Adresse"
							value={restream.publication_url}
							disabled={!restream.publication_url}
							size="small"
							readOnly
							variant="standard"
							fullWidth
						/>
					</Grid>
					{restream.schedule && (
						<React.Fragment>
							<Grid item xs={12}>
								<TextField
									label="Startzeit"
									value={restream.schedule_start}
									disabled={!restream.schedule_start}
									size="small"
									readOnly
									variant="standard"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Endzeit"
									value={restream.schedule_end}
									disabled={!restream.schedule_end}
									size="small"
									readOnly
									variant="standard"
									fullWidth
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</Stack>
			<Stack
				style={{
					padding: '15px 15px 15px 15px',
					marginBottom: 5,
					backgroundColor: 'rgba(0,0,0,.035)',
					borderRadius: 4,
				}}
				spacing={2}
			>
				<Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1} marginTop={0.3}>
					<Grid item xs={12}>
						<ACLSelect acl={acl} setACL={setACL} readOnly />
					</Grid>
				</Grid>
			</Stack>
		</React.Fragment>
	);
}

RestreamSidebarSettings.propTypes = {
	restream: PropTypes.object.isRequired,
};

RestreamSidebarSettings.defaultProps = {};

export default RestreamSidebarSettings;
