// eslint-disable-next-line
export default {
    styleOverrides: {
        colorPrimary: {
            "&:before": {
                borderBottom: 0,
            },
        },
        sizeSmall: {
            fontSize: "14px!important",
        },
        underline: {
            "&&:hover::before": {
                borderColor: "#0084E8",
            },
            "&:hover:not($disabled $error):before": {
                borderBottom: `2px solid #0084E8`,
            },
            "&:after": {
                borderBottom: `2px solid #fff`,
            },
            "&$focused:after": {
                borderBottomColor: `#fff`,
            },
            "&$error:after": {
                borderBottomColor: `#0084E8`,
            },
            "&:before": {
                borderBottom: `1px solid rgba(0,0,0,.15)`,
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: `2px solid #0084E8`,
            },
            "&$disabled:before": {
                borderBottom: `1px solid #0084E8`,
            },
        },
    },
};
