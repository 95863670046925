import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";

import UserDialog from "./UserDialog";

import { useApp } from "../../context/App";

import fallback from "../../images/avatar.png";

function UserButton() {
    const { client } = useApp();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const [openUserEdit, setOpenUserEdit] = React.useState(false);
    const handleClickOpenUserEdit = () => {
        if (user && (user.type === "user" || user.type === "superuser")) {
            setOpenUserEdit(true);
        }
    };
    const handleCloseUserEdit = () => {
        setOpenUserEdit(false);
    };

    const user = client.UserConfig();

    return (
        <Paper
            elevation={2}
            sx={{
                borderRadius: 2,
                padding: 1,
                p: "0px 0px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
                height: 40,
            }}
            component="form"
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Avatar
                    variant="rounded"
                    alt="Remy Sharp"
                    src={user.avatar_url ? user.avatar_url : fallback}
                    sx={{ width: 40, height: 40, borderRadius: 2 }}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handleClickOpenUserEdit}
                ></Avatar>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    width={"100%"}
                >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        paddingBottom={1}
                        //width={200}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={1}
                            paddingTop={1}
                            paddingBottom={1}
                            fontWeight={600}
                            fontSize={16}
                            width={"100%"}
                            backgroundColor={"rgba(0,0,0,.03)"}
                        >
                            <span>Zugangsprofil</span>
                            {user && user.type === "user" && (
                                <IconButton
                                    aria-label="open-edit"
                                    onClick={handleClickOpenUserEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Stack>
                        <Divider style={{ borderWidth: 1, marginTop: 0 }} />
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingTop={0.5}
                            fontWeight={600}
                            fontSize={14}
                        >
                            Name
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            fontSize={14}
                        >
                            {user.first_name} {user.last_name}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingTop={0.5}
                            fontWeight={600}
                            fontSize={14}
                        >
                            E-Mail Adresse
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            paddingLeft={2}
                            paddingRight={2}
                            fontSize={14}
                        >
                            {user.email}
                        </Stack>
                        {user.phone_opt_in && (
                            <React.Fragment>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    paddingLeft={2}
                                    paddingRight={2}
                                    paddingTop={0.5}
                                    fontWeight={600}
                                    fontSize={14}
                                >
                                    Telefonnummer
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    paddingLeft={2}
                                    paddingRight={2}
                                    fontSize={14}
                                >
                                    {user.phone}
                                </Stack>
                            </React.Fragment>
                        )}
                        {user.mobile_opt_in && (
                            <React.Fragment>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    paddingLeft={2}
                                    paddingRight={2}
                                    paddingTop={0.5}
                                    fontWeight={600}
                                    fontSize={14}
                                >
                                    Handynummer
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    paddingLeft={2}
                                    paddingRight={2}
                                    fontSize={14}
                                >
                                    {user.mobile}
                                </Stack>
                            </React.Fragment>
                        )}
                    </Stack>
                </Popover>
            </Stack>
            <UserDialog
                user={user}
                openUserEdit={openUserEdit}
                handleCloseUserEdit={handleCloseUserEdit}
            />
        </Paper>
    );
}

UserButton.propTypes = {};

export default UserButton;
