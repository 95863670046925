import * as React from "react";
import PropTypes from "prop-types";

import "moment/locale/de";
import "moment-timezone";
import moment from "moment";

import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import fallback from "../../images/fallback.png";

import AlertBoxDetail from "./AlertBoxDetail";
import AlertBoxSlaFilter from "./AlertBoxSlaFilter";
import SidebarTabPanel from "../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../components/SidebarTabPanelProps";
import SearchField from "../../components/SearchField";
import Table from "../../components/Table";


function AlertNameLabel({ 
    id, 
    playerConfig, 
    name
}) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);
   
    React.useEffect(() => {
        const img = new Image();
        img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, [id, playerConfig.url]);

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Paper
                elevation={0}
                sx={{
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    padding: 0,
                    height: 30,
                    minWidth: 55,
                    borderRadius: 1,
                    border: "1px solid #f1f1f1",
                }}
            />
            <span sx={{ marginLeft: 2 }}>{name}</span>
        </Stack>
    );
}


function Alerts({
    alerts = [], 
    playerConfig = {}, 
    slaFilterStates, 
    searchText = "" 
}) {

    const [rows, setRows] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [mousePosition, setMousePosition] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setDialogOpen(prevState => true);
        }
    }, [selectedRow]);

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "sla",
            numeric: false,
            disablePadding: true,
            label: "SLA",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
        },
        {
            id: "created_at",
            numeric: true,
            disablePadding: false,
            label: "Erstellt",
        }
    ];

    function StatusLabelDE(status) {
        if (status === "open") {
            return "Offen";
        } else if (status === "closed") {
            return "Geschlossen";
        } else if (status === "in_progress") {
            return "In Arbeit";
        } else if (status === "waiting") {
            return "Wartend";
        } else {
            return status;
        }
    };

    function StatusLabel(props) {
        const { status } = props;
        return (
            <Chip
                size="small"
                style={{
                    color: "#fff",
                    backgroundColor: "#0085e9",
                    padding: 0,
                    fontSize: "0.75rem",
                }}
                label={StatusLabelDE(status ? status : "open")}
            />
        );
    }

    function SLAValue(sla) {
        if (sla === "0") {
            return 1;
        } else if (sla === "8") {
            return 2;
        } else if (sla === "2") {
            return 3;
        } else {
            return 0;
        }
    }

    function SLALabel(sla) {
        if (sla === "0") {
            return "Std.";
        } else if (sla === "2" || sla === "8") {
            return `${sla}`;
        } else {
            return "-";
        }
    }

    React.useEffect(() => {
        const slaFilter = (sla) => {
            if (sla === "0" && slaFilterStates.sla0) {
                return true;
            } else if (sla === "8" && slaFilterStates.sla8) {
                return true;
            } else if (sla === "2" && slaFilterStates.sla2) {
                return true;
            } else if (!sla && slaFilterStates.slaNone) {
                return true;
            } else {
                return false;;
            }
        }

        const oldRows = [...rows];
        const newRows = [];
        
        if (alerts) {
            for (const alert in alerts) {
                if (alerts[alert].item_type === "camera") {
                    const a = alerts[alert];
                    const c = a.camera;
                    if (c && slaFilter(c.sla)) {
                        const newRow = {
                            id: { value: a.item_id, label: "" },
                            name: { value: c.meta.name, label: <AlertNameLabel
                                id={c.id}
                                playerConfig={playerConfig}
                                name={c.meta.name}
                            />},
                            sla: { value: SLAValue(c.sla), label: SLALabel(c.sla) },
                            status: { value: a.status ? a.status : "open", label: <StatusLabel status={a.status} /> },
                            created_at: { value: a.created_at, label: moment(a.created_at).format("DD.MM.YYYY HH:mm") },
                            alias_id: { value: c.alias_id, label: c.alias_id },
                            producer: { value: c.meta.producer, label: c.meta.producer },
                            alert: a,
                            camera: c,
                        };
                        newRows.push(newRow);
                    }
                }
            };
        }
        const updatedRows = oldRows.filter(oldRow => newRows.some(newRow => newRow.id.value === oldRow.id.value)).map(oldRow => {
            const newRow = newRows.find(newRow => newRow.id.value === oldRow.id.value);
            return { ...oldRow, ...newRow };
        });
        
        newRows.forEach(newRow => {
            if (!updatedRows.some(updatedRow => updatedRow.id.value === newRow.id.value)) {
                updatedRows.push(newRow);
            }
        });
        setRows(newRows);
    // eslint-disable-next-line
    }, [alerts, slaFilterStates]);

    return (
        <>
            <Table 
                rows={rows} 
                maxRows={5} 
                sortBy={["created_at"]} 
                sortDir={"asc"} 
                rowHeight={1} 
                dense={false}
                headCells={headCells} 
                setSelectedRow={setSelectedRow} 
                setMousePosition={setMousePosition}
                searchText={searchText}
            />
            {dialogOpen && selectedRow && (
                <AlertBoxDetail
                    key={selectedRow ? selectedRow.updated : Date.now()}
                    selectedRow={selectedRow}
                    dialogOpenid={dialogOpen} 
                    setDialogOpen={setDialogOpen}
                    mousePosition={mousePosition}
                />
            )}
        </>
    );

};


function AlertBox({ 
    alerts = [],
    playerConfig = {} 
}) {
    const [tab, setTab] = React.useState(0);
    const handleChangeTag = (event, newValue) => {
        setTab(newValue);
    };

    const [searchText, setSearchText] = React.useState("");

    const [slaFilterStates, setSlaFilterStates] = React.useState({
        sla2: true,
        sla8: true,
        sla0: false,
        slaNone: false,
    });

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={-2}
                marginBottom={-1}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            >
                <Tabs value={tab} onChange={handleChangeTag}>
                    <Tab
                        iconPosition="start"
                        label="Störungen"
                        {...SidebarTabPanelProps(0)}
                        style={{ textTransform: "initial" }}
                    />
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        marginBottom: -25,
                    }}
                    spacing={0}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                        paddingTop={1.5}
                        paddingBottom={0}
                        height={50}
                    >
                        <SearchField
                            searchText={searchText}
                            setSearchText={setSearchText}
                        />
                        <AlertBoxSlaFilter slaFilterStates={slaFilterStates} setSlaFilterStates={setSlaFilterStates} />
                    </Stack>
                    <Alerts 
                        alerts={alerts} 
                        playerConfig={playerConfig} 
                        slaFilterStates={slaFilterStates} 
                        searchText={searchText}
                    />
                </Stack>
            </SidebarTabPanel>
        </>
    );
};

AlertBox.propTypes = {
    alerts: PropTypes.array,
    playerConfig: PropTypes.object,
};

export default AlertBox;