import * as React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

function CameraMeta({ meta }) {
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
            >
                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        value={meta.name}
                        disabled={!meta.name}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Beschreibung"
                        value={meta.description}
                        disabled={!meta.description}
                        size="small"
                        multiline
                        maxRows={5}
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Postleitzahl"
                        value={meta.postal_code}
                        disabled={!meta.postal_code}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        label="Stadt"
                        value={meta.city}
                        disabled={!meta.city}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Landkreis"
                        value={meta.county}
                        disabled={!meta.county}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Region"
                        value={meta.region}
                        disabled={!meta.region}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        label="LC"
                        value={
                            meta.country_code
                                ? meta.country_code.toUpperCase()
                                : ""
                        }
                        disabled={!meta.country_code}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={11}>
                    <TextField
                        label="Land"
                        value={meta.country}
                        disabled={!meta.country}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Breitengrad"
                        value={meta.latitude}
                        disabled={!meta.latitude}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Längengrad"
                        value={meta.longitude}
                        disabled={!meta.longitude}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                {meta.producer && (
                    <Grid item xs={12}>
                        <TextField
                            label="Produzent"
                            value={meta.producer}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}

CameraMeta.propTypes = {
    meta: PropTypes.object.isRequired,
};

CameraMeta.defaultProps = {};

export default CameraMeta;
