import * as React from "react";

import "moment-timezone";
import moment from "moment";

import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { DefaultCopyField } from "../../../../components/CopyButton";
import ACLSelect from "../../../../components/ACLSelect";
import SidebarTabPanel from "../../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../../components/SidebarTabPanelProps";

import PlaybackUserSidebarCameraSelect from "./PlaybackUserSidebarCameraSelect";
import PlaybackUserSidebarMenu from "./PlaybackUserSidebarMenu";

import { useApp } from "../../../../context/App";

function PlaybackUserSidebar({ drawerWidth, playbackUser }) {
    const { client } = useApp();
    // SIDEBAR TABPANEL
    const [tab, setTab] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const customerId = client.CustomerId();

    // settings > ACLSelect
    const [acl, setACL] = React.useState(playbackUser.acl);

    // access > PlaybackUserSidebarCameraSelect
    const [cameras, setCameras] = React.useState(null);
    const [selectedCamera, setSelectedCamera] = React.useState(null);

    const getCameras = async () => {
        const data = await client.GetCustomerCamera({
            acl_id: playbackUser.acl_id,
            limit: 0,
        });
        setCameras(data);
        setSelectedCamera(data[0]);
    };

    React.useEffect(() => {
        getCameras();
        // eslint-disable-next-line
    }, []);

    return (
        <Stack width={drawerWidth}>
            <DialogTitle
                id="scroll-dialog-title"
                style={{
                    padding: "10px 0px 0px 0px",
                }}
                marginLeft={"-20px"}
                marginRight={"-20px"}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    textTransform={"uppercase"}
                    lineHeight={"normal"}
                    padding={"0px 20px 20px 20px"}
                >
                    {playbackUser.name}
                </Stack>
            </DialogTitle>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                marginTop={0}
                borderTop={"1px solid rgba(0, 0, 0, 0.12)"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                width={drawerWidth}
            >
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="Zugriff" {...SidebarTabPanelProps(0)} />
                    <Tab label="Einstellung" {...SidebarTabPanelProps(1)} />
                    {/* <Tab label="Protokoll" {...SidebarTabPanelProps(2)} /> */}
                </Tabs>
                {/* <PlaybackUserSidebarMenu /> */}
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        marginTop: 10,
                        marginBottom: 5,
                    }}
                    spacing={0}
                >
                    {cameras && (
                        <PlaybackUserSidebarCameraSelect
                            cameras={cameras.sort((a, b) =>
                                (a.meta.name || "").localeCompare(
                                    b.meta.name || "",
                                ),
                            )}
                            selectedCamera={selectedCamera}
                            setSelectedCamera={setSelectedCamera}
                        />
                    )}
                    {selectedCamera &&
                        playbackUser.protocol_allowlist.includes("hls") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                                    HLS Stream (HTTP Livestreaming)
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`https://${playbackUser.id}:${playbackUser.password}@wettercom-c1.livespotting.com/memfs/${customerId}/${selectedCamera.id}/live/main.m3u8`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                    {selectedCamera &&
                        playbackUser.protocol_allowlist.includes("rtmp") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                                    RTMP Stream
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`rtmp://wettercom-c1.livespotting.com/${customerId}/${selectedCamera.id}.stream/${playbackUser.id}:${playbackUser.password}`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                    {selectedCamera &&
                        playbackUser.protocol_allowlist.includes("srt") && (
                            <React.Fragment>
                                <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                                    SRT Stream
                                </Typography>
                                <Stack
                                    style={{
                                        padding: "15px 15px 15px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={2}
                                >
                                    <DefaultCopyField
                                        value={`srt://wettercom-c1.livespotting.com:6000/?mode=caller&transtype=live&streamid=${customerId}/${selectedCamera.id}.stream,mode:request,token:${playbackUser.id}:${playbackUser.password}`}
                                        multiline
                                        maxRows={3}
                                    />
                                </Stack>
                            </React.Fragment>
                        )}
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    style={{
                        padding: "0px 1px 20px 25px",
                        marginLeft: -40,
                        marginBottom: 5,
                    }}
                    spacing={1}
                >
                    <Stack
                        style={{
                            padding: "10px 15px 10px 15px",
                            marginTop: 10,
                            marginLeft: 15,
                            marginRight: 0,
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0.5}
                    >
                        <Typography fontSize={14}>
                            <strong>ID:</strong> {playbackUser.id}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(playbackUser.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {playbackUser.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(playbackUser.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </Stack>
                    <Grid
                        container
                        justifyContent="space-between"
                        fontSize={14}
                        style={{
                            marginTop: 6,
                            paddingRight: 15,
                        }}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                value={
                                    playbackUser.name ? playbackUser.name : ""
                                }
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Beschreibung"
                                value={
                                    playbackUser.description
                                        ? playbackUser.description
                                        : ""
                                }
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ACLSelect acl={acl} setACL={setACL} readOnly />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Erlaubte Protokolle"
                                value={
                                    playbackUser.protocol_allowlist
                                        ? playbackUser.protocol_allowlist
                                        : ""
                                }
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Passwort"
                                value={
                                    playbackUser.password
                                        ? playbackUser.password
                                        : ""
                                }
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </SidebarTabPanel>
        </Stack>
    );
}

PlaybackUserSidebar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    playbackUser: PropTypes.object.isRequired,
};

PlaybackUserSidebar.defaultProps = {};

export default PlaybackUserSidebar;
