import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Page from "../../components/Page";

import SidebarTabPanel from "../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../components/SidebarTabPanelProps";

import PlayerInventory from "./PlaybackPlayer";
import PlaybackUser from "./PlaybackUser";

import PlayerTokenGenerator from "./PlayerTokenGenerator";
import PlaybackPlayerSidebar from "./PlaybackPlayer/PlaybackPlayerSidebar";
import PlaybackUserSidebar from "./PlaybackUser/PlaybackUserSidebar";

import { useApp } from "../../context/App";

function Player() {
    const navigate = useNavigate();
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        setPlayer(null);
        setPlaybackUser(null);
        navigate(`/playback/player`);
    };

    // PERMISSION CHECK
    const playbackPlayerAdminRoles = ["admin", "player:admin"];
    // const playbackPlayerEditorRoles = playbackPlayerAdminRoles.concat([
    //     "editor",
    //     "player:editor",
    // ]);

    // TABS
    const [tab, setTab] = React.useState(0);
    const handleChangeTag = (event, newValue) => {
        setTab(newValue);
    };

    // PLAYER VALUES
    const [players, setPlayers] = React.useState(null);
    const { player_id } = useParams();
    const [player, setPlayer] = React.useState(null);
    const [playerSelected, setPlayerSelected] = React.useState(null);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

    const getPlayers = async () => {
        const params = {};
        const data = await client.GetCustomerPlayer(params);

        const sortedPlayers = [...data].sort((a, b) =>
            (a.name || "").localeCompare(b.name || ""),
        );
        setPlayers(sortedPlayers);

        if (player_id && !player) {
            console.log("player_id", player_id);
            const player = data.find((p) => p.id === player_id);
            if (player) {
                setPlayer(player);
                setPlaybackUser(null);
                setOpenSidebar(true);
                setDrawerWidth(550);
            }
        }
    };

    // PLAYBACK USER VALUES
    const [playbackUsers, setPlaybackUsers] = React.useState(null);
    const [playbackUser, setPlaybackUser] = React.useState(null);
    const { playback_user_id } = useParams();
    const [playbackUserSelected, setPlaybackUserSelected] =
        React.useState(null);

    const getPlaybackUser = async () => {
        const params = {};
        const data = await client.GetCustomerPlaybackUser();

        const sortedPlaybackUsers = [...data].sort((a, b) =>
            (a.name || "").localeCompare(b.name || ""),
        );
        setPlaybackUsers(sortedPlaybackUsers);

        if (playback_user_id && !playbackUser) {
            const playbackUser = data.find((p) => p.id === playback_user_id);
            if (playbackUser) {
                setPlaybackUser(playbackUser);
                setPlayer(null);
                setOpenSidebar(true);
                setDrawerWidth(400);
            }
        }
    };

    React.useEffect(() => {
        (async () => {
            setSelectedPage("playback");
            if (tab === 0) {
                if (player_id) {
                    navigate(`/playback/player/${player_id}`);
                } else {
                    navigate(`/playback/player`);
                }
                if (playbackUser) {
                    setPlaybackUser(null);
                    setOpenSidebar(false);
                }
                await getPlayers();
                await getPlayerConfig();
            } else if (tab === 1) {
                if (playback_user_id) {
                    navigate(`/playback/user/${playback_user_id}`);
                } else {
                    navigate(`/playback/user`);
                }
                if (player) {
                    setPlayer(null);
                    setOpenSidebar(false);
                }
                await getPlaybackUser();
            }
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [reload, tab]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    const [drawerWidth, setDrawerWidth] = React.useState(550);

    React.useEffect(() => {
        if (player) {
            setPlaybackUser(null);
            setOpenSidebar(true);
            setDrawerWidth(550);
        } else if (playbackUser) {
            setPlayer(null);
            setOpenSidebar(true);
            setDrawerWidth(400);
        }
    }, [player, playbackUser]);

    return (
        <Page
            drawerWidth={drawerWidth}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                (player && (
                    <PlaybackPlayerSidebar
                        key={player.id}
                        drawerWidth={drawerWidth}
                        player={player}
                        playerConfig={playerConfig}
                    />
                )) ||
                (playbackUser && (
                    <PlaybackUserSidebar
                        key={playbackUser.id}
                        drawerWidth={drawerWidth}
                        playbackUser={playbackUser}
                    />
                ))
            }
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            >
                <Tabs value={tab} onChange={handleChangeTag}>
                    <Tab
                        icon={<PlayCircleFilledWhiteIcon />}
                        iconPosition="start"
                        label="Player"
                        {...SidebarTabPanelProps(0)}
                        style={{ textTransform: "initial" }}
                    />
                    <Tab
                        icon={<PlayCircleFilledWhiteIcon />}
                        iconPosition="start"
                        label="Direktzugriff"
                        {...SidebarTabPanelProps(1)}
                        style={{ textTransform: "initial" }}
                    />
                    {/* <Tab
                                icon={<AddModeratorIcon />}
                                iconPosition="start"
                                label="Token Assistent"
                                style={{ textTransform: "initial" }}
                                {...SidebarTabPanelProps(1)}
                                disabled={
                                    !hasPermission(playerEditorRoles)
                                }
                            /> */}
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    width={"100%"}
                >
                    {players && (
                        <PlayerInventory
                            players={players}
                            setPlayer={setPlayer}
                            playerSelected={playerSelected}
                            setPlayerSelected={setPlayerSelected}
                        />
                    )}
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    width={"100%"}
                >
                    {playbackUsers && (
                        <PlaybackUser
                            playbackUsers={playbackUsers}
                            setPlaybackUser={setPlaybackUser}
                            playbackUserSelected={playbackUserSelected}
                            setPlaybackUserSelected={setPlaybackUserSelected}
                        />
                    )}
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={2} padding={0}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    width={"100%"}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                        width={"100%"}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-left"
                            alignItems="flex-left"
                            spacing={2}
                            maxWidth={550}
                            padding={"0px 25px 10px 25px"}
                        >
                            <span>
                                Für die Verwendung Player und
                                System, können über den Assistenten eigene Token
                                erzeugt werden.
                            </span>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-left"
                            alignItems="flex-left"
                            spacing={2}
                        >
                            {playerConfig && (
                                <PlayerTokenGenerator
                                    playerConfig={playerConfig}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </SidebarTabPanel>
        </Page>
    );
}

Player.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Player.defaultProps = {
    openMenu: false,
};

export default Player;
