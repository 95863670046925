import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { useApp } from "../context/App";

function ACLSelect({ acl, setACL, readOnly }) {
    const { client } = useApp();
    const [open, setOpen] = React.useState(false);

    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [inputValue, setInputValue] = React.useState("");

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                client.GetCustomerACL().then((data) => setOptions(data));
            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            //multiple
            //limitTags={2}
            value={acl}
            onChange={(event, newValue) => {
                setACL(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="camera-role"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
                option.id === value.id || null || ""
            }
            getOptionLabel={(option) => option.name || ""}
            readOnly={readOnly}
            disabled
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Zugriffliste (ACL)"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

ACLSelect.propTypes = {
    acl: PropTypes.string,
    setACL: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

ACLSelect.defaultProps = {
    readOnly: false,
};

export default ACLSelect;
