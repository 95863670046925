import * as React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

function PasswordChange({
    disableNext,
    setDisableNext,
    user,
    formValues,
    handleChangeFormValues,
}) {
    React.useEffect(() => {
        if (
            (formValues.password.length >= 12 && formValues.password_confirm.length >= 12) &&
            (formValues.password === formValues.password_confirm)
        ) {
            setDisableNext(false);
        } else {
            setDisableNext(true);
        }
    }, [setDisableNext, formValues.password, formValues.password_confirm]);

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginTop={-1}
            marginBottom={2}
            spacing={1}
        >
            <Grid item xs={12}>
                <Typography variant="h4">Passwort ändern</Typography>
                <Divider height={1} />
            </Grid>
            <Grid item xs={12} marginTop={2}>
                <TextField
                    type="text"
                    label="Passwort"
                    name="password"
                    value={formValues.password}
                    onChange={handleChangeFormValues}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                    error={formValues.password.length < 12}
                    helperText={formValues.password.length >= 12 ? "" : "Passwort muss mindestens 12 Zeichen haben"}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Passwort Bestätigen"
                    name="password_confirm"
                    value={formValues.password_confirm}
                    onChange={handleChangeFormValues}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                    error={formValues.password !== formValues.password_confirm}
                    helperText={formValues.password === formValues.password_confirm ? "" : "Passwort ist unterschiedlich"}
                />
            </Grid>
        </Grid>
    );
}

PasswordChange.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    handleChangeFormValues: PropTypes.func.isRequired,
};

PasswordChange.defaultProps = {};

export default PasswordChange;
