import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import QrCode from "react-qr-code";

function TwoFactorAuth({
    disableNext,
    setDisableNext,
    setDisableBack,
    registered,
    user,
    otpData,
    handleChangeOtpData,
}) {
    const otp_issuer = "livespotting%20Kamera%20Managment";
    const otp_name = user.email;
    const otpauthUri = `otpauth://totp/${otp_issuer}:${otp_name}?secret=${otpData.otp_secret}&issuer=${otp_issuer}`;

    React.useEffect(() => {
        if (!otpData.otp_enable) {
            setDisableNext(false);
        } else if (otpData.otp_enable && otpData.otp_token.length === 6) {
            setDisableNext(false);
        } else {
            setDisableNext(true);
        }
        setDisableBack(!registered);
    }, [setDisableNext, setDisableBack, registered, otpData]);

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginTop={-2}
            marginBottom={1}
            padding={0}
            spacing={1.5}
        >
            <Grid item xs={12}>
                <Typography variant="h4">
                    Zwei-Faktor (2FA) Authtentifizierung
                </Typography>
                <Divider height={1} />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="opt_enable"
                                checked={otpData.opt_enable}
                                onChange={handleChangeOtpData}
                                inputProps={{ "aria-label": "controlled" }}
                                color="success"
                            />
                        }
                        label="Jetzt aktivieren"
                    />
                </FormGroup>
            </Grid>
            {otpData.opt_enable && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography fontSize={18} fontWeight={600}>
                            1. Konfiguration der Authtentifizierung
                        </Typography>
                        <ul style={{ margin: "0px 0px 0px -20px" }}>
                            <li>
                                <Typography fontSize={14} marginTop={1}>
                                    Installieren Sie Google Authenticator (
                                    <a
                                        href="https://apps.apple.com/de/app/google-authenticator/id388497605"
                                        about="_blank"
                                    >
                                        IOS
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                        about="_blank"
                                    >
                                        Android
                                    </a>
                                    ), Microsoft Authenticator (
                                    <a
                                        href="https://apps.apple.com/de/app/microsoft-authenticator/id983156458"
                                        about="_blank"
                                    >
                                        IOS
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                                        about="_blank"
                                    >
                                        Android
                                    </a>
                                    ) oder Authy (
                                    <a
                                        href="https://apps.apple.com/de/app/twilio-authy/id494168017"
                                        about="_blank"
                                    >
                                        IOS
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.authy.authy"
                                        about="_blank"
                                    >
                                        Android
                                    </a>
                                    ).
                                </Typography>
                            </li>
                            <li>
                                <Typography fontSize={14}>
                                    Öffnen Sie die Anwendung und wählen Sie "+".
                                </Typography>
                            </li>
                            <li>
                                <Typography fontSize={14}>
                                    Scannen Sie den QR-Code über die Kamera
                                    ihres Telefons.
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider height={1} />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography
                            fontSize={18}
                            fontWeight={600}
                            marginBottom={2}
                        >
                            2. Scanne den QR-Code
                        </Typography>
                        <QrCode value={otpauthUri} />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography fontSize={16} fontWeight={600}>
                            Oder füge den Sicherheitsschlüssel direkt ein:
                        </Typography>
                        <Typography fontSize={16}>
                            {otpData.otp_secret}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider height={1} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={18} fontWeight={600}>
                            3. Verifiziere die Konfiguration
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="Aktuellen Schlüssel einfügen"
                            name="otp_token"
                            value={otpData.otp_token}
                            onChange={handleChangeOtpData}
                            autoFocus
                            fullWidth
                            size="small"
                            variant="standard"
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
}

TwoFactorAuth.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
    setDisableBack: PropTypes.func.isRequired,
    registered: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    otpData: PropTypes.object.isRequired,
    handleChangeOtpData: PropTypes.func.isRequired,
};

TwoFactorAuth.defaultProps = {};

export default TwoFactorAuth;
