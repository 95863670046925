/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        filledInfo: {
            backgroundColor: base.palette.primary.main,
        },
        filledSuccess: {
            backgroundColor: base.palette.secondary.main,
        },
        filledWarning: {
            backgroundColor: base.palette.warning.main,
        },
        filledError: {
            backgroundColor: base.palette.error.main,
        },
    },
};
