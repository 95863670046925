import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";

export default function AnalyticsContentTypeSelect({ apiQuery, setApiQuery }) {
  const handleChange = (event) => {
    setApiQuery(prevApiQuery => ({ ...prevApiQuery, content_type: event.target.value ? event.target.value : null }));
  };

  return (
    <TextField
      select
      size="small"
      id="anlytics-contenttype-select"
      value={apiQuery.content_type && apiQuery.content_type !== "" ? apiQuery.content_type : null}
      label={(!apiQuery.content_type ? "Content Typ" : null)}
      onChange={handleChange}
      color={(!apiQuery.content_type ? "primary" : "secondary")}
      variant="outlined"
    >
      <MenuItem value={null}>
        {!apiQuery.content_type ? null : "Filter entfernen"}
      </MenuItem>
      <MenuItem value="m3u8">Livestream</MenuItem>
      <MenuItem value="jpg">Vorschaubild</MenuItem>
      <MenuItem value="mp4">Videodatei</MenuItem>
    </TextField>
  );
}