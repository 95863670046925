import * as React from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

function TwoFactorToken({ otp_token, setOtpToken }) {
    const otpInputRefs = Array(6)
        .fill(0)
        .map(() => React.createRef());

    const handleChange = (elementIndex, event) => {
        let otpValues = [...otp_token];
        otpValues[elementIndex] = event.target.value;

        if (
            elementIndex < otpInputRefs.length - 1 &&
            event.target.value !== ""
        ) {
            otpInputRefs[elementIndex + 1].current.focus();
        }

        setOtpToken(otpValues);
    };

    const handleKeyDown = (elementIndex, event) => {
        if (event.keyCode === 8 && otp_token[elementIndex] === "") {
            if (elementIndex > 0) {
                otpInputRefs[elementIndex - 1].current.focus();
            }
        }
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
        >
            {otp_token.map((data, index) => {
                return (
                    <Paper
                        elevation={1}
                        sx={{
                            borderRadius: 2,
                            padding: "0em .5em 0em .5em",
                            display: "flex",
                            alignItems: "center",
                            height: 60,
                            width: 60,
                        }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <OutlinedInput
                            type="text"
                            name="otp"
                            variant="outlined"
                            maxLength="1"
                            key={index}
                            value={data}
                            inputRef={otpInputRefs[index]}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            inputProps={{ style: { textAlign: "center" } }}
                        />
                    </Paper>
                );
            })}
        </Stack>
    );
}

TwoFactorToken.propTypes = {
    otp_token: PropTypes.array.isRequired,
    setOtpToken: PropTypes.func.isRequired,
};

TwoFactorToken.defaultProps = {};

export default TwoFactorToken;
