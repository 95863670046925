/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            display: "flex",
            alignItems: "center !important",
            justifyContent: "center !important",
        },
    },
};
