import * as React from "react";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function DeleteDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.onCancel}>
            <DialogTitle fontSize={22}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <span>KUNDE LÖSCHEN</span>
                    <IconButton aria-label="open-edit" onClick={props.onCancel}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                Sind Sie sicher, dass Sie diesen Kunden löschen wollen?
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={"100%"}
                >
                    <Button
                        variant="outlined"
                        color="danger"
                        onClick={props.onAccept}
                    >
                        Ja, mann!
                    </Button>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button variant="outlined" onClick={props.onCancel}>
                            Nein!
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

DeleteDialog.defaultProps = {
    open: false,
    onAccept: () => {},
    onCancel: () => {},
};

export default DeleteDialog;
