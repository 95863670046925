import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import { useApp } from "../context/App";
import Logo from "../images/livespotting.svg";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

function AppPage({
    drawerWidth = 75,
    handleMenuOpen = null,
    window = null,
    children = null,
    userMenu = null,
    pageMenu = null,
}) {
    const container =
        window !== null ? () => window().document.body : undefined;

    const { client, openMenu, handleMenuToggle } = useApp();
    const user = client.UserConfig();

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", paddingTop: "20px", width: "100%" }}>
                <CssBaseline />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 0,
                        p: 0,
                        width: "100%",
                        marginTop: user.type === "superuser" ? "18px" : null,
                    }}
                >
                    {children}
                </Box>
                <Box
                    component="nav"
                    sx={{
                        width: { sm: drawerWidth },
                        flexGrow: 3,
                        flexShrink: { sm: 0 },
                    }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="left"
                        open={openMenu}
                        onClose={handleMenuToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        xs={{
                            paper: {
                                backgroundColor: "#000",
                                overflowX: "hidden",
                            },
                        }}
                        sx={{
                            display: { xs: "block", sm: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                                top: user.type === "superuser" ? "55px" : null,
                            },
                        }}
                        PaperProps={{ sx: { overflow: "hidden" } }}
                        elevation={3}
                    >
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            width={"100%"}
                            height={"24px"}
                            minHeight={"24px"}
                            maxHeight={"24px"}
                            marginTop={"5px"}
                            marginBottom={"35px"}
                            paddingLeft={4}
                        >
                            <ServiceIcon
                                style={{ width: "40px", marginLeft: "-39px" }}
                            />
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            width={"75px"}
                            paddingTop={2}
                            marginLeft={"-11px"}
                            paddingRight={0}
                            marginBottom={"10px"}
                        >
                            {pageMenu}
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            width={"75px"}
                            paddingTop={2}
                            marginLeft={"-11px"}
                            paddingRight={0}
                        >
                            {userMenu}
                        </Stack>
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        anchor="left"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                                top: user.type === "superuser" ? "55px" : null,
                            },
                        }}
                        PaperProps={{ sx: { overflowX: "hidden" } }}
                        open
                    >
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            width={"100%"}
                            height={"24px"}
                            minHeight={"24px"}
                            maxHeight={"24px"}
                            marginTop={"5px"}
                            marginBottom={"35px"}
                            paddingLeft={4}
                        >
                            <ServiceIcon
                                style={{ width: "40px", marginLeft: "-39px" }}
                            />
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            width={"75px"}
                            paddingTop={2}
                            marginLeft={"-11px"}
                            paddingRight={0}
                            marginBottom={"10px"}
                        >
                            {pageMenu}
                        </Stack>
                        <Divider />
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            width={"75px"}
                            paddingTop={2}
                            marginLeft={"-11px"}
                            paddingRight={0}
                        >
                            {userMenu}
                        </Stack>
                    </Drawer>
                </Box>
            </Box>
        </React.Fragment>
    );
}

AppPage.propTypes = {
    window: PropTypes.func,
    drawerWidth: PropTypes.any,
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
};

export default AppPage;
