import * as React from "react";

import BusinessIcon from "@mui/icons-material/Business";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Page from "../../components/Page";
import SidebarTabPanel from "../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../components/SidebarTabPanelProps";

import AdminCompanyProfile from "./AdminCompany/AdminCompanyProfile";
import AdminBilling from "./AdminBilling";
import AdminACL from "./AdminACL";
import AdminACLSidebar from "./AdminACL/AdminACLSidebar";
import AdminUser from "./AdminUser";
import AdminUserSidebar from "./AdminUser/AdminUserSidebar";
import AdminProtocol from "./AdminProtocol";

import { useApp } from "../../context/App";

function Admin({
    openMenu = false,
}) {
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
    };

    // PERMISSION CHECK
    const adminRoles = ["admin"];
    const billingRoles = adminRoles.concat(["billing"]);

    // TABS
    const [tab, setTab] = React.useState(0);
    const handleChangeTag = (event, newValue) => {
        setTab(newValue);
    };

    const [customer, setCustomer] = React.useState(null);
    const [users, setUsers] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [userSelected, setUserSelected] = React.useState("");
    const [acls, setACLs] = React.useState(null);
    const [acl, setACL] = React.useState(null);
    const [billing, setBilling] = React.useState(null);
    const [protocols, setProtocols] = React.useState(null);

    React.useEffect(() => {
        setSelectedPage("admin");
    }, [setSelectedPage]);

    React.useEffect(() => {
        (async () => {
            setCustomer(null);
            setUser(null);
            setUserSelected("");
            setACL(null);
            setProtocols(null);
            setOpenSidebar(false);
            if (tab === 0) {
                await client.GetCustomer().then((data) => setCustomer(data));
            } else if (tab === 1) {
                await client.GetCustomerUser().then((data) => setUsers(data));
            } else if (tab === 2) {
                await client.GetCustomerACL().then((data) => setACLs(data));
            } else if (tab === 4) {
                await client
                    .GetCustomerBilling()
                    .then((data) => setBilling(data));
            }
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [tab, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (user || acl) {
            setOpenSidebar(true);
        }
    }, [user, acl]);

    const drawerWidth = 550;

    return (
        <Page
            drawerWidth={drawerWidth}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                <React.Fragment>
                    {user && (
                        <AdminUserSidebar
                            key={user.username}
                            drawerWidth={drawerWidth}
                            user={user}
                            adminRoles={adminRoles}
                        />
                    )}
                    {acl && (
                        <AdminACLSidebar
                            key={acl.id}
                            drawerWidth={drawerWidth}
                            acl={acl}
                            adminRoles={adminRoles}
                        />
                    )}
                </React.Fragment>
            }
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                marginLeft={"-25px"}
                marginRight={"-25px"}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            >
                <Tabs value={tab} onChange={handleChangeTag}>
                    <Tab
                        icon={<BusinessIcon />}
                        iconPosition="start"
                        label="Übersicht"
                        {...SidebarTabPanelProps(0)}
                        style={{ textTransform: "initial" }}
                        disabled={!hasPermission(billingRoles)}
                    />
                    <Tab
                        icon={<PersonIcon />}
                        iconPosition="start"
                        label="Zugänge"
                        style={{ textTransform: "initial" }}
                        {...SidebarTabPanelProps(1)}
                        disabled={!hasPermission(adminRoles)}
                    />
                    <Tab
                        icon={<LocalPoliceIcon />}
                        iconPosition="start"
                        label="ACL"
                        style={{ textTransform: "initial" }}
                        {...SidebarTabPanelProps(2)}
                        disabled={!hasPermission(adminRoles)}
                    />
                    <Tab
                        icon={<ListAltIcon />}
                        iconPosition="start"
                        label="Protokoll"
                        style={{ textTransform: "initial" }}
                        {...SidebarTabPanelProps(3)}
                        disabled={!hasPermission(adminRoles)}
                    />
                    <Tab
                        icon={<CreditCardIcon />}
                        iconPosition="start"
                        label="Abrechnung"
                        style={{ textTransform: "initial" }}
                        {...SidebarTabPanelProps(4)}
                        disabled={!hasPermission(billingRoles)}
                    />
                </Tabs>
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    style={{
                        padding: "0px 25px 20px 25px",
                        marginLeft: -25,
                        marginRight: -25,
                        marginBottom: 5,
                        maxWidth: 900,
                    }}
                    spacing={2}
                >
                    {hasPermission(billingRoles) && customer && (
                        <AdminCompanyProfile customer={customer} />
                    )}
                </Stack>
            </SidebarTabPanel>
            {hasPermission(adminRoles) && (
                <React.Fragment>
                    <SidebarTabPanel value={tab} index={1} padding={0}>
                        <Stack
                            style={{
                                padding: "0px 25px 20px 25px",
                                marginLeft: -25,
                                marginRight: -25,
                                marginBottom: 5,
                            }}
                            spacing={2}
                        >
                            {users && (
                                <AdminUser
                                    users={users}
                                    setUser={setUser}
                                    userSelected={userSelected}
                                    setUserSelected={setUserSelected}
                                />
                            )}
                        </Stack>
                    </SidebarTabPanel>
                    <SidebarTabPanel value={tab} index={2} padding={0}>
                        <Stack
                            style={{
                                padding: "0px 25px 20px 25px",
                                marginLeft: -25,
                                marginRight: -25,
                                marginBottom: 5,
                            }}
                            spacing={2}
                        >
                            {acls && <AdminACL acls={acls} setACL={setACL} />}
                        </Stack>
                    </SidebarTabPanel>
                </React.Fragment>
            )}
            {hasPermission(adminRoles) && (
                <SidebarTabPanel value={tab} index={3} padding={0}>
                    {hasPermission(adminRoles) && (
                        <AdminProtocol />
                    )}
                    
                </SidebarTabPanel>
            )}
            {hasPermission(billingRoles) && (
                <SidebarTabPanel value={tab} index={4} padding={0}>
                    <Stack
                        direction="row"
                        justifyContent="flex-left"
                        alignItems="flex-left"
                        spacing={2}
                        maxWidth={550}
                        padding={"0px 25px 25px 25px"}
                    >
                        <span>
                            Übersicht über aktuell gebuchte Produkte und
                            Leistungen.
                        </span>
                    </Stack>
                    {hasPermission(billingRoles) && billing && (
                        <AdminBilling billing={billing} />
                    )}
                </SidebarTabPanel>
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;
