/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        colorSuccess: {
            color: "#fff",
            backgroundColor: "#1dad22",
            padding: 5,
        },
        colorDanger: {
            color: "#fff",
            //backgroundColor: '#1dad22',
            padding: 5,
        },
        colorPrimary: {
            color: "#fff",
            backgroundColor: "#0084E8",
            padding: 5,
        },
        colorDefault: {
            color: "#fff",
            backgroundColor: "#1dad22",
            padding: 5,
        },
        deleteIconColorDefault: {
            color: "rgba(255,255,255,1)",
        },
        sizeMedium: {
            fontSize: 16,
            borderRadius: 6,
            textTransform: "uppercase",
        },
        sizeSmall: {
            fontSize: 14,
            fontWeight: 600,
            borderRadius: 4,
            textTransform: "uppercase",
        },
    },
};
