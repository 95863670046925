import * as React from 'react';
import PropTypes from 'prop-types';

import 'moment-timezone';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import ErrorIcon from '@mui/icons-material/Error';

import Stack from '@mui/material/Stack';
import SupportIcon from '@mui/icons-material/Support';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useApp } from '../../../context/App';

import SidebarTabPanel from '../../../components/SidebarTabPanel';
import SidebarTabPanelProps from '../../../components/SidebarTabPanelProps';
import VideoJS from '../../../components/VideoJS';

import CameraMeta from '../../Camera/CameraSidebar/CameraMeta';
import RestreamAlert from './RestreamAlert';
import RestreamSidebarSettings from './RestreamSidebarSettings';
import RestreamStream from './RestreamStream';
import RestreamSidebarMenu from './RestreamSidebarMenu';

function RestreamSidebar({ drawerWidth, restream, playerConfig, adminRoles, editorRoles }) {
	const { client } = useApp();
	const [tab, setTab] = React.useState(0);
	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
	};

	// VIDEOJS
	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		poster: playerConfig.url + restream.camera_id + '/live/main_240.jpg',
		playsinline: true,
		muted: true,
		// html5: {
		//     hls: {
		//         overrideNative: true,
		//         limitRenditionByPlayerDimensions: true,
		//         useDevicePixelRatio: true
		//     },
		//     nativeAudioTracks: false,
		//     nativeVideoTracks: false,
		//     useBandwidthFromLocalStorage: true
		// },
		sources: [
			{
				src: playerConfig.url + restream.camera_id + '/live/main.m3u8?token=' + playerConfig.token,
				type: 'application/vnd.apple.mpegurl',
			},
		],
	};

	return (
		<Stack sx={{ maxWidth: drawerWidth }} width={'100%'}>
			<Stack
				style={{
					padding: '10px 0px 0px 0px',
				}}
				marginLeft={'-20px'}
				marginRight={'-20px'}
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					fontSize={22}
					fontWeight={500}
					textTransform={'uppercase'}
					lineHeight={'normal'}
					padding={'0px 20px 10px 20px'}
				>
					{restream.name}
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1} fontSize={22} fontWeight={500} padding={'0px 20px 20px 20px'}>
					{restream.status === 'healthy' && (
						<Chip size="small" icon={<CheckCircleIcon style={{ color: '#fff' }} />} color="success" label="Verbunden" />
					)}
					{restream.status === 'unhealthy' && (
						<Chip size="small" icon={<ErrorIcon style={{ color: '#fff' }} />} color="danger" label="Verbindungsfehler" />
					)}
					{restream.status === 'unknown' && (
						<Chip size="small" icon={<CancelIcon style={{ color: '#fff' }} />} color="danger" label="Unbekannter Zustand" />
					)}
					{restream.sla && (
						<Chip
							size="small"
							icon={<SupportIcon style={{ color: '#fff' }} />}
							style={{
								color: '#fff',
								backgroundColor: '#0085E9',
							}}
							label={restream.sla > 0 ? `SLA ${restream.sla}` : `SLA`}
						/>
					)}
				</Stack>
				<Stack direction="column" alignItems="flex-start" spacing={1} padding={'0px 20px 20px 20px'}>
					<VideoJS {...videoJsOptions} />
				</Stack>
			</Stack>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				marginLeft={'-25px'}
				marginRight={'-25px'}
				marginTop={0}
				borderTop={'1px solid rgba(0, 0, 0, 0.12)'}
				borderBottom={'1px solid rgba(0, 0, 0, 0.12)'}
				maxWidth={drawerWidth}
			>
				<Tabs value={tab} onChange={handleChangeTab}>
					<Tab label="Meta" {...SidebarTabPanelProps(0)} />
					<Tab label="Stream" {...SidebarTabPanelProps(1)} />
					<Tab label="Störung" {...SidebarTabPanelProps(2)} />
					<Tab label="Details" {...SidebarTabPanelProps(3)} />
				</Tabs>
				<RestreamSidebarMenu restream={restream} adminRoles={adminRoles} editorRoles={editorRoles} />
			</Stack>
			<SidebarTabPanel value={tab} index={0} padding={0}>
				<Stack
					style={{
						marginBottom: 5,
					}}
					spacing={0}
				>
					<Stack
						style={{
							padding: '10px 15px 10px 15px',
							marginTop: 10,
							marginLeft: 0,
							marginRight: 0,
							marginBottom: 15,
							backgroundColor: 'rgba(0,0,0,.035)',
							borderRadius: 4,
						}}
						spacing={0.5}
					>
						<Typography fontSize={14}>
							<strong>ID:</strong> {restream.id}
						</Typography>
						<Typography fontSize={14}>
							<strong>Kamera ID:</strong> {restream.camera_id}
						</Typography>
						<Typography fontSize={14}>
							<strong>Erstellt:</strong> {moment.utc(restream.created_at).local().format('DD.MM.YYYY HH:mm:ss')}
						</Typography>
					</Stack>
					<Typography marginTop={2} marginBottom={1.5} variant="h4">
						Standort
					</Typography>
					<Stack
						style={{
							padding: '10px 15px 15px 15px',
							marginBottom: 20,
							backgroundColor: 'rgba(0,0,0,.035)',
							borderRadius: 4,
						}}
						spacing={0}
					>
						<CameraMeta meta={restream.camera.meta} />
					</Stack>
				</Stack>
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={1} padding={0}>
				<Stack
					style={{
						marginBottom: 5,
					}}
					spacing={0}
				>
					<RestreamStream restream={restream} />
				</Stack>
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={2} padding={0}>
				<RestreamAlert restream={restream} />
			</SidebarTabPanel>
			<SidebarTabPanel value={tab} index={3} padding={0}>
				<RestreamSidebarSettings restream={restream} />
			</SidebarTabPanel>
		</Stack>
	);
}

RestreamSidebar.propTypes = {
	drawerWidth: PropTypes.number,
	restream: PropTypes.object.isRequired,
	playerConfig: PropTypes.object,
};

RestreamSidebar.defaultProps = {
	drawerWidth: 540,
};

export default RestreamSidebar;
