import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useApp } from "../context/App";

function Core(props) {
    const { client } = useApp();

    const [ready, setReady] = React.useState(false);
    const [cores, setCores] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const cores = await client.GetCores();
            if (cores !== null) {
                setCores(cores);
            }

            setReady(true);
        })();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        props.onChange(e);
    };

    if (ready === false) {
        return null;
    }

    const options = [];

    for (let core of cores) {
        options.push(
            <MenuItem key={core.id} value={core.id}>
                {core.name}
            </MenuItem>,
        );
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="core-select-label">Core</InputLabel>
            <Select
                disabled={!ready || props.disabled}
                labelId="core-select-label"
                value={props.value}
                label="Auswählen ..."
                onChange={handleChange}
            >
                {options}
            </Select>
        </FormControl>
    );
}

Core.defaultProps = {
    value: "",
    onChange: () => {},
    disabled: false,
};

export default Core;
