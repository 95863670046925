import React, { useState } from 'react';
import PropTypes from "prop-types";

import Divider from '@mui/material/Divider';
import HelpIcon from '@mui/icons-material/Help';import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function HistorySummaryBoxInfo({
    dateRange = null,
}) {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const handleOpenMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleOpenMenu} aria-label="Filter">
                <HelpIcon sx={{color: "#0084E8"}} />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                    sx={{ padding: "10px 15px 5px 15px", maxWidth: 290}}
                >
                    <Typography variant="h6" color="textSecondary" sx={{ paddingBottom: 1, fontSize: 14, lineHeight: "1.4", fontWeight: 500 }}>
                        28 Tage Auswertung
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{ paddingBottom: 1, fontSize: 14, lineHeight: "1.4", fontWeight: 400 }}>
                        Alle Zugriffe der vergangenen 28 Tage ({dateRange.from_date.format("DD.MM.YYYY") + " - " + dateRange.to_date.format("DD.MM.YYYY")}) und ein prozentualer Vergleich zum vorherigen Zeitraum ({dateRange.from_date_delta.format("DD.MM.YYYY") + " - " + dateRange.to_date_delta.format("DD.MM.YYYY")}).
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{ paddingBottom: 1, fontSize: 13, lineHeight: "1.4", fontWeight: 400 }}>
                        Die Ergebnisse können von den Live-Messwerten abweichen, da Live-Daten im Gegensatz zu den Analytics-Daten weder gefiltert noch aufbereitet werden.
                    </Typography>
                </Stack>
            </Menu>
        </div>
    );
}

HistorySummaryBoxInfo.propTypes = {
    slaFilterStates: PropTypes.object,
    setSlaFilterStates: PropTypes.func,
};

export default HistorySummaryBoxInfo;