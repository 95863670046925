/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        root: {
            background: "rgba(0, 0, 0, 0)!important",
            borderBottom: `2px solid ${base.palette.background.light}!important`,
            padding: "0em!important",
            marginBottom: "1.5em",
        },
    },
};
