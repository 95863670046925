import * as React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import CustomerButton from "./CustomerButton";
import UserButton from "./UserButton";

import { useApp } from "../context/App";

function PageHeader(props) {
    const { handleMenuToggle } = useApp();

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "flex-start" }}
            spacing={2}
            marginLeft={-1}
            paddingLeft={-5}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                width={"100%"}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleMenuToggle}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        marginRight: 2,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                {props.contentLeft}
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                height={"58px"}
            >
                {props.contentRight}
                <UserButton />
                <CustomerButton />
                {props.handleSidebarOpen && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={props.handleSidebarOpen}
                        sx={{ ...(props.openSidebar && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
}

PageHeader.propTypes = {
    openSidebar: PropTypes.bool,
    handleSidebarOpen: PropTypes.func,
    contentLeft: PropTypes.object,
    contentRight: PropTypes.object,
};

PageHeader.defaultProps = {
    openSidebar: false,
    handleSidebarOpen: null,
    contentLeft: null,
    contentRight: null,
};

export default PageHeader;
