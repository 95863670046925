import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function YouTube(props) {
	return {
		id: 'youtube',
		verion: '1.0',
		name: 'YouTube Live',
		description: 'Stream to YouTube Live',
		category: 'Social',
		help_link: 'https://support.google.com/youtube/answer/2474026?hl=en',
		icon: <FontAwesomeIcon icon={faYoutube} style={{ color: '#FF0000', ...props }} />,
		stream_key_link: 'https://www.youtube.com/live_dashboard',
		stream_requirements: {
			video_codec: ['h264'],
			video_resolution: ['1280x720', '1920x1080', '2560x1440', '3840x2160'],
			video_fps: {
				min: 15,
				max: 60,
			},
			video_bitrate: {
				min: 300,
				max: 24000,
			},
			audio_codec: ['aac'],
			audio_bitrate: {
				min: 64,
				max: 320,
			},
			audio_channels: 2,
		},
		stream_targets: [
			{
				address: 'rtmp://a.rtmp.youtube.com/live2/{token}',
				options: ['-f', 'flv'],
				type: 'primary',
				protocol: 'rtmp',
				region: 'international',
			},
			{
				address: 'rtmp://b.rtmp.youtube.com/live2/{token}',
				options: ['-f', 'flv'],
				type: 'secondary',
				protocol: 'rtmp',
				region: 'international',
			},
		],
	};
}
