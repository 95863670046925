import React, { useEffect, useState } from "react";
import { IconButton, TextField, InputAdornment, Tooltip } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";

const CopyError = {
    NotAvailable: "NotAvailable",
    WriteError: "WriteError",
};

const IconButtonWithTooltip = styled(IconButton)(({ theme }) => ({
    "&.Mui-disabled": {
        pointerEvents: "auto",
    },
}));

const AlignedInputAdornment = styled(InputAdornment)({
    margin: "0 auto", // fix for vertically unaligned icon
});

export function CopyField({
    onCopyError,
    onCopySuccess,
    copyTooltip = "Copy",
    value,
    noWrap = false,
    ...rest
}) {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const enabled = "clipboard" in navigator;
        setDisabled(!enabled);

        if (!enabled) {
            typeof onCopyError === "function" &&
                onCopyError(CopyError.NotAvailable);
        }
        // eslint-disable-next-line
    }, []);

    const copyText = () => {
        if ("clipboard" in navigator) {
            navigator.clipboard.writeText(value).then(
                () => {
                    typeof onCopySuccess === "function" && onCopySuccess(value);
                },
                () => {
                    typeof onCopyError === "function" &&
                        onCopyError(CopyError.WriteError);
                },
            );
        } else {
            typeof onCopyError === "function" &&
                onCopyError(CopyError.NotAvailable);
        }
    };

    return (
        <TextField
            variant="standard"
            type="text"
            value={value}
            sx={{
                "& .MuiInputBase-root::before, & .MuiInput-root::before": {
                    border: 0,
                    content: "unset",
                },
                "& .MuiInputBase-input": {
                    whiteSpace: !noWrap ? "break-spaces" : null,
                    wordBreak: !noWrap ? "break-word" : null,
                    fontSize: 14,
                },
            }}
            InputProps={{
                endAdornment: (
                    <AlignedInputAdornment
                        position="end"
                        sx={{ alignSelf: "flex-start" }}
                    >
                        {disabled ? (
                            <IconButtonWithTooltip
                                disabled={disabled}
                                onClick={copyText}
                            >
                                <CopyIcon />
                            </IconButtonWithTooltip>
                        ) : (
                            <Tooltip title={copyTooltip}>
                                <IconButtonWithTooltip
                                    role="button"
                                    disabled={disabled}
                                    onClick={copyText}
                                >
                                    <CopyIcon />
                                </IconButtonWithTooltip>
                            </Tooltip>
                        )}
                    </AlignedInputAdornment>
                ),
            }}
            {...rest}
        />
    );
}

export function DefaultCopyField(props) {
    const [tooltip, setTooltip] = useState("Kopieren");

    const setCopied = () => setTooltip("Kopiert!");
    const setCopyError = () => setTooltip("Kopierfehler!");

    return (
        <CopyField
            copyTooltip={tooltip}
            onCopySuccess={setCopied}
            onCopyError={setCopyError}
            {...props}
        />
    );
}
