import React, { useState } from 'react';
import PropTypes from "prop-types";

import Check from '@mui/icons-material/Check'; 
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList'; 
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function AlertBoxSlaFilter({ 
    slaFilterStates = {
        sla2: true,
        sla8: true,
        sla0: true,
        slaNone: true
    },
    setSlaFilterStates= () => {},
}) {

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const handleMenuItemClick = (name) => {
        setSlaFilterStates({ ...slaFilterStates, [name]: !slaFilterStates[name] });
    };
    const handleOpenMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const slaName = (name) => {
        switch (name) {
            case 'sla2':
                return 'SLA 2';
            case 'sla8':
                return 'SLA 8';
            case 'sla0':
                return 'SLA';
            case 'slaNone':
                return 'Ohne';
            default:
                return name;
        }
    }

    return (
        <div>
            <IconButton onClick={handleOpenMenu} aria-label="Filter">
                <FilterListIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
            >
                <ListItem style={{ pointerEvents: 'none', backgroundColor: "rgba(0,0,0,.03)" }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <FilterListIcon />
                        <Typography variant="h6">
                            SLA Filter
                        </Typography>
                    </Stack>
                </ListItem>
                <Divider style={{ borderWidth: 1, marginTop: 0, marginBottom: 0 }} />
                {Object.entries(slaFilterStates).map(([name, checked]) => (
                    <MenuItem
                        key={name}
                        disableRipple
                        onClick={() => handleMenuItemClick(name)}
                        selected={checked}
                    >
                        <ListItemIcon>
                            {checked && <Check style={{color: "#fff"}} />}
                        </ListItemIcon>
                        {slaName(name)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

AlertBoxSlaFilter.propTypes = {
    slaFilterStates: PropTypes.object,
    setSlaFilterStates: PropTypes.func,
};

export default AlertBoxSlaFilter;