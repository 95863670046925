/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        root: {
            '&.Mui-selected': {
                backgroundColor: `${base.palette.blue.main}!important`,
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#005DB7!important',
                },
            },
            '&:not(.Mui-selected)': {
                // tage innerhalb min/max range und NICHT! ausgewählt
                backgroundColor: "rgba(0,0,0,.15)",
                color: '#000',
            },
            // außerhalb min/max range
            '&.Mui-disabled:not(.Mui-selected)': {
                backgroundColor: '#fff',
                color: 'rgba(0,0,0,.45)',
            },
        },
    },
};
