import React, { useState, useRef, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';


function SearchField({
    searchText = "",
    setSearchText = () => { },
}) {

    const [isActive, setIsActive] = useState(false);
    const textFieldRef = useRef(null);

    const handleChangeSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleActivateSearch = () => {
        setIsActive(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textFieldRef.current && !textFieldRef.current.contains(event.target) && searchText === '') {
                setIsActive(false);
            }
        };

        if (isActive) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isActive, searchText]);

    if (!isActive) {
        return (
            <IconButton onClick={handleActivateSearch}>
                <SearchIcon />
            </IconButton>
        );
    }

    return (
        <TextField
            ref={textFieldRef}
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleChangeSearch}
            autoFocus
            sx={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "4px",
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchField;