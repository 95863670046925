/* eslint-disable import/no-anonymous-default-export */
export default {
    common: {
        black: "#000",
        white: "#fff",
    },
    // default color
    primary: {
        light: "#fff",
        main: "#B3B3B3",
        dark: "#fff",
        contrastText: "#000",
    },
    // success color
    secondary: {
        main: "rgba(0,0,0,0)",
        contrastText: "#323232",
    },
    selected: {
        main: "#000",
        contrastText: "#323232",
    },
    unselected: {
        main: "#919090",
        contrastText: "#323232",
    },
    success: {
        main: "#39B54A",
        contrastText: "#000",
    },
    error: {
        main: "rgba(193,39,45,.9)",
        dark: "rgba(193,39,45,.9)",
        contrastText: "#fff",
    },
    danger: {
        main: "rgba(193,39,45,.9)",
        dark: "rgba(193,39,45,.9)",
        contrastText: "#fff",
    },
    warning: {
        main: "#ffc107",
    },
    blue: {
        main: "#0085E9",
    },
    disabled: {
        main: "rgba(0,0,0,0.15)",
    },
    text: {
        primary: "#000",
        secondary: "rgba(0, 0, 0, 0.7)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.7)",
    },
};
