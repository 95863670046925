import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
    'null',
    '0',
    '2',
    '8'
];

function getStyles(name, slaFilter, theme) {
  return {
    fontWeight:
    slaFilter.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SlaFilterSelect({slaFilter, setSlaFilter}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSlaFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 260 }}>
        <Select
          labelId="sla-filter-label"
          id="sla-filter"
          multiple
          value={slaFilter}
          onChange={handleChange}
          autoWidth
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip 
                    size="small"
                    key={value}
                    label={value === 'null' ? 'NSLA' : value === '0' ? 'SLA' : 'SLA ' + value}
                    style={{
                        color: "#fff",
                        backgroundColor: "#0085E9",
                        padding: 0,
                    }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          variant="outlined"
          style={{marginLeft: '-8px', height: 40,}}
          
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, slaFilter, theme)}
            >
              {name === 'null' ? 'Ohne SLA (NSLA)' : name === '0' ? 'SLA' : 'SLA ' + name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}