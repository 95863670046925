import Facebook from './Facebook';
import Twitch from './Twitch';
import YouTube from './YouTube';
import X from './X';
import RTMP from './RTMP';
import SRT from './SRT';
import HLS from './HLS';

export default function PublicationProviderRegistry(props, version = 1) {
	// for versioning
	// eslint-disable-next-line no-magic-numbers
	if (version !== 1) {
		throw new Error('Invalid version');
	}
	return [Facebook(props), X(props), Twitch(props), YouTube(props), HLS(props), RTMP(props), SRT(props)];
}
