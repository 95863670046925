import * as React from "react";

import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Start({ disableNext, setDisableNext }) {
    React.useEffect(() => {
        setDisableNext(false);
    }, [setDisableNext]);

    return (
        <Stack
            direction="column"
            spacing={0}
            justifyContent="flex-start"
            alignItems="flex-start"
            width={450}
        >
            <Typography
                fontSize={42}
                fontWeight={400}
                lineHeight={1}
                marginBottom={0}
            >
                Herzlich
                <br />
                Willkommen
            </Typography>
            <Divider height={1} />
            <Typography fontSize={16} marginTop={1}>
                Für den Zugriff sind nur noch wenige Schritte zu erledigen. Nach
                Abschluss der Registrierung und der Vervollständigung ihres
                Profils, werden Sie automatisch auf die freigeschalteten
                Bereiche weitergeleitet.
            </Typography>
        </Stack>
    );
}

Start.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
};

Start.defaultProps = {};

export default Start;
