import * as React from "react";

import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CustomerForm from "./CustomerForm";

function AddDialog({ open, onClose, onChange }) {
    const [addCustomer, setAddCustomer] = React.useState({});
    const [formValuesChanged, setFormValuesChanged] = React.useState(false);

    const handleChange = (customer) => {
        setAddCustomer(customer);
        setFormValuesChanged(true);
    };

    const handleSave = async () => {
        onChange(addCustomer);
    };

    const handleReset = () => {
        setFormValuesChanged(false);
        setAddCustomer({});
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle fontSize={22}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <span>KUNDEN ERSTELLEN</span>
                    <IconButton aria-label="open-edit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <CustomerForm
                    mode="add"
                    customer={addCustomer}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={"100%"}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            disabled={!formValuesChanged}
                            variant="outlined"
                            onClick={handleReset}
                        >
                            Zurücksetzen
                        </Button>
                        <Button
                            disabled={!formValuesChanged}
                            variant="outlined"
                            onClick={handleSave}
                        >
                            Speichern
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

AddDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

AddDialog.defaultProps = {
    open: false,
    onClose: () => {},
};

export default AddDialog;
