import * as React from "react";
import PropTypes from "prop-types";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import CustomerEditDialog from "./CustomerEditDialog";
import CustomerAddDialog from "./CustomerAddDialog";
import CustomerDeleteDialog from "./CustomerDeleteDialog";

import { useApp } from "../../context/App";

function EditButton(props) {
    const [open, setOpen] = React.useState(false);

    const handleOpenCustomerEdit = () => {
        setOpen(true);
    };

    const handleCloseCustomerEdit = () => {
        setOpen(false);
    };

    const handleChange = async (customer) => {
        if (!(await props.onChange(customer))) {
            return;
        }

        setOpen(false);
    };

    const handleDelete = async () => {
        setOpen(false);

        props.onDelete();
    };

    return (
        <Paper
            elevation={2}
            sx={{
                borderRadius: 1,
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                height: 40,
                padding: "10px",
                marginLeft: 1,
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <IconButton
                    aria-label="open-edit"
                    onClick={handleOpenCustomerEdit}
                >
                    <EditIcon />
                </IconButton>
            </Stack>
            <CustomerEditDialog
                customer={props.customer}
                open={open}
                onClose={handleCloseCustomerEdit}
                onChange={handleChange}
                onDelete={handleDelete}
            />
        </Paper>
    );
}

EditButton.propTypes = {
    customer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

EditButton.defaultProps = {
    value: "",
    onChange: () => {},
    onDelete: () => {},
};

function AddButton(props) {
    const [open, setOpen] = React.useState(false);

    const handleOpenCustomerAdd = () => {
        setOpen(true);
    };

    const handleCloseCustomerAdd = () => {
        setOpen(false);
    };

    const handleChange = async (customer) => {
        if (!(await props.onChange(customer))) {
            return;
        }

        setOpen(false);
    };

    return (
        <Paper
            elevation={2}
            sx={{
                borderRadius: 1,
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                height: 40,
                padding: "10px",
                marginLeft: 1,
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <IconButton
                    aria-label="open-edit"
                    onClick={handleOpenCustomerAdd}
                >
                    <AddIcon />
                </IconButton>
            </Stack>
            <CustomerAddDialog
                open={open}
                onClose={handleCloseCustomerAdd}
                onChange={handleChange}
            />
        </Paper>
    );
}

AddButton.propTypes = {
    onChange: PropTypes.func.isRequired,
};

AddButton.defaultProps = {
    onChange: () => {},
};

function AdminBar(props) {
    const { client, reload, reloadPage } = useApp();
    const [ready, setReady] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [customer, setCustomer] = React.useState({ id: "", name: "" }); // the selected customer
    const [name, setName] = React.useState(""); // the name in the textfield
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const user = client.UserConfig();
            if (user && user.type === "superuser") {
                await refresh();
            }
        })();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        (async () => {
            const user = client.UserConfig();
            if (user && user.type === "superuser") {
                await refresh();
            }
        })();
        // eslint-disable-next-line
    }, [reload]);

    const refresh = async () => {
        setReady(false);

        const customerId = client.getCustomerId();
        const customers = await client.GetAllCustomers();

        for (let i = 0; i < customers.length; i++) {
            if (customers[i].id !== customerId) {
                continue;
            }

            setCustomer(customers[i]);
            setName(customers[i].name);
            break;
        }
        setCustomers(customers);
        setReady(true);
        return () => {
            setReady(false);
        };
    };

    const handleCustomerChange = (event, value) => {
        client.setCustomer(value);
        setCustomer(value);
        reloadPage();
    };

    const handleInputChange = (event, value) => {
        setName(value);
    };

    const handleCustomerEdit = async (customerData) => {
        const data = {
            name: customerData.name,
            website: customerData.website,
            email: customerData.email,
            billing_email: customerData.billing_email,
            sla: customerData.sla,
        };
        const response = await client.PutCustomer(customerData.id, data);
        if (response === null) {
            return false;
        }

        if (customer.avatar_url !== customerData.avatar_url) {
            await client.PutCustomerAvatar(
                customerData.id,
                customerData.avatar_url,
            );
        }
        
        reloadPage();

        return true;
    };

    const handleCustomerAdd = async (customerData) => {
        const data = {
            name: customerData.name,
            website: customerData.website,
            email: customerData.email,
            billing_email: customerData.billing_email,
            sla: customerData.sla,
        };
        const customer = await client.PostCustomer(data);
        if (customer !== null) {
            return false;
        }

        if (customerData.avatar_url) {
            await client.PutCustomerAvatar(
                customer.id,
                customerData.avatar_url,
            );
        }

        reloadPage();

        return false;
    };

    const handleCustomerDelete = () => {
        setDeleteDialog(true);
    };

    const handleCustomerDeleteCancel = () => {
        setDeleteDialog(false);
    };

    const handleCustomerDeleteAccept = async () => {
        setDeleteDialog(false);

        const response = await client.DeleteCustomer(customer.id);
        if (response === null) {
            return false;
        }

        reloadPage();

        return true;
    };

    if (!ready) {
        return null;
    }

    return (
        <Box
            sx={{
                display: "block",
                paddingTop: "7px",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "100%",
                height: "55px",
                backgroundColor: "#0084E8",
                boxShadow: 3,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1201,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "flex-start" }}
                spacing={0}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    width={"100%"}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            borderRadius: 1,
                            width: {
                                xs: 250,
                                sm: 300,
                                md: 350,
                                lg: 550,
                            },
                            minWidth: { xs: 50, md: 250 },
                            display: "flex",
                            alignItems: "center",
                            height: 40,
                            padding: "1em 0.5em 1em 1.5em",
                        }}
                        component="form"
                    >
                        <Autocomplete
                            disabled={!ready}
                            size="small"
                            onInputChange={handleInputChange}
                            onChange={handleCustomerChange}
                            inputValue={name}
                            value={customer}
                            clearOnBlur={false}
                            options={customers}
                            getOptionLabel={(option) => {
                                return option.name;
                            }}
                            getOptionKey={(option) => option.id}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            style={{ marginLeft: -25 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    color="primary"
                                    label=""
                                    variant="outlined"
                                    placeholder="Suche..."
                                />
                            )}
                            noOptionsText={"Kein Ergebnis gefunden"}
                            fullWidth
                        />
                        <IconButton disabled aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <EditButton
                        customer={customer}
                        onChange={handleCustomerEdit}
                        onDelete={handleCustomerDelete}
                    ></EditButton>
                </Stack>
                <AddButton onChange={handleCustomerAdd}></AddButton>
            </Stack>
            <CustomerDeleteDialog
                open={deleteDialog}
                onAccept={handleCustomerDeleteAccept}
                onCancel={handleCustomerDeleteCancel}
            />
        </Box>
    );
}

export default AdminBar;
