import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "product",
        numeric: false,
        disablePadding: false,
        label: "Produkt",
    },
    {
        id: "current",
        numeric: true,
        disablePadding: false,
        label: "Anzahl",
    },
    {
        id: "free",
        numeric: true,
        disablePadding: false,
        label: "Inklusive",
    },
    {
        id: "limit",
        numeric: true,
        disablePadding: false,
        label: "Limit",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ billing }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");

    const page = 0;
    const rowsPerPage = 100;
    const rows = billing;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="row"
                                        tabIndex={-1}
                                        key={row.product}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                        >
                                            {row.product ===
                                                "cameras_no_sla" && (
                                                <Typography fontWeight={600}>
                                                    Kamera NO-SLA
                                                </Typography>
                                            )}
                                            {row.product === "cameras_sla" && (
                                                <Typography fontWeight={600}>
                                                    Kamera SLA
                                                </Typography>
                                            )}
                                            {row.product ===
                                                "cameras_sla_8" && (
                                                <Typography fontWeight={600}>
                                                    Kamera SLA+8
                                                </Typography>
                                            )}
                                            {row.product ===
                                                "cameras_sla_2" && (
                                                <Typography fontWeight={600}>
                                                    Kamera SLA+2
                                                </Typography>
                                            )}
                                            {row.product === "videos" && (
                                                <Typography fontWeight={600}>
                                                    Video
                                                </Typography>
                                            )}
                                            {row.product === "player" && (
                                                <Typography fontWeight={600}>
                                                    Player
                                                </Typography>
                                            )}
                                            {row.product === "acl" && (
                                                <Typography fontWeight={600}>
                                                    ACL
                                                </Typography>
                                            )}
                                            {row.product === "user" && (
                                                <Typography fontWeight={600}>
                                                    Zugang
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.current.toLocaleString()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.free.toLocaleString()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.limit.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
