import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useApp } from "../context/App";
import PageHeader from "./PageHeader";
import PageSidebarHeader from "./PageSidebarHeader";

const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, drawerWidth, user }) => ({
    flexGrow: 1,
    width: `100%`,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {
        marginLeft: 75,
        marginRight: -60,
    },
    marginLeft: -15,
    marginTop: user?.type === "superuser" ? -20 : -40,
    ...(open && {
        [theme.breakpoints.up("sm")]: {
            marginLeft: 75,
            marginRight: `${drawerWidth})`,
            //width: `calc(100% - ${drawerWidth}px)`,
        },
        width: `100%`,
        marginRight: -60,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Page(props) {
    // DISABLES MOBILE DRAWER >= MD (non-clickable bug)
    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("md"));

    const { window } = props;
    const container =
        window !== undefined ? () => window().document.body : undefined;

    const { client } = useApp();
    const user = client.UserConfig();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {downMd && (
                <Drawer
                    container={container}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true,
                    }}
                    anchor="right"
                    open={props.openSidebar}
                    onClose={props.handleSidebarToggle}
                    xs={{ paper: { backgroundColor: "#000" } }}
                    sx={{
                        display: {
                            xs: props.openSidebar ? "block" : "none",
                            sm: "none",
                        },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: "100%",
                            padding: 0,
                            top: user.type === "superuser" ? "55px" : null,
                        },
                    }}
                    elevation={3}
                >
                    <PageSidebarHeader
                        handleSidebarToggle={props.handleSidebarToggle}
                        sidebarFullScreen={props.sidebarFullScreen}
                        handleSidebarFullscreenToggle={
                            props.handleSidebarFullscreenToggle
                        }
                    />
                    {props.sidebarTitel && (
                        <React.Fragment>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                width={"100%"}
                                height={"24px"}
                                minHeight={"24px"}
                                maxHeight={"24px"}
                                marginTop={"23px"}
                                marginBottom={"15px"}
                                paddingLeft={4}
                            >
                                <LocationOnIcon style={{ color: "#0085E9" }} />
                                <Typography
                                    variant="h1"
                                    fontWeight={600}
                                    fontSize={"1.25rem"}
                                >
                                    {props.sidebarTitel}
                                </Typography>
                            </Stack>
                            <Divider />
                        </React.Fragment>
                    )}
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        width={"100%"}
                        padding={4}
                    >
                        {props.sidebarContent}
                    </Stack>
                </Drawer>
            )}
            <Main open={props.openSidebar} drawerWidth={props.drawerWidth} user={user}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    marginRight={-2}
                >
                    <PageHeader contentLeft={props.headerContentLeft} />
                    {props.children}
                </Stack>
            </Main>
            <Drawer
                sx={{
                    width: props.sidebarFullScreen ? "100%" : props.drawerWidth,
                    flexShrink: 0,
                    display: {
                        xs: "none",
                        sm: props.openSidebar ? "block" : "none",
                    },
                    "& .MuiDrawer-paper": {
                        width: props.sidebarFullScreen
                            ? "100%"
                            : props.drawerWidth,
                        top: user.type === "superuser" ? "55px" : null,
                    },
                }}
                variant={props.sidebarFullScreen ? "temporary" : "persistent"}
                container={props.sidebarFullScreen ? container : null}
                anchor="right"
                open={props.openSidebar}
                elevation={3}
                transitionDuration={0}
            >
                <PageSidebarHeader
                    handleSidebarToggle={props.handleSidebarToggle}
                    sidebarFullScreen={props.sidebarFullScreen}
                    handleSidebarFullscreenToggle={
                        props.handleSidebarFullscreenToggle
                    }
                />
                {props.sidebarTitel && (
                    <React.Fragment>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            width={"100%"}
                            height={"24px"}
                            minHeight={"24px"}
                            maxHeight={"24px"}
                            marginTop={"15px"}
                            marginBottom={"25px"}
                        >
                            <LocationOnIcon style={{ color: "#0085E9" }} />
                            <Typography
                                variant="h1"
                                fontWeight={600}
                                fontSize={"1.25rem"}
                            >
                                {props.sidebarTitel}
                            </Typography>
                        </Stack>
                        <Divider />
                    </React.Fragment>
                )}
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width={"100%"}
                    marginTop={props.sidebarTitel ? null : -1}
                    paddingTop={props.sidebarTitel ? 2 : null}
                    marginBottom={user.type === "superuser" ? 5 : null}
                >
                    {props.sidebarContent}
                </Stack>
            </Drawer>
        </Box>
    );
}

Page.propTypes = {
    window: PropTypes.func,
    drawerWidth: PropTypes.number,
    openSidebar: PropTypes.bool,
    handleSidebarToggle: PropTypes.func,
    sidebarFullScreen: PropTypes.bool,
    handleSidebarFullscreenToggle: PropTypes.func,
};

Page.defaultProps = {
    drawerWidth: 450,
    openSidebar: false,
    handleSidebarToggle: null,
    sidebarFullScreen: false,
    handleSidebarFullscreenToggle: null,
    headerContentLeft: null,
};

export default Page;
