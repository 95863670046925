import * as React from "react";

import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

import AnalyticsCameraSelect from "./AnalyticsCameraSelect";
import AnalyticsPlayerSelect from "./AnalyticsPlayerSelect";
import AnalyticsDeviceTypeSelect from "./AnalyticsDeviceTypeSelect";
import AnalyticsCountrySelect from "./AnalyticsCountrySelect";
import AnalyticsRegionSelect from "./AnalyticsRegionSelect";
import AnalyticsContentTypeSelect from "./AnalyticsContentTypeSelect";


function AnalyticsFilterSelect({ apiQuery, setApiQuery, assets }) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button 
                color="primary"
                id="message-menu-button"
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ 
                    //m: '.5em 1em 0.5em .5em',
                    padding: 0,
                    height: 40,
                    width: 45,
                    minWidth: 40,
                    borderRadius: 1, 
                    border: "1px solid rgba(0,0,0,.2)"
                }}
            >
                <FilterListIcon />
            </Button>
            <Menu
              id="message-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                  "aria-labelledby": "message-menu-button",
              }}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              sx={{
                '& .MuiPaper-root': {
                  minWidth: 300,
                },
              }}
            >
                {!apiQuery.camera_id && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                        marginTop={0}
                        key={'camera-select' + apiQuery.camera_id}
                        value={apiQuery.camera_id}
                    >
                        <AnalyticsCameraSelect
                            cameras={assets.cameras}
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )}
                {!apiQuery.player_id && (
                    <Divider style={{borderWidth: 1, margin: '0px 0px 0px 0px'}} />
                )}
                {!apiQuery.player_id && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                    >
                        <AnalyticsPlayerSelect
                            players={assets.players}
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )}
                {!apiQuery.device_type && (
                     <Divider style={{borderWidth: 1, margin: '0px 0px 15px 0px'}} />
                )}
                {!apiQuery.device_type && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                        marginTop={-2}
                    >
                        <AnalyticsDeviceTypeSelect
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )}
                {!apiQuery.content_type && (
                    <Divider style={{borderWidth: 1, margin: '0px 0px 15px 0px'}} />
                )}
                {!apiQuery.content_type && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                        marginTop={-2}
                    >
                        <AnalyticsContentTypeSelect
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )}
                {!apiQuery.country_iso && (
                    <Divider style={{borderWidth: 1, margin: '0px 0px 15px 0px'}} />
                )}
                {!apiQuery.country_iso && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                        marginTop={-2}
                    >
                        <AnalyticsCountrySelect
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )}
                {(apiQuery.country_iso && !apiQuery.region_iso) && (
                    <Divider style={{borderWidth: 1, margin: '0px 0px 15px 0px'}} />
                )}
                {(apiQuery.country_iso && !apiQuery.region_iso) && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                        padding={1}
                        marginTop={-2}
                    >
                        <AnalyticsRegionSelect
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    </Stack>
                )} 
            </Menu>
        </Box>
    );
}

AnalyticsFilterSelect.propTypes = {
    apiQuery: PropTypes.object.isRequired,
    setApiQuery: PropTypes.func.isRequired,
    assets: PropTypes.object.isRequired,
};

AnalyticsFilterSelect.defaultProps = {};

export default AnalyticsFilterSelect;