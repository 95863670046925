import * as React from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LoginIcon from "@mui/icons-material/Login";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Logo from "../images/livespotting.svg";

import { useApp } from "../context/App";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

function Login() {
    const { client, backdrop } = useApp();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const login = async () => {
        if ((username === "") & (password === "")) {
            return;
        } else {
            backdrop(true);
            const data = await client.login(username, password);
            backdrop(false);

            if (data) {
                client.RefreshPage();
            }
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Verhindert das Neuladen der Seite
        await login();
    };

    return (
        <form onSubmit={handleFormSubmit} style={{ width: "100%" }}>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                height={"100vh"}
                width={"100%"}
                margin={"-8px -8px -8px 0px"}
            >
                <Stack></Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-left"
                    spacing={1}
                >
                    <ServiceIcon
                        style={{
                            width: 40,
                            height: 40,
                            position: "fixed",
                            left: "1.1em",
                            top: "1.3em",
                        }}
                    />
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                borderRadius: 2,
                                padding: "0em .5em 0em .5em",
                                width: { xs: 360 },
                                minWidth: { xs: 50, md: 250 },
                                display: "flex",
                                alignItems: "center",
                                height: 60,
                                marginTop: -5,
                            }}
                        >
                            <TextField
                                fullWidth
                                id="username"
                                size="small"
                                variant="outlined"
                                color="primary"
                                placeholder="E-Mail"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Paper>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Paper
                            elevation={username.length > 0 ? 1 : 0}
                            sx={{
                                borderRadius: 2,
                                padding: "0em .5em 0em .5em",
                                width: { xs: 295 },
                                minWidth: { xs: 50, md: 200 },
                                display: "flex",
                                alignItems: "center",
                                height: 60,
                            }}
                        >
                            <FormControl
                                color="primary"
                                size="small"
                                variant="outlined"
                                fullWidth
                            >
                                <OutlinedInput
                                    id="password"
                                    placeholder="Passwort"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    disabled={username.length === 0}
                                    type={showPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={username.length === 0}
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                        <Paper
                            elevation={username.length > 0 ? 2 : 0}
                            sx={{
                                borderRadius: 2,
                                width: 60,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 60,
                                backgroundColor:
                                    password.length > 0
                                        ? "rgb(0, 133, 233)"
                                        : null,
                            }}
                        >
                            <IconButton
                                type="submit"
                                color="success"
                                size="large"
                                aria-label="login"
                                disabled={password.length === 0}
                                autoFocus
                            >
                                <LoginIcon
                                    fontSize="inherit"
                                    style={{
                                        color:
                                            password.length > 0
                                                ? "#fff"
                                                : "rgba(0,0,0,.25)",
                                    }}
                                />
                            </IconButton>
                        </Paper>
                    </Stack>
                    {/* <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        paddingLeft={.5}
                        paddingTop={.5}
                    >
                        <Typography fontSize={13} >Registrieren</Typography>
                        <Typography fontSize={13} >Passwort anfordern</Typography>
                    </Stack> */}
                </Stack>
                <Stack></Stack>
            </Stack>
        </form>
    );
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
