/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        groupedOutlinedPrimary: {
            color: "#000",
            backgroundColor: "rgba(0,0,0,.065)",
            //border: '2px solid rgba(0,0,0,.25)',
            //padding: 5,
            "&:hover": {
                color: "#fff",
                backgroundColor: "rgba(0,0,0,.45)",
            },
            "&:disabled": {
                color: "rgba(0,0,0,.25)",
            },
        },
        groupedContainedPrimary: {
            color: "#000",
            background: "#fff",
            border: "1px solid #fff",
            "&:fucus": {
                border: "1px solid #fff",
                color: "#fff",
                background: "rgba(0,0,0,.45)",
            },
            "&:hover": {
                border: "1px solid #fff",
                color: "#fff",
                background: "rgba(0,0,0,.25)",
            },
            "&:disabled": {
                color: "rgba(0,0,0,.25)",
            },
            "&:not(:last-of-type)": {
                border: "1px solid #fff",
            },
        },
    },
};
