import * as React from "react";

import Divider from "@mui/material/Divider";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControl from "@mui/material/FormControl";
import Button from '@mui/material/Button';
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import moment from "moment";

import DateSelect from "../../components/DateSelect";


function SortBy({ sort, handleSort }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="outlined">
            <InputLabel>Sortieren nach</InputLabel>
            <Select
                value={sort}
                label="sortieren_nach"
                onChange={handleSort}
                size="small"
            >
                <MenuItem value="created_at">Anlagedatum</MenuItem>
                <MenuItem value="camera_meta.name">Name</MenuItem>
                <MenuItem value="camera_meta.city">Stadt</MenuItem>
                <MenuItem value="camera_meta.region">Region</MenuItem>
                <MenuItem value="camera_meta.country">Land</MenuItem>
                <MenuItem value="camera_meta.video_category">
                    Video Kategorie
                </MenuItem>
            </Select>
        </FormControl>
    );
}

SortBy.propTypes = {
    sort: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
};

SortBy.defaultProps = {
    sort: "city",
};

function SortDirection({ sortDirection, handleSortDirection }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="outlined">
            <InputLabel>Sortierrichtung</InputLabel>
            <Select
                value={sortDirection}
                label="sort_direction"
                onChange={handleSortDirection}
                size="small"
                fullWidth
            >
                <MenuItem value="asc">A-z</MenuItem>
                <MenuItem value="desc">z-A</MenuItem>
            </Select>
        </FormControl>
    );
}

SortDirection.propTypes = {
    sortDirection: PropTypes.string,
    handleSortDirection: PropTypes.func.isRequired,
};

SortDirection.defaultProps = {
    sortDirection: "asc",
};

function VideoFilter({
    sort,
    handleSort,
    sortDirection,
    handleSortDirection,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    limit,
    setLimit,
}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button 
                color="primary"
                id="message-menu-button"
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ 
                    m: '.5em .5em 0.5em .5em',
                    padding: 0,
                    height: 40,
                    width: 45,
                    minWidth: 40,
                    boxShadow: 1, 
                    borderRadius: 1, 
                    background: "#fff", 
                    border: "1px solid rgba(0,0,0,.2)"
                }}
            >
                <FilterListIcon />
            </Button>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                    minWidth={"250px"}
                    paddingTop={3}
                >
                    <SortBy sort={sort} handleSort={handleSort} />
                </Stack>
                <Divider
                    style={{ borderWidth: 1, margin: "0px 0px 15px 0px" }}
                />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                    minWidth={"250px"}
                >
                    <SortDirection
                        sortDirection={sortDirection}
                        handleSortDirection={handleSortDirection}
                    />
                </Stack>
                <Divider
                    style={{ borderWidth: 1, margin: "0px 0px 15px 0px" }}
                />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                >
                    <DateSelect
                        label={"Startzeitpunkt"}
                        value={fromDate}
                        setValue={setFromDate}
                    />
                </Stack>
                <Divider
                    style={{ borderWidth: 1, margin: "0px 0px 15px 0px" }}
                />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                >
                    <DateSelect
                        label={"Endzeitpunkt"}
                        value={toDate}
                        setValue={setToDate}
                    />
                </Stack>
                <Divider
                    style={{ borderWidth: 1, margin: "0px 0px 15px 0px" }}
                />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                >
                    <TextField
                        id="limit"
                        label="Maximale Videos"
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 2500 } }}
                        value={limit}
                        onChange={(event) => {
                            event.target.value >= 1 &&
                            event.target.value <= 2500
                                ? setLimit(event.target.value)
                                : setLimit(limit);
                        }}
                    />
                </Stack>
            </Menu>
        </div>
    );
}

VideoFilter.propTypes = {
    sort: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
    sortDirection: PropTypes.string,
    handleSortDirection: PropTypes.func.isRequired,
    fromDate: PropTypes.instanceOf(moment),
    setFromDate: PropTypes.func,
    toDate: PropTypes.instanceOf(moment),
    setToDate: PropTypes.func,
    limit: PropTypes.number,
    setLimit: PropTypes.func,
};

VideoFilter.defaultProps = {};

export default VideoFilter;
