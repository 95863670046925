/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        inputRoot: {
            paddingBottom: "6px!important",
        },
        listbox: {
            margin: -25,
        },
        option: {
            // '&[data-focus="true"]': {
            //     backgroundColor: '#000!important',
            // },
            margin: 0,
            padding: 0,
            paddingLeft: "15px!important",
            paddingRight: "15px!important",
            borderBottom: `1px solid rgba(255,255,255, .2)`,
            '&[aria-selected="true"]': {
                color: "#fff!important",
                backgroundColor: "#0084E8!important",
            },
            "&.Mui-focused": {
                color: "#fff!important",
                backgroundColor: "rgba(0,132,232,.9)!important",
            },
        },
    },
};
