import * as React from "react";

import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from "@mui/material/Tooltip";

import AnalyticsShare from "../AnalyticsShare";

export default function AnalyticsShareButton({apiQuery}) {

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [tooltipText, setTooltipText] = React.useState("Kopieren");

    const handleCopyClick = () => {
        const currentURL = window.location.href.split('?share=')[0];
        const apiQueryToShare = AnalyticsShare({crypt: true, apiQueryUncrypt: apiQuery, apiQueryCrypt:""});

        navigator.clipboard
            .writeText(`${currentURL}?share=${apiQueryToShare}`)
            .then(() => {
                setTooltipText("Kopiert");
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                    setTooltipText("Kopieren");
                }, 2000);
            })
            .catch((error) => {
                setTooltipText("Kopieren");
            });
    };

    return (
        <Tooltip title={tooltipText} arrow open={tooltipOpen} placement="top">
            <Button 
                color="primary"
                id="message-menu-button"
                onClick={handleCopyClick}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                sx={{ 
                    //m: '.5em 1em 0.5em .5em',
                    padding: 0,
                    height: 40,
                    width: 45,
                    minWidth: 40,
                    borderRadius: 1, 
                    border: "1px solid rgba(0,0,0,.2)",
                    color: "rgba(0,0,0,.75)",
                }}
            >
                <ShareIcon />
            </Button>
        </Tooltip>
    );
};
