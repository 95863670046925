import * as React from 'react';

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import Link from '@mui/material/Link';
import HistoryIcon from '@mui/icons-material/History';

import "moment-timezone";
import moment from "moment";
import "moment/locale/de";

import DateSelect from "../../../components/DateSelect";

import AnalyticsStepSelect from "./AnalyticsStepSelect";


function AnalyticsDateRangeSelect({ apiQuery, setApiQuery, maxDaysForHour, storageDateRange, onChangeRange }) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setFromDate = (newValue) => {
    setApiQuery(prevApiQuery => (
      {
        ...prevApiQuery,
        from_date: moment(newValue).hours(0).minutes(0).seconds(0)
      }
    ))
  };
  const setToDate = (newValue) => {
    setApiQuery(prevApiQuery => (
      {
        ...prevApiQuery,
        to_date: moment(newValue).hours(0).minutes(0).seconds(0)
      }
    ))
  };
  const handleShortcut = (value) => {
    setApiQuery(prevApiQuery => (
      {
        ...prevApiQuery,
        from_date: moment().subtract(value + 1, "days").hours(0).minutes(0).seconds(0),
        to_date: moment().subtract(1, "days").hours(0).minutes(0).seconds(0),
        step: value > 300 ? "month" : value >= 90 ? "week" : "day"
        //step: "day"
      }
    ))
  };

  const handleChangeStep = (step) => {
    onChangeRange(apiQuery.from_date, apiQuery.to_date, step);
  };

  const minDateTime = moment(storageDateRange[0]);
  const maxDateTime = moment(storageDateRange[1]);

  return (
    <Box>
      <Button
        onClick={handleClick}
        color='primary'
        sx={{
          minHeight: 40,
          padding: "6px 14px",
          color: "#000",
          fontWeight: 400,
          borderRadius: 1, 
          background: "#fff", 
          border: "1px solid rgba(0,0,0,.2)"
        }}
      >
        <HistoryIcon sx={{marginRight: 1}} /> 
        {moment(apiQuery.from_date).format('DD.MM.YY')} - 
        {moment(apiQuery.to_date).format('DD.MM.YY')}
      </Button>
      <Menu
        id="message-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "message-menu-button",
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 200,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
          padding={4}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
            sx={{
              paddingTop: "-2px!important",
            }}
          >
            <Link onClick={() => handleShortcut(7)} 
              color="inherit"
              sx={{
                marginTop: "-10px!important",
              }}
            >
              {'Letzte 7 Tage'}
            </Link>
            <Link onClick={() => handleShortcut(28)} color="inherit">
              {'Letzte 28 Tage'}
            </Link>
            <Link onClick={() => handleShortcut(90)} color="inherit">
              {'Letzte 90 Tage'}
            </Link>
            <Link onClick={() => handleShortcut(365)} color="inherit">
              {'Letzte 365 Tage'}
            </Link>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            padding={"0px 0px 0px 0px"}
            marginTop={-2}
            marginRight={-1}
          >
            <TextField
                id="from_date"
                label={"Startzeitpunkt"} 
                type="date"
                name="from_date"
                value={moment(apiQuery.from_date).format('YYYY-MM-DD')} 
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{
                    sx: {
                        backgroundColor: '#fff',
                        shrink: true,
                    },
                }}
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                id="to_date"
                label={"Endzeitpunkt"} 
                type="date"
                name="from_date"
                value={moment(apiQuery.to_date).format('YYYY-MM-DD')} 
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{
                    sx: {
                        backgroundColor: '#fff',
                        shrink: true,
                    },
                }}
                variant="outlined"
                size="small"
                color="secondary"
            />
            <AnalyticsStepSelect
              step={apiQuery.step}
              // disableHours={
              //     moment(apiQuery.to_date).diff(
              //         moment(apiQuery.from_date),
              //         "days",
              //     ) > maxDaysForHour
              // }
              onChangeStep={handleChangeStep}
            />
          </Stack>
        </Stack>
      </Menu>
    </Box>
  );
}

AnalyticsDateRangeSelect.propTypes = {
  apiQuery: PropTypes.object.isRequired,
  setApiQuery: PropTypes.func.isRequired,
  maxDaysForHour: PropTypes.number.isRequired,
  storageDateRange: PropTypes.array.isRequired,
  onChangeRange: PropTypes.func.isRequired,
};

AnalyticsDateRangeSelect.defaultProps = {};

export default AnalyticsDateRangeSelect;