import * as React from 'react';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import Select from '@mui/material/Select';


function AnalyticsSegmentSelect({ segment, apiQuery, setApiQuery, hasPermission }) {
  const cameraRoles = [
    "admin",
    "editor",
    "viewer",
    "camera:viewer",
    "camera:editor",
    "camera:admin",
  ];
  const playbackPlayerRoles = [
      "admin",
      "editor",
      "viewer",
      "player:viewer",
      "player:editor",
      "player:admin",
  ];
  const playbackUserRoles = [
      "admin",
      "editor",
      "viewer",
      "player:viewer",
      "player:editor",
      "player:admin",
  ];
  const adminRoles = ["admin"];
  
  function setSegmentSet() {
    const segmentSet = [];
    if (hasPermission(cameraRoles)) {
      segmentSet.push(
        {
          name: "Kameras",
          value: "camera",
          requires: null,
        }
      );
    }
    if (hasPermission(playbackPlayerRoles)) {
      segmentSet.push(
        {
          name: "Player",
          value: "player",
          requires: null,
        }
      );
    }
    if (hasPermission(playbackUserRoles)) {
      segmentSet.push(
        {
          name: "Direktzugriffe",
          value: "playback_user",
          requires: null,
        }
      );
    }
    if (hasPermission(adminRoles)) {
      segmentSet.push(
        {
          name: "Zugänge",
          value: "user",
          requires: null,
        }
      );
    }
    return segmentSet;
  }

  

  function setDimension1(segment) {
    const dimForChange = ["camera_id", "player_id", "playback_user_id", "user_email"];
    if (apiQuery.dimension1 !== null && dimForChange.includes(apiQuery.dimension1)) {
      return segment === "camera" ? "camera_id" : segment === "player" ? "player_id" : segment === "playback_user" ? "playback_user_id" : segment === "user" ? "user_email" : null;
    }
    return null;
  }

  const handleChange = (event) => {
    const newDimension1 = setDimension1(event.target.value);
    setApiQuery(prevApiQuery => (
      { ...prevApiQuery,
        [segment]: event.target.value ? event.target.value : null,
        dimension1: newDimension1 ? newDimension1 : prevApiQuery.dimension1,
      }));
  };
  

  const value = () => {
    return apiQuery[segment] ? apiQuery[segment] : "";
  }

  return (
    <FormControl 
      sx={{ 
        m: '.5em 1em 0.5em 0em', 
        minWidth: 80, 
        boxShadow: 1, 
        borderRadius: 1, 
        background: "#fff", 
        opacity: apiQuery[segment] ? 1 : 0.5
      }}
      disabled={segment !== "segment1"}
      size="small"
    >
      <Select
        labelId="analytics-segment-select-label"
        id="analytics-segment-select"
        value={value()}
        onChange={handleChange}
        color="secondary"
        displayEmpty
      >
        {setSegmentSet().map((dim) => (
          <MenuItem key={dim.name} value={dim.value}>
            {dim.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

AnalyticsSegmentSelect.propTypes = {
  segment: PropTypes.string.isRequired,
  apiQuery: PropTypes.object.isRequired,
  setApiQuery: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
};

AnalyticsSegmentSelect.defaultProps = {
  segment: "camera",
  apiQuery: {},
  setApiQuery: () => {},
  hasPermission: () => {},
};

export default AnalyticsSegmentSelect;