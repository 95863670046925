import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import RestreamCameraProfileSelect from '../RestreamCameraProfileSelect';
import RestreamPublicationProvidersSelect from '../RestreamPublicationProvidersSelect';
import RestreamScheduler from '../RestreamScheduler';

export function RestreamEditForm({ client, reloadPage, restream, handleClose }) {
	const [formValuesChanged, setFormValuesChanged] = React.useState(false);
	const [formValues, setFormValues] = React.useState(null);
	const [cameras, setCameras] = React.useState(null);

	React.useEffect(() => {
		const thisFormValues = {
			...restream,
			publication_provider: restream.publication_provider.id,
			schedule: restream.scheduler ? true : false,
			schedule_start: restream.scheduler ? restream.scheduler : '',
			schedule_end: restream.scheduler && restream.runtime_duration_seconds ? GetScheduleEnde(restream) : '',
		};
		setFormValues(thisFormValues);
		setCameras([restream.camera]);
		// eslint-disable-next-line
	}, [restream]);

	function GetScheduleEnde({ obj }) {
		if (!obj.schedule_end || obj.schedule_end === '') return '';
		const start = Date.parse(obj.schedule_start);
		const end = Date.parse(obj.schedule_end);
		const differenceInSeconds = (end - start) / 1000;
		return differenceInSeconds;
	}

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === 'checkbox' ? checked : value;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: newValue,
		}));
		setFormValuesChanged(true);
	};

	const updateRestream = async () => {
		const restreamUpdate = {
			name: formValues.name,
			description: formValues.description,
			camera_input_id: formValues.camera_input_id,
			publication_token: formValues.publication_token,
			publication_url: formValues.publication_url,
			scheduler: formValues.scheduler,
			runtime_duration_seconds: formValues.runtime_duration_seconds,
			output_config: formValues.output_config,
		};
		await client.PutCustomerRestreamId({
			restream_id: restream.id,
			restream: restreamUpdate,
		});
		handleClose();
		reloadPage();
	};

	if (!formValues) {
		return null;
	}

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={2}>
			<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} width={'100%'} minWidth={400}>
				{/* <TextField
                    id="name"
                    label="Name"
                    name="name"
                    value={formValues.name || ""}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="secondary"
                /> */}
				{/* <TextField
                    id="description"
                    label="Beschreibung"
                    name="description"
                    value={formValues.description || ""}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    color="secondary"
                /> */}
				<RestreamPublicationProvidersSelect formValues={formValues} handleChange={handleChange} providerLock={true} />
				<RestreamCameraProfileSelect formValues={formValues} handleChange={handleChange} cameras={cameras} />
				<RestreamScheduler formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} />
			</Stack>
			<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} width={'100%'} paddingBottom={1}>
				<Button variant="outlined" color="primary" size="medium" onClick={handleClose}>
					Abbrechen
				</Button>
				<Button variant="outlined" color="primary" size="medium" onClick={updateRestream} disabled={!formValuesChanged} autoFocus>
					Ändern
				</Button>
			</Stack>
		</Stack>
	);
}

RestreamEditForm.propTypes = {
	client: PropTypes.object,
	reloadPage: PropTypes.func,
	restream: PropTypes.object,
	handleClose: PropTypes.func,
};

RestreamEditForm.defaultProps = {
	restream: {},
	handleClose: () => {},
};

export default RestreamEditForm;
