import * as React from 'react';
import PropTypes from 'prop-types';

import 'moment-timezone';
import moment from 'moment';

import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Moment from 'react-moment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useApp } from '../../../context/App';

import AlertEditDialog from '../../../components/AlertEditDialog';

const error_keywords = {
	failed_to_resolve_hostname: 'Die Kameraadresse konnte nicht aufgelöst werden. Bitte überprüfen Sie den hinterlegten Domainnamen.',
	host_is_unreachable: 'Die Kameraadresse ist nicht erreichbar. Bitte überprüfen Sie Ihre Netzwerkverbindung.',
	forbidden: 'Zugriff verweigert. Bitte überprüfen Sie die Zugangsdaten der Kamera oder ihres Streaming-Servers.',
	unauthorized: 'Nicht autorisiert. Bitte überprüfen Sie die Zugangsdaten der Kamera.',
	invalid_data_found: 'Die Kamera produziert ungültige Videodaten. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	io_error: 'Der RTMP Stream konnte nicht geöffnet werden. Bitte überprüfen Sie, ob ihr RTMP-Stream-Sendeverfahren korrekt konfiguriert ist.',
	not_found: 'Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Freigabe auf ihrem Streaming-Server.',
	could_not_open: 'Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	could_not_find_codec_parameters_for_stream:
		'Die Kamera liefert unvollständige Videodaten. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	got_error_from_av_read_frame: 'Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	immidiate_exit_requested: 'Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	update_your_ffmpeg_version: 'Die Kamera produziert ungültige Daten. Bitte wenden Sie sich an den Support.',
	option_playout_uuid_not_found: 'Es wurde eine Unstimmigkeit in der Konfiguration festgestellt. Bitte wenden Sie sich an den Support.',
	got_error: 'Die Kamera produziert einen unbekannten Fehler. Bitte wenden Sie sich an den Support.',
	not_connected: 'Die Kamera ist nicht verbunden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	connected: 'Die Kamera ist verbunden.',
	fps_failed: 'Die Kamera liefert unter 15 Bilder pro Sekunde (FPS). Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.',
	stopped: 'Der Kamerastream wurde im Kamera-Management gestoppt.',
};

function transAlertStatus(status) {
	if (status === 'open') {
		return 'Offen';
	} else if (status === 'closed') {
		return 'Geschlossen';
	} else if (status === 'in_progress') {
		return 'In Arbeit';
	} else if (status === 'waiting') {
		return 'Wartend';
	} else {
		return status;
	}
}

function AlertActive({ status, alert }) {
	return (
		<Grid container justifyContent="space-between" fontSize={14} spacing={1}>
			{alert && alert.created_at && (
				<React.Fragment>
					<Grid item xs={3} fontWeight={600}>
						Status:
					</Grid>
					<Grid item xs={9} style={{ wordWrap: 'break-word' }}>
						{transAlertStatus(alert.status ? alert.status : 'open')}
					</Grid>
					<Grid item xs={3} fontWeight={600}>
						Statusmeldung:
					</Grid>
					<Grid item xs={9} style={{ wordWrap: 'break-word' }}>
						{alert.status_message && (
							<Typography fontSize={14} key={alert.status_message}>
								{alert.status_message}
							</Typography>
						)}
						{!alert.status_message && 'Keine Statusmeldung vorhanden.'}
					</Grid>
					<Grid item xs={3} fontWeight={600}>
						Fehlermeldung:
					</Grid>
					<Grid item xs={9} style={{ wordWrap: 'break-word' }}>
						{alert.messages &&
							alert.messages.length > 0 &&
							alert.messages.map((message) => (
								<Typography fontSize={14} key={message}>
									{message.split(',')[0] in error_keywords ? error_keywords[message.split(',')[0]] : message}
								</Typography>
							))}
					</Grid>
					<Grid item xs={3} fontWeight={600}>
						Erstellt:
					</Grid>
					<Grid item xs={9}>
						<Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
							{moment(alert.created_at).tz(moment.tz.guess())}
						</Moment>
					</Grid>
				</React.Fragment>
			)}
			{(!alert || !alert.created_at) && (
				<Grid item xs={12}>
					{status === 'healthy' ? 'Es wurde keine Störung erkannt.' : 'Die Kamera ist aktuell nicht verbunden.'}
				</Grid>
			)}
		</Grid>
	);
}

AlertActive.propTypes = {
	alert: PropTypes.object,
};

AlertActive.defaultProps = {};

function AlertLog({ log }) {
	return (
		<React.Fragment>
			<Grid item xs={3}>
				<Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
					{moment(log.ts).tz(moment.tz.guess())}
				</Moment>
			</Grid>
			<Grid item xs={9} style={{ wordWrap: 'break-word' }}>
				{log.message ? (
					log.message.split(',')[0] in error_keywords ? (
						error_keywords[log.message.split(',')[0]]
					) : (
						log.message
					)
				) : (
					<Chip size="small" style={{ color: '#fff' }} color={log.state === 'open' ? 'danger' : 'success'} label={log.state} />
				)}
			</Grid>
		</React.Fragment>
	);
}

AlertLog.propTypes = {
	log: PropTypes.object,
};

AlertLog.defaultProps = {};

function RestreamAlert({ restream }) {
	const { client } = useApp();
	const [alert, setAlert] = React.useState(false);
	const loading = !alert;

	React.useEffect(() => {
		if (!loading) {
			return undefined;
		}
		(async () => {
			if (!alert) {
				try {
					const data = await client.GetCustomerRestreamIdAlert({
						restream_id: restream.id,
					});
					setAlert(data);
				} catch (error) {
					console.log(error.response.data);
				}
			}
		})();
		return () => {};
		// eslint-disable-next-line
	}, [alert]);

	if (loading) {
		return (
			<React.Fragment>
				<Typography marginTop={0.5} marginBottom={1.5} variant="h4">
					Aktive Störung
				</Typography>
				<Stack
					style={{
						padding: '10px 15px 15px 15px',
						marginBottom: 5,
						backgroundColor: 'rgba(0,0,0,.035)',
						borderRadius: 4,
					}}
					spacing={0}
				>
					<CircularProgress color="inherit" size={20} />
				</Stack>
			</React.Fragment>
		);
	}

	const user = client.UserConfig();

	return (
		<React.Fragment>
			<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} marginTop={0.5} marginBottom={1.5}>
				<Typography variant="h4">Aktive Störung</Typography>
				{alert.alert && user.type === 'superuser' && <AlertEditDialog alert={alert.alert} client={client} />}
			</Stack>
			<Stack
				style={{
					padding: '10px 15px 15px 15px',
					marginBottom: 5,
					backgroundColor: 'rgba(0,0,0,.035)',
					borderRadius: 4,
				}}
				spacing={0}
			>
				<AlertActive status={restream.status} alert={alert.alert} />
			</Stack>
			<Typography marginTop={2.5} variant="h4">
				Störungsmeldungen
			</Typography>
			<Typography fontSize={15} marginTop={0.5} marginBottom={2}>
				Protokolle aus den vergangenen 24 Stunden:
			</Typography>
			<Stack
				style={{
					padding: '10px 15px 15px 15px',
					marginBottom: 5,
					backgroundColor: 'rgba(0,0,0,.035)',
					borderRadius: 4,
					maxHeight: 200,
					overflowX: 'scroll',
				}}
				spacing={0}
			>
				<Grid container justifyContent="space-between" fontSize={14} spacing={1}>
					<Grid item xs={3} fontWeight={600}>
						Datum:
					</Grid>
					<Grid item xs={9} fontWeight={600}>
						Mitteilung:
					</Grid>
					{alert.alert_log && alert.alert_log.length > 0 && alert.alert_log.map((log, index) => <AlertLog key={index} log={log} />)}
				</Grid>
			</Stack>
		</React.Fragment>
	);
}

RestreamAlert.propTypes = {
	restream: PropTypes.object,
};

RestreamAlert.defaultProps = {};

export default RestreamAlert;
