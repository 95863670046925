import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

const findCamera = (cameras, camera_id) => {
    if (!cameras) {
        return { id: "", meta: { name: "", city: "", country: "" } };
    }

    const camera = cameras.find((element) => {
        return element.id === camera_id;
    });

    return camera
        ? camera
        : { id: "", meta: { name: "", city: "", country: "" } };
};

function AnalyticsCameraSelect({ cameras, apiQuery, setApiQuery }) {
    const [isSlected, setIsSelected] = React.useState(
        findCamera(cameras, apiQuery.camera_id ? apiQuery.camera_id : ""),
    );

    if (!cameras) {
        return null;
    }

    const options = cameras
        .map(({ id, meta }) => ({
            id: id,
            name: meta.name,
            city: meta.city,
            country: meta.country,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((obj, index, array) => {
            const currentIndex = array.findIndex(
                (item) => item.name === obj.name && item.city === obj.city,
            );
            return currentIndex === index;
        })
        .sort((a, b) => -(a.country || "").localeCompare(b.country || ""));

    const selectedCameraValue = (camera) => {
        if (!camera.meta) {
            return camera;
        }
        const { id, meta } = camera;
        return {
            id: id,
            name: meta.name,
            city: meta.city,
            country: meta.country,
        };
    };

    return (
        <Autocomplete
            value={isSlected && isSlected.id ? selectedCameraValue(isSlected) : null}
            size="small"
            id="camera-select"
            options={options ? options : []}
            groupBy={(option) => option.country}
            getOptionLabel={(option) => {
                if (!option.id) return "";
                return `${option.name} (${option.city})`;
            }}
            getOptionKey={(option) => option.id}
            onChange={(event, newValue) => {
                setIsSelected(newValue);
                setApiQuery((prevApiQuery) => ({
                    ...prevApiQuery,
                    camera_id: newValue ? newValue.id : null,
                }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    color={isSlected.id ? "secondary" : "primary"}
                    label={isSlected.id ? null : "Kamera"}
                    sx={{
                        width: isSlected.id ? "200px" : null,
                    }}
                />
            )}
        />
    );
}

AnalyticsCameraSelect.propTypes = {
    cameras: PropTypes.array,
    setApiQuery: PropTypes.func.isRequired,
};

AnalyticsCameraSelect.defaultProps = {};

export default AnalyticsCameraSelect;
