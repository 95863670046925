/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        colorSecondary: {
            border: "1px solid rgba(0,0,0,.18)",
            borderWidth: "1px",
            "&:focus": {
                border: "0px solid rgba(0,0,0,.18)",
            },
            "&:hover": {
                border: "1px solid rgba(0,0,0,.18)",
            },
            notchedOutline: {
                borderWidth: 0,
            },
        },
    },
};
