import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

export default function AnalyticsStepSelect({
    step,
    disableHours,
    onChangeStep,
}) {
    const handleChange = (event) => {
        onChangeStep(event.target.value);
    };

    return (
        <TextField
            select
            size="small"
            label="Auflösung"
            id="anlytics-step-select"
            value={step}
            onChange={handleChange}
            color="secondary"
            variant="outlined"
            InputLabelProps={{
                sx: {
                    backgroundColor: '#fff',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    fontSize: '1rem!important',
                }
            }}
        >
            {/* <MenuItem disabled={disableHours} value="hour">
                Stunde
            </MenuItem> */}
            <MenuItem value="day">Tag</MenuItem>
            <MenuItem value="week">Woche</MenuItem>
            <MenuItem value="month">Monat</MenuItem>
        </TextField>
    );
}
