import React, { useState, useEffect } from 'react';

import CircleIcon from '@mui/icons-material/Circle';


const fadeInOutAnimation = `
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: .6; }
  }

  .fadeInOut {
    animation: fadeInOut 2s ease-in-out;
  }
`;


function BlinkingIcon({
  metric = []
}) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 2000);
    return () => clearTimeout(timer);
  }, [metric]);

  return (
    <>
      <style>{fadeInOutAnimation}</style>
      <CircleIcon
        className={animate ? 'fadeInOut' : ''}
        sx={{
          color: '#fff',
          backgroundColor: '#0084E8',
          opacity: animate ? .6 : 0,
          width: "15px",
          height: "15px",
        }}
      />
    </>
  );
}

export default BlinkingIcon;
