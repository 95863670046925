import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';

export default function Twitch(props) {
	return {
		id: 'twitch',
		verion: '1.0',
		name: 'Twitch',
		description: 'Stream to Twitch',
		category: 'Social',
		help_link: 'https://help.twitch.tv/s/article/twitch-stream-key-faq',
		stream_key_link: 'https://twitch.com/dashboard/settings/stream',
		icon: <FontAwesomeIcon icon={faTwitch} style={{ color: '#9147FF', ...props }} />,
		stream_requirements: {
			video_codec: ['h264'],
			audio_codec: ['aac'],
			video_resolution: ['1280x720', '1920x1080', '2560x1440', '3840x2160'],
			video_fps: {
				min: 15,
				max: 60,
			},
			video_bitrate: {
				min: 300,
				max: 24000,
			},
			audio_bitrate: {
				min: 64,
				max: 320,
			},
			audio_channels: 2,
		},
		stream_targets: [
			{
				address: 'rtmp://live.twitch.tv/app/{token}',
				options: ['-f', 'flv'],
				type: 'primary',
				protocol: 'rtmp',
				region: 'international',
			},
		],
	};
}
