/* eslint-disable import/no-anonymous-default-export */
import base from "./base";
import bgImage from "../images/bg.png";
import bgWImage from "../images/bg-white.png";

export default {
    html: {
        width: "100%",
        height: "100%",
    },
    body: {
        backgroundImage: `url(${bgWImage}),url(${bgImage})`,
        backgroundSize: "100% -50px,auto",
        backgroundRepeat: "no-repeat,repeat-y",
        backgroundPosition: "top center,center -50px",
    },
    code: {
        fontFamily: "soure-code-pro, monospace",
    },
    textarea: {
        width: "100%",
        backgroundColor: base.palette.background.modalbox,
        fontFamily: "monospace",
        fontSize: ".85rem",
        whiteSpace: "pre",
        overflow: "auto",
        color: base.palette.text.primary,
        border: "0",
        resize: "none",
        wordWrap: "inherit",
        wordBreak: "inherit",
        overflowWrap: "normal",
    },
    "*:focus": {
        outline: "none",
    },
    "::-webkit-scrollbar-corner": {
        background: "transparent",
    },
    // firefox
    "@-moz-document url-prefix(http://),url-prefix(https://)": {
        "thumb,scrollbarbutton": {
            MozAppearance: "none !important",
            backgroundColor: "transparent !important",
        },
        "thumb:hover,scrollbarbutton:hover": {
            MozAppearance: "none !important",
            backgroundColor: "transparent !important",
        },
        scrollbarbutton: {
            display: "none !important",
        },
        'scrollbar[orient="vertical"]': {
            minWidth: "6px !important",
        },
    },

    // Extend Textarea
    ".textarea": {
        backgroundColor: base.palette.background.modalbox,
        borderRadius: 4,
        padding: "0em 1em 0em 1em",
    },

    // Extend MuiButton
    ".probeButton": {
        height: "56px!important",
    },
};
