import * as React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function End({
    disableNext,
    setDisableNext,
    setDisableBack,
    otpEnabled,
    otpData,
}) {
    React.useEffect(() => {
        if ((otpData.enabled && otpEnabled) || !otpData.enabled) {
            setDisableBack(true);
        } else {
            setDisableBack(false);
        }
    }, [setDisableBack, otpData.enabled, otpEnabled]);

    const otpResetSecret = () => {
        const otp_reset_secret = [];
        for (let i = 0; i < otpData.otp_reset_secret.length; i += 8) {
            const substring = otpData.otp_reset_secret.substr(i, 8);
            otp_reset_secret.push(substring);
        }
        return otp_reset_secret;
    };

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginTop={-2}
            marginBottom={1}
            padding={0}
            spacing={1.5}
        >
            <Grid item xs={12}>
                <Typography variant="h4">
                    Registrierung abgeschlossen
                </Typography>
                <Divider height={1} />
            </Grid>
            {otpData.opt_enable && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography fontSize={18} fontWeight={600}>
                            Wiederherstellungsschlüssel
                        </Typography>
                    </Grid>
                    {otpResetSecret().map((otp_key) => (
                        <Grid item xs={3} key={otp_key} textAlign={"center"}>
                            <Typography
                                border={"1px solid rgba(0,0,0,.25)"}
                                borderRadius={1}
                            >
                                {otp_key}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Typography
                            fontSize={14}
                            fontWeight={600}
                            marginTop={1}
                            color="error"
                        >
                            Bitte bewahren Sie diesen Schlüssel an einem
                            sicheren Ort auf. Ohne diesen Schlüssel kann der
                            zweite Faktor und damit auch der Zugang nicht
                            zurückgesetzt werden!
                        </Typography>
                    </Grid>
                </React.Fragment>
            )}
            <Grid item xs={12}>
                <Typography marginTop={1}>
                    Vielen Dank für die erfolgreiche Registrierung. Für den
                    Zugriff klicken Sie bitte auf "erneut einloggen".
                </Typography>
            </Grid>
        </Grid>
    );
}

End.propTypes = {
    disableNext: PropTypes.bool.isRequired,
    setDisableNext: PropTypes.func.isRequired,
    setDisableBack: PropTypes.func.isRequired,
    otpEnabled: PropTypes.bool.isRequired,
    otpData: PropTypes.object,
};

End.defaultProps = {};

export default End;
