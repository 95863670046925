import * as React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import PublicationProviderRegistry from './PublicationProviderRegistry';

function PublicationToken({ PublicationProviderSelected, formValues, handleChange }) {
	const isError = () => {
		return !formValues.publication_token || (formValues.publication_token && formValues.publication_token.length < 10);
	};
	return (
		<TextField
			id="restream-publication-provider-select-token"
			name="publication_token"
			value={formValues.publication_token || ''}
			label="Plattform Token"
			onChange={handleChange}
			size="small"
			fullWidth
			variant="outlined"
			color="secondary"
			error={isError()}
			helperText={
				<Link href={PublicationProviderSelected.help_link} target="_blank" rel="noopener">
					Zur Hilfeseite der Plattform
				</Link>
			}
			InputLabelProps={{
				sx: {
					backgroundColor: '#fff',
				},
			}}
			FormHelperTextProps={{
				sx: {
					color: '#000!important',
					fontSize: '11px',
					'.MuiLink-root': { color: '#000' },
				},
			}}
			InputProps={{
				endAdornment: (
					<Button
						size="small"
						padding={'1px 16px!important'}
						variant="outlined"
						color="primary"
						component={Link}
						href={PublicationProviderSelected.stream_key_link}
						target="_blank"
						rel="noopener"
					>
						Erstellen
					</Button>
				),
			}}
		/>
	);
}

function PublicationUrl({ PublicationProviderSelected, formValues, handleChange }) {
	const isError = () => {
		return (
			!formValues.publication_url ||
			formValues.publication_url === '' ||
			(formValues.publication_url && !formValues.publication_url.includes(PublicationProviderSelected.stream_targets[0].protocol))
		);
	};
	return (
		<TextField
			id="restream-publication-provider-select-url"
			name="publication_url"
			value={formValues.publication_url || ''}
			label="Plattform URL"
			onChange={handleChange}
			size="small"
			fullWidth
			variant="outlined"
			color="secondary"
			placeholder={PublicationProviderSelected.stream_targets[0].address}
			error={isError()}
			helperText={`Bitte füge hier die vollständige ${PublicationProviderSelected.stream_targets[0].protocol.toUpperCase()}-Server Adresse ein.`}
			InputLabelProps={{
				sx: {
					backgroundColor: '#fff',
				},
			}}
			FormHelperTextProps={{
				sx: {
					marginTop: 1,
					color: '#000!important',
					fontSize: '12px',
					'.MuiLink-root': { color: '#000' },
				},
			}}
		/>
	);
}

function PublicationProvider(provider) {
	return (
		<MenuItem key={provider.id} value={provider.id}>
			<span style={{ marginRight: 10, paddingTop: 5 }}>{provider.icon}</span> {provider.name}
		</MenuItem>
	);
}

function RestreamPublicationProvidersSelect({ formValues, handleChange, providerLock }) {
	React.useEffect(() => {
		handleChange({
			target: {
				name: 'camera_input_id',
				value: '0',
			},
		});
		// eslint-disable-next-line
	}, [formValues.publication_provider]);

	React.useEffect(() => {
		if (PublicationProviderSelected) {
			// todo: stream_targets[0] ist zu statisch
			let address = '';
			if (
				formValues.publication_token !== '' &&
				PublicationProviderSelected.stream_targets[0].address.includes('{token}') &&
				formValues.publication_token
			) {
				address = PublicationProviderSelected.stream_targets[0].address.replace('{token}', formValues.publication_token);
			} else {
				address = formValues.publication_url;
			}
			const options = ['-c:v', 'copy', '-c:a', 'copy', ...PublicationProviderSelected.stream_targets[0].options];
			const outputConfig = [
				{
					id: '0',
					address: address,
					cleanup: null,
					options: options,
				},
			];
			handleChange({ target: { name: 'output_config', value: outputConfig } });
		}
		// eslint-disable-next-line
	}, [formValues.publication_provider, formValues.publication_token, formValues.publication_url]);

	if (!formValues) {
		return null;
	}

	const publicationProviderRegistry = PublicationProviderRegistry({ height: 18 });
	const publicationProviderRegistrySorted = publicationProviderRegistry.sort((a, b) => a.name.localeCompare(b.name));
	const PublicationProviderSelected = publicationProviderRegistry.find((service) => service.id === formValues.publication_provider);

	return (
		<React.Fragment>
			<TextField
				id="restream-publication-provider-select"
				select
				name="publication_provider"
				value={formValues.publication_provider || ''}
				label="Plattform"
				onChange={handleChange}
				disabled={providerLock}
				size="small"
				fullWidth
				variant="outlined"
				color="secondary"
				InputLabelProps={{
					sx: {
						backgroundColor: '#fff',
					},
				}}
			>
				{publicationProviderRegistrySorted.map((service) => PublicationProvider(service))}
			</TextField>
			{PublicationProviderSelected && PublicationProviderSelected.stream_targets[0].address.includes('{token}') && (
				<PublicationToken PublicationProviderSelected={PublicationProviderSelected} formValues={formValues} handleChange={handleChange} />
			)}
			{PublicationProviderSelected && !PublicationProviderSelected.stream_targets[0].address.includes('{token}') && (
				<PublicationUrl PublicationProviderSelected={PublicationProviderSelected} formValues={formValues} handleChange={handleChange} />
			)}
		</React.Fragment>
	);
}

RestreamPublicationProvidersSelect.propTypes = {
	formValues: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	providerLock: PropTypes.bool,
};

RestreamPublicationProvidersSelect.defaultProps = {
	providerLock: false,
};

export default RestreamPublicationProvidersSelect;
