import * as React from "react";

import { Typography } from "@mui/material";

import AnalyticsDimensionSelect from "./AnalyticsDimensionSelect";
import AnalyticsSegmentSelect from "./AnalyticsSegmentSelect";


function AnalyticsDimensionBar({ apiQuery, setApiQuery, hasPermission }) {
    return (
        <React.Fragment>
            <AnalyticsSegmentSelect segment={'segment1'} apiQuery={apiQuery} setApiQuery={setApiQuery} hasPermission={hasPermission} />
            <Typography variant="h2" component="h1" color="textPrimary" noWrap sx={{ marginRight: 2, opacity: apiQuery.dimension2 ? 1 : 0.2 }}>/</Typography>
            {apiQuery.segment1 && (
                <AnalyticsDimensionSelect dimension={'dimension1'} apiQuery={apiQuery} setApiQuery={setApiQuery} />
            )}
            {/* {apiQuery.segment1 && apiQuery.dimension1 && (
                <React.Fragment>
                    <Typography variant="h2" component="h1" color="textPrimary" noWrap marginRight={2}>/</Typography>
                    <AnalyticsDimensionSelect dimension={'dimension2'} apiQuery={apiQuery} setApiQuery={setApiQuery} />
                </React.Fragment>
            )} */}
        </React.Fragment>
    );
}

export default AnalyticsDimensionBar;
