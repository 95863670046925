import * as React from "react";

import "moment-timezone";
import moment from "moment";
import "moment/locale/de";

import { visuallyHidden } from "@mui/utils";

import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

import AnalyticsChart from "./AnalyticsChart";
import AnalyticsTableFilter from "./AnalyticsTableFilter";
import { AnalyticsTableFilterRows } from "./AnalyticsTableFilter";
import Tooltip from "../../../components/Tooltip";
import AnalyticsPrepareMetrics from "./AnalyticsPrepareMetrics";
import AnalyticsChartSeriesSelect from "./AnalyticsChartSeriesSelect";
import AnalyticsCsvButton from "./AnalyticsCsvButton";
import AnalyticsShareButton from "./AnalyticsShareButton";

import fallback from "../../../images/fallback.png";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCellsMetric = (name) => {
    let cells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: <Tooltip text={name} description={name} />,
        },
        {
            id: "session_hits",
            numeric: true,
            disablePadding: false,
            label: <Tooltip text={`Zugriffe`} description={`Gesamte Zugriffe`} />,
        },
        {
            id: "session_hits_avg",
            numeric: true,
            disablePadding: false,
            label: <Tooltip text={`Durchschnittliche Zugriffe`} description={`Durchschnittliche Zugriffe`} />,
        },
        {
            id: "session_seconds",
            numeric: true,
            disablePadding: false,
            label: (
                <Tooltip
                    text={`Wiedergabedauer`}
                    description={`Gesamte Wiedergabedauer in Stunden`}
                />
            ),
        },
        {
            id: "session_seconds_avg",
            numeric: true,
            disablePadding: false,
            label: (
                <Tooltip
                    text={`Durchschnittliche Wiedergabezeit`}
                    description={`Durchschnittliche Wiedergabezeit in Minuten pro Zugriff`}
                />
            ),
        },
        // {
        //     id: "session_parallel",
        //     numeric: true,
        //     disablePadding: false,
        //     label: (
        //         <Tooltip
        //             text={`Maximale gleichzeitige Zuschauer`}
        //             description={`Maximale gleichzeitige Zuschauer`}
        //         />
        //     ),
        // },
    ];
    return cells;
};

const headCells = (name) => {
    const metric = headCellsMetric();
    return [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: <Tooltip text={name} description={name} />,
        },
        ... metric,
    ]
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, apiQuery } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    let name = "";
    if (apiQuery.dimension1 === "camera_id") {
        name = "Name";
    } else if (apiQuery.dimension1 === "player_id") {
        name = "Name";
    } else if (apiQuery.dimension1 === "user_email") {
        name = "Name";
    } else if (apiQuery.dimension1 === "playback_user_id") {
        name = "Name";
    } else if (apiQuery.dimension1 === "device_type") {
        name = "Gerätekategorie";
    } else if (apiQuery.dimension1 === "os_family") {
        name = "Betriebssysteme";
    } else if (apiQuery.dimension1 === "os_version") {
        name = "Betriebssystemversion";
    } else if (apiQuery.dimension1 === "browser_family") {
        name = "Browser";
    } else if (apiQuery.dimension1 === "browser_version") {
        name = "Browser Version";
    } else if (apiQuery.dimension1 === "region_iso") {
        name = "Landesregion";
    } else if (apiQuery.dimension1 === "country_iso") {
        name = "Land";
    } else if (apiQuery.dimension1 === "content_type") {
        name = "Content Typ";
    }

    const cells = headCellsMetric(name);

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {cells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            //textWrap: "nowrap",
                            textWrap: "wrap",
                            fontSize: "0.75rem",
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    apiQuery: PropTypes.object.isRequired,
};

// TODO Die Funktion sollte ausgelagert werden
function SummaryRowMetric({ rows }) {
    if (rows && rows.length === 0) {
        return null;
    }

    let metric = {
        name: "Gesamt",
        color: "#0084E8",
        session_hits: 0,
        session_hits_avg: 0,
        session_seconds: 0,
        session_seconds_avg: 0,
        //session_parallel: 0,
        values: [],
    };

    let values = {};

    try {
        rows.forEach((row) => {
            row.values.forEach((value) => {
                if (!values[value.session_date]) {
                    values[value.session_date] = {
                        session_date: value.session_date,
                        session_hits: value.session_hits,
                        session_hits_avg: row.session_hits_avg,
                        session_seconds: value.session_seconds,
                        session_seconds_avg: value.session_seconds_avg,
                        //session_parallel: value.session_parallel,
                    };
                } else {
                    values[value.session_date].session_hits +=
                        value.session_hits;
                    values[value.session_date].session_hits_avg =
                        row.session_hits_avg;
                    values[value.session_date].session_seconds +=
                        value.session_seconds;
                    values[value.session_date].session_seconds_avg = parseInt(
                        (
                            values[value.session_date].session_seconds /
                            values[value.session_date].session_hits
                        ).toFixed(0),
                        10,
                    );
                    // values[value.session_date].session_parallel =
                    //     value.session_parallel >
                    //     values[value.session_date].session_parallel
                    //         ? value.session_parallel
                    //         : values[value.session_date].session_parallel;
                }
            });
            metric.session_hits += row.session_hits;
            metric.session_hits_avg += row.session_hits_avg;
            metric.session_seconds += row.session_seconds;
            // metric.session_parallel =
            //     row.session_parallel > metric.session_parallel
            //         ? row.session_parallel
            //         : metric.session_parallel;
        });

        metric.session_seconds_avg = parseInt(
            (metric.session_seconds / metric.session_hits).toFixed(0),
            10,
        );

        metric.values = Object.values(values);
    } catch (e) {
        console.log(e);
    }
    return metric;
}

function CalcMetricMinutes(metric) {
    let totalMinutes =
        parseFloat(metric.session_seconds / metric.session_hits) / 60;
    let minutes = Math.floor(totalMinutes);
    let seconds = Math.round((totalMinutes - minutes) * 60);
    let timeString = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    return timeString;
}

function SummaryRow({
    metric,
    components = {
        hits: true,
        hits_avg: true,
        seconds: true,
        seconds_avg: true,
        //parallel: true,
    },
    checkedSummary,
    handleCheckedSummary,
    chartSeriesSelect,
}) {
    if (!metric) {
        return null;
    }

    return (
        <TableRow
            tabIndex={-1}
            sx={{
                backgroundColor: "rgba(0, 0, 0,.055)!important",
            }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={checkedSummary}
                    onClick={(event) =>
                        handleCheckedSummary(event.target.checked)
                    }
                    inputProps={{
                        "aria-label": "select all desserts",
                    }}
                    color="primary"
                    style={{ color: checkedSummary ? "#0084E8" : "primary" }}
                />
            </TableCell>
            <TableCell
                component="th"
                scope="row"
                padding="normal"
                style={{ fontSize: "1rem" }}
            >
                Gesamt
            </TableCell>
            {components.hits && (
                <TableCell align="right" style={{ fontSize: "1rem" }}>
                    {metric.session_hits.toLocaleString("de-DE")}
                    {chartSeriesSelect === "session_hits" && checkedSummary && (
                        <CircleIcon
                            sx={{
                                color: metric.color,
                                fontSize: 16,
                                marginBottom: "-3px",
                                marginLeft: "6px",
                            }}
                        />
                    )}
                </TableCell>
            )}
            {components.hits_avg && (
                <TableCell align="right" style={{ fontSize: "1rem" }}>
                    {metric.session_hits_avg.toLocaleString("de-DE")}
                    {chartSeriesSelect === "session_hits_avg" && checkedSummary && (
                        <CircleIcon
                            sx={{
                                color: metric.color,
                                fontSize: 16,
                                marginBottom: "-3px",
                                marginLeft: "6px",
                            }}
                        />
                    )}
                </TableCell>
            )}
            {components.seconds && metric.session_seconds && (
                <TableCell align="right" style={{ fontSize: "1rem" }}>
                    {parseInt(metric.session_seconds / 60 / 60).toLocaleString(
                        "de-DE",
                    )}
                    {chartSeriesSelect === "session_seconds" &&
                        checkedSummary && (
                            <CircleIcon
                                sx={{
                                    color: metric.color,
                                    fontSize: 16,
                                    marginBottom: "-3px",
                                    marginLeft: "6px",
                                }}
                            />
                        )}
                </TableCell>
            )}
            {components.seconds_avg && (
                <TableCell align="right" style={{ fontSize: "1rem" }}>
                    {CalcMetricMinutes(metric)}
                    {chartSeriesSelect === "session_seconds_avg" &&
                        checkedSummary && (
                            <CircleIcon
                                sx={{
                                    color: metric.color,
                                    fontSize: 16,
                                    marginBottom: "-3px",
                                    marginLeft: "6px",
                                }}
                            />
                        )}
                </TableCell>
            )}
            {/* {components.parallel && (
                <TableCell align="right" style={{ fontSize: "1rem" }}>
                    {metric.session_parallel.toLocaleString("de-DE")}
                    {chartSeriesSelect === "session_parallel" &&
                        checkedSummary && (
                            <CircleIcon
                                sx={{
                                    color: metric.color,
                                    fontSize: 16,
                                    marginBottom: "-3px",
                                    marginLeft: "6px",
                                }}
                            />
                        )}
                </TableCell>
            )} */}
        </TableRow>
    );
}


function CameraImage({ id, playerConfig }) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, []);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
                padding: 0,
                height: 30,
                minWidth: 55,
                borderRadius: 1,
                border: "1px solid #f1f1f1",
            }}
        />
    );
}


export default function AnalyticsTableChart({
    apiQuery,
    metrics,
    assets,
    playerConfig,
    ready,
    readyAssets,
    backdrop,
    components = {
        hits: true,
        hits_avg: true,
        seconds: true,
        seconds_avg: true,
        // parallel: true,
    },
    onChangeRange,
    maxDaysForHour,
}) {
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("session_hits");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [checked, setChecked] = React.useState([]);
    const [checkedSummary, setCheckedSummary] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [summaryMetric, setSummaryMetric] = React.useState(null);
    const [chartSeriesSelect, setChartSeriesSelect] =
        React.useState("session_hits");

    React.useEffect(() => {
        if (ready) {
            const rows = AnalyticsPrepareMetrics(
                apiQuery.dimension1,
                metrics,
                assets,
            );
            setRows(rows ? rows : []);
            const summaryRowMetric = SummaryRowMetric({ rows: rows });
            setSummaryMetric(summaryRowMetric);
        }
    }, [metrics, ready]);

    React.useEffect(() => {
        backdrop(!ready || !readyAssets);
    }, [ready, readyAssets, backdrop]);

    const handleChangeRange = (from_date, to_date, step) => {
        onChangeRange(from_date, to_date, step);
    };

    // Filter für die Tabelle
    const [searchText, setSearchText] = React.useState("");
    const filteredRows = React.useMemo(
        () => AnalyticsTableFilterRows(rows, searchText),
        [rows, searchText],
    );

    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [filteredRows, order, orderBy, page, rowsPerPage],
    );

    // Aktiviert die ersten 10 Checkboxen und Metriken
    React.useEffect(() => {
        if (visibleRows.length > 0) {
            let newChecked = [];
            for (let i = 0; i < Math.min(5, visibleRows.length); i++) {
                newChecked.push(visibleRows[i].id);
            }
            setChecked(newChecked);
        }
    }, [visibleRows]);

    // Selektiert die angeklickte Zeile
    const isSelected = (id) => selectedRow === id;
    const handleSelected = (asset) => {
        selectedRow === asset.id
            ? setSelectedRow(null)
            : setSelectedRow(asset.id);
    };

    // Selektiert die angeklickte Checkbox
    const handleCheckClick = (event, id) => {
        const checkedIndex = checked.indexOf(id);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(checked, id);
        } else if (checkedIndex === 0) {
            newChecked = newChecked.concat(checked.slice(1));
        } else if (checkedIndex === checked.length - 1) {
            newChecked = newChecked.concat(checked.slice(0, -1));
        } else if (checkedIndex > 0) {
            newChecked = newChecked.concat(
                checked.slice(0, checkedIndex),
                checked.slice(checkedIndex + 1),
            );
        }
        if (newChecked.length > 10) {
            return null;
        }
        setChecked(newChecked);
    };
    const isChecked = (id) => checked.indexOf(id) !== -1;
    const handleCheckedSummary = (checked) => {
        if (checked) {
            setCheckedSummary(true);
        } else {
            setCheckedSummary(false);
        }
    };

    // Sortiert die Rows nach den Spalten
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Filtert die Metriken
    const filteredMetrics = () => {
        if (visibleRows.length === 0) {
            return [];
        }
        let metrics = [];
        if (checkedSummary) {
            metrics.push(summaryMetric);
        }
        visibleRows?.forEach((row) => {
            if (checked.includes(row.id)) {
                metrics.push(row);
            }
        });
        return metrics;
    };

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "flex-end", sm: "flex-end" }}
                alignItems="flex-start"
                spacing={2}
                marginTop={1}
            >
                <span width={"100%"}></span>
                <AnalyticsChartSeriesSelect
                    chartSeriesSelect={chartSeriesSelect}
                    setChartSeriesSelect={setChartSeriesSelect}
                />
                <AnalyticsShareButton apiQuery={apiQuery} />
            </Stack>
            {visibleRows && (
                <AnalyticsChart
                    key={chartSeriesSelect}
                    fromDate={apiQuery.from_date}
                    toDate={apiQuery.to_date}
                    step={apiQuery.step}
                    metrics={filteredMetrics()}
                    chartSeriesSelect={chartSeriesSelect}
                    onChangeRange={handleChangeRange}
                    maxDaysForHour={maxDaysForHour}
                />
            )}
            <Paper sx={{ width: "100%", mb: 2, marginTop: 2 }}>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent={{ xs: "flex-start", sm: "space-between" }}
                    alignItems="flex-start"
                    spacing={2}
                    marginTop={1}
                    marginBottom={1}
                >
                    {/* <span width={'100%'}></span> */}
                    <Stack
                        direction="row"
                        justifyContent="flex-left"
                        alignItems="center"
                        spacing={2}
                        marginTop={2}
                        width={"100%"}
                    >
                        <AnalyticsTableFilter
                            searchText={searchText}
                            setSearchText={setSearchText}
                            setPage={setPage}
                            apiQuery={apiQuery}
                        />
                        <AnalyticsCsvButton rows={rows} apiQuery={apiQuery} />
                    </Stack>
                </Stack>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            apiQuery={apiQuery}
                        />
                        <TableBody>
                            {summaryMetric && (
                                <SummaryRow
                                    components={components}
                                    metric={summaryMetric}
                                    checkedSummary={checkedSummary}
                                    handleCheckedSummary={handleCheckedSummary}
                                    chartSeriesSelect={chartSeriesSelect}
                                />
                            )}
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const isItemChecked = isChecked(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => handleSelected(row)}
                                        tabIndex={-1}
                                        key={row.id}
                                        aria-checked={isItemSelected}
                                        selected={isItemSelected}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemChecked}
                                                onClick={(event) =>
                                                    handleCheckClick(
                                                        event,
                                                        row.id,
                                                    )
                                                }
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                                style={{
                                                    color: isItemChecked
                                                        ? row.color
                                                        : "primary",
                                                }}
                                                role="checkbox"
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                            style={{
                                                fontSize: ".8rem",
                                                padding: 10,
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                {apiQuery.dimension1 ===
                                                    "camera_id" && (
                                                    <CameraImage
                                                        id={row.id}
                                                        playerConfig={playerConfig}
                                                    />
                                                )}
                                                <span>{row.name}</span>
                                            </Stack>
                                        </TableCell>
                                        {components.hits && (
                                            <TableCell
                                                align="right"
                                                style={{ fontSize: ".8rem" }}
                                            >
                                                {row.session_hits.toLocaleString(
                                                    "de-DE",
                                                )}
                                                <span
                                                    style={{
                                                        marginLeft: 10,
                                                        color: "rgba(0,0,0,0.6)",
                                                    }}
                                                >
                                                    (
                                                    {(
                                                        (row.session_hits /
                                                            summaryMetric.session_hits) *
                                                        100
                                                    ).toFixed(2)}
                                                    %)
                                                </span>
                                                {chartSeriesSelect ===
                                                    "session_hits" &&
                                                    isItemChecked && (
                                                        <CircleIcon
                                                            sx={{
                                                                color: row.color,
                                                                fontSize: 16,
                                                                marginBottom:
                                                                    "-3px",
                                                                marginLeft:
                                                                    "6px",
                                                            }}
                                                        />
                                                    )}
                                            </TableCell>
                                        )}
                                        {components.hits_avg && (
                                            <TableCell
                                                align="right"
                                                style={{ fontSize: ".8rem" }}
                                            >
                                                {row.session_hits_avg.toLocaleString(
                                                    "de-DE",
                                                )}
                                                <span
                                                    style={{
                                                        marginLeft: 10,
                                                        color: "rgba(0,0,0,0.6)",
                                                    }}
                                                >
                                                    (
                                                    {(
                                                        (row.session_hits_avg /
                                                            summaryMetric.session_hits_avg) *
                                                        100
                                                    ).toFixed(2)}
                                                    %)
                                                </span>
                                                {chartSeriesSelect ===
                                                    "session_hits" &&
                                                    isItemChecked && (
                                                        <CircleIcon
                                                            sx={{
                                                                color: row.color,
                                                                fontSize: 16,
                                                                marginBottom:
                                                                    "-3px",
                                                                marginLeft:
                                                                    "6px",
                                                            }}
                                                        />
                                                    )}
                                            </TableCell>
                                        )}
                                        {components.seconds && (
                                            <TableCell
                                                align="right"
                                                style={{ fontSize: ".8rem" }}
                                            >
                                                {parseInt(
                                                    row.session_seconds /
                                                        60 /
                                                        60,
                                                ).toLocaleString("de-DE")}
                                                <span
                                                    style={{
                                                        marginLeft: 10,
                                                        color: "rgba(0,0,0,0.6)",
                                                    }}
                                                >
                                                    (
                                                    {(
                                                        (row.session_seconds /
                                                            summaryMetric.session_seconds) *
                                                        100
                                                    ).toFixed(2)}
                                                    %)
                                                </span>
                                                {chartSeriesSelect ===
                                                    "session_seconds" &&
                                                    isItemChecked && (
                                                        <CircleIcon
                                                            sx={{
                                                                color: row.color,
                                                                fontSize: 16,
                                                                marginBottom:
                                                                    "-3px",
                                                                marginLeft:
                                                                    "6px",
                                                            }}
                                                        />
                                                    )}
                                            </TableCell>
                                        )}
                                        {components.seconds_avg && (
                                            <TableCell
                                                align="right"
                                                style={{ fontSize: ".8rem" }}
                                            >
                                                {CalcMetricMinutes(row)}
                                                {chartSeriesSelect ===
                                                    "session_seconds_avg" &&
                                                    isItemChecked && (
                                                        <CircleIcon
                                                            sx={{
                                                                color: row.color,
                                                                fontSize: 16,
                                                                marginBottom:
                                                                    "-3px",
                                                                marginLeft:
                                                                    "6px",
                                                            }}
                                                        />
                                                    )}
                                            </TableCell>
                                        )}
                                        {/* {components.parallel && (
                                            <TableCell
                                                align="right"
                                                style={{ fontSize: ".8rem" }}
                                            >
                                                {row.session_parallel}
                                                {chartSeriesSelect ===
                                                    "session_parallel" &&
                                                    isItemChecked && (
                                                        <CircleIcon
                                                            sx={{
                                                                color: row.color,
                                                                fontSize: 16,
                                                                marginBottom:
                                                                    "-3px",
                                                                marginLeft:
                                                                    "6px",
                                                            }}
                                                        />
                                                    )}
                                            </TableCell>
                                        )} */}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Ergebnisse pro Seite"
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
