import * as React from "react";

import "moment-timezone";
import moment from "moment";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

function AdminCompanyProfile({ customer }) {
    // TEXTFIELD NEEDS STR
    for (const k in customer) {
        if (customer[k] === null) {
            customer[k] = "";
        }
    }

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            width={"100%"}
        >
            <Paper>
                <Grid
                    container
                    justifyContent="space-between"
                    fontSize={14}
                    style={{
                        marginTop: 6,
                        //backgroundColor: 'rgba(0,0,0,.035)',
                        //padding: '5px 10px 5px 10px',
                    }}
                    spacing={1}
                >
                    <Grid item xs={4}>
                        <Paper
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                padding: 1,
                                p: "0px 0px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                marginBottom: 2,
                                marginRight: 1,
                            }}
                        >
                            <Avatar
                                variant="rounded"
                                alt="Remy Sharp"
                                src={customer.avatar_url}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 2,
                                }}
                            ></Avatar>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            value={customer.name}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Webseite"
                            value={customer.website}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="E-Mail"
                            value={customer.email}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="E-Mail (Rechnungen)"
                            value={customer.billing_email}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Erstellt"
                            value={moment
                                .utc(customer.created_at)
                                .local()
                                .format("DD.MM.YYYY HH:mm:ss")
                            }
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
}

AdminCompanyProfile.propTypes = {
    customer: PropTypes.object,
};

AdminCompanyProfile.defaultProps = {};

export default AdminCompanyProfile;
