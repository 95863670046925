import * as React from "react";

import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import UserEdit from "./UserEdit";

function UserDialog({ user, openUserEdit, handleCloseUserEdit }) {
    // SAVE
    const [save, setSave] = React.useState(false);
    const handleSave = () => {
        setSave(!save);
    };

    // RESET
    const [reset, setReset] = React.useState(false);
    const handleReset = () => {
        setReset(!reset);
    };

    // DISABLE SAVE/RESET
    const [formValuesChanged, setFormValuesChanged] = React.useState(false);

    return (
        <Dialog open={openUserEdit} onClose={handleCloseUserEdit}>
            <DialogTitle fontSize={22}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <span>PROFIL EINSTELLUNG</span>
                    <IconButton
                        aria-label="open-edit"
                        onClick={handleCloseUserEdit}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Hier finden Sie alle Informationen zu ihrem Zugangsprofil.
                </DialogContentText>
                <UserEdit
                    user={user}
                    save={save}
                    setSave={setSave}
                    reset={reset}
                    setReset={setReset}
                    formValuesChanged={formValuesChanged}
                    setFormValuesChanged={setFormValuesChanged}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width={"100%"}
                >
                    <Button
                        variant="standard"
                        color="primary"
                        onClick={handleCloseUserEdit}
                    >
                        Passwort zurücksetzen
                    </Button>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            disabled={!formValuesChanged}
                            variant="outlined"
                            onClick={handleReset}
                        >
                            Zurücksetzen
                        </Button>
                        <Button
                            disabled={!formValuesChanged}
                            variant="outlined"
                            onClick={handleSave}
                        >
                            Speichern
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

UserDialog.propTypes = {
    user: PropTypes.object.isRequired,
    openUserEdit: PropTypes.bool.isRequired,
    handleCloseUserEdit: PropTypes.func.isRequired,
};

UserDialog.defaultProps = {};

export default UserDialog;
