import * as React from "react";

//import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";

import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import Stack from "@mui/material/Stack";

export default function DownloadDialog(props) {
    const downloadList = props.downloadList;

    const handleClear = () => {
        props.setDownloadList([]);
        props.handleDownloadDialogClose(true);
    };

    function DownloadFiles() {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = `${moment().format(
            "DD-MM-YYYY_HH-mm-ss",
        )}_videos.zip`;
        downloadList.forEach(async function (download) {
            const filename = `${moment(download.created_at).format(
                "DD-MM-YYYY_HH-mm-ss",
            )}_${download.camera_meta.city}_${download.tag}.mp4`;
            try {
                const file = await JSZipUtils.getBinaryContent(
                    download.video_sources[0].video_source +
                        "?token=" +
                        props.playerConfig.token,
                );
                zip.file(filename, file, { binary: true });
                count++;
                if (count === downloadList.length) {
                    zip.generateAsync({ type: "blob" }).then(
                        function (content) {
                            saveAs(content, zipFilename);
                        },
                    );
                }
                handleClear();
            } catch (err) {
                console.log(err);
            }
        });
    }

    function DownloadList() {
        const listItems = downloadList.map((file, index) => (
            <React.Fragment>
                <Grid item xs={1} style={{ wordBreak: "break-all" }}>
                    {index + 1}
                </Grid>
                <Grid
                    item
                    xs={4}
                    key={file.id.toString()}
                    style={{ wordBreak: "break-all" }}
                >
                    <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                        {moment(file.created_at).tz(moment.tz.guess())}
                    </Moment>
                </Grid>
                <Grid item xs={4} style={{ wordBreak: "break-all" }}>
                    {file.camera_meta.city}
                </Grid>
                <Grid item xs={3} style={{ wordBreak: "break-all" }}>
                    {file.tag}
                </Grid>
            </React.Fragment>
        ));
        return (
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                fontSize={14}
                spacing={1}
                style={{ wordBreak: "break-all" }}
            >
                <Grid
                    item
                    xs={1}
                    style={{ fontWeight: 600, wordBreak: "break-all" }}
                >
                    Nr.
                </Grid>
                <Grid
                    item
                    xs={4}
                    style={{ fontWeight: 600, wordBreak: "break-all" }}
                >
                    Erstellt
                </Grid>
                <Grid
                    item
                    xs={4}
                    style={{ fontWeight: 600, wordBreak: "break-all" }}
                >
                    Stadt
                </Grid>
                <Grid
                    item
                    xs={3}
                    style={{ fontWeight: 600, wordBreak: "break-all" }}
                >
                    Tags
                </Grid>
                {listItems}
            </Grid>
        );
    }

    return (
        <Dialog
            open={props.downloadDialogOpen}
            onClose={props.handleDownloadDialogClose}
            minWidth={800}
            PaperProps={{ style: { padding: "10px 15px 10px 15px" } }}
        >
            <DialogTitle variant="h5" fontSize={22} minWidth={750}>
                Download Liste
            </DialogTitle>
            <DialogContent>
                <DownloadList />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    marginTop={4}
                >
                    <Button
                        size="small"
                        variant="outlined"
                        color="danger"
                        onClick={props.handleDownloadDialogClose}
                    >
                        Abbruch
                    </Button>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleClear}
                            disabled={downloadList.length === 0}
                            size="small"
                        >
                            Alle entfernen
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={DownloadFiles}
                            disabled={downloadList.length === 0}
                            size="small"
                        >
                            Download
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
