import * as React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

import 'moment-timezone';
import moment from 'moment';
import 'moment/locale/de';

function AnalyticsChart({ fromDate, toDate, step, metrics, chartSeriesSelect, onChangeRange, maxDaysForHour }) {
	const series = [];
	const xLabels = [];
	let from_date = moment(fromDate);
	let to_date = moment(toDate);

	const handleChangeRange = (from_date, to_date, step) => {
		onChangeRange(from_date, to_date, step);
	};

	const clamp = (step, ts) => {
		ts = ts.clone();

		let format = 'YYYY-MM-DD-HH';

		if (step === 'hour') {
			format = 'YYYY-MM-DD-HH';
		} else if (step === 'day') {
			format = 'YYYY-MM-DD';
		} else if (step === 'week') {
			format = 'YYYY-WW';
		} else if (step === 'month') {
			format = 'YYYY-MM';
		}

		return moment(ts.format(format), format);
	};

	let labels_from = clamp(step, from_date);
	let labels_to = clamp(step, to_date);

	while (labels_from <= labels_to) {
		xLabels.push(labels_from.valueOf());
		if (step === 'hour') {
			labels_from.add(1, 'hours');
		} else if (step === 'day') {
			labels_from.add(1, 'days');
		} else if (step === 'week') {
			labels_from.add(1, 'weeks');
		} else if (step === 'month') {
			labels_from.add(1, 'months');
		}
	}

	metrics.sort((a, b) => b[chartSeriesSelect] - a[chartSeriesSelect]);
	metrics.forEach((metric) => {
		const values = [];

		if (metric.values.length === 0) {
			return;
		}

		let labels = xLabels.slice();

		metric.values.forEach((value) => {
			let ts = clamp(step, moment(value.session_date)).valueOf();
			values.push([
				ts,
				chartSeriesSelect === 'session_seconds' || chartSeriesSelect === 'session_seconds_avg'
					? value[chartSeriesSelect] / 60 / 60
					: value[chartSeriesSelect],
			]);

			let i = labels.indexOf(ts);
			if (i !== -1) {
				labels.splice(i, 1);
			}
		});

		for (let i = 0; i < labels.length; i++) {
			values.push([labels[i], 0]);
		}

		// Sortieren Sie die Werte nach dem Zeitpunkt
		values.sort((a, b) => a[0] - b[0]);
		series.push({
			data: values,
			name: metric.name,
			color: metric.color,
		});
	});

	const valueFormatterByStep = (step) => (timestamp) => {
		const date = moment(timestamp);
		if (step === 'hour') {
			return date.format('DD.MM.YYYY HH [Uhr]');
		} else if (step === 'day') {
			return date.format('DD.MM.YYYY');
		} else if (step === 'week') {
			return date.format('[KW]w YYYY');
		} else if (step === 'month') {
			return date.format('MMM YYYY');
		} else {
			return date.format('DD.MM.YYYY HH');
		}
	};

	const valueFormatter = valueFormatterByStep(step);

	const setStep = (x_from_date, x_to_date) => {
		const diff = moment(x_to_date).diff(moment(x_from_date), 'days');
		if (diff < maxDaysForHour + 1 && step !== 'hour') {
			return 'hour';
		}
		return step;
	};

	const setTooltipValue = (value) => {
		let tooltipValue = value;
		if (chartSeriesSelect === 'session_seconds_avg') {
			let minutes = Math.floor(tooltipValue * 60);
			let seconds = Math.round((tooltipValue * 60 - minutes) * 60);
			let timeString = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
			return timeString;
		} else if (chartSeriesSelect === 'session_seconds') {
			tooltipValue = Math.floor(tooltipValue);
		}
		return tooltipValue.toLocaleString('de-DE');
	};

	return (
		<>
			<style>
				{`
                    .apexcharts-tooltip-text {
                        margin: 0;
                        padding: 0px 0;
                        line-height: 6px;
                    }
                    .apexcharts-tooltip {
                        transform: translate(0%, -100%) !important;
                    }
                `}
			</style>
			<Chart
				options={{
					chart: {
						id: 'analytics-chart',
						toolbar: {
							show: false,
						},
						animations: {
							enabled: false,
						},
						zoom: {
							enabled: true,
						},
						events: {
							beforeZoom: function (chartContext, { xaxis }) {
								const from_date = moment(xaxis.min).minutes(0).seconds(0);
								const to_date = moment(xaxis.max).minutes(0).seconds(0);
								const step = setStep(xaxis.min, xaxis.max);

								handleChangeRange(from_date, to_date, step);

								return {
									xaxis: {
										min: from_date.valueOf(),
										max: to_date.valueOf(),
									},
								};
							},
						},
						padding: {
							top: 150,
							right: 150,
							bottom: 150,
							left: 150,
						},
					},
					xaxis: {
						overwriteCategories: xLabels,
						min: from_date.valueOf(),
						max: to_date.valueOf(),
						type: 'datetime',
						labels: {
							formatter: valueFormatter,
						},
						tooltip: {
							enabled: false,
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								return value.toLocaleString('de-DE');
							},
						},
						tooltip: {
							enabled: false,
						},
					},
					stroke: {
						curve: 'straight',
						width: 2.5,
					},
					legend: {
						show: false,
					},
					tooltip: {
						hideEmptySeries: false,
						x: {
							format: 'HH:mm:ss',
						},
						y: {
							formatter: setTooltipValue,
						},
						custom: function ({ series, seriesIndex, dataPointIndex, w }) {
							let title = w.globals.tooltip.tooltipTitle.outerHTML;
							let items = '';
							const ttItems = Array.from(w.globals.tooltip.ttItems);

							// Erstellen Sie ein Array von Werten aus der Serie
							const values = series.map((s) => s[dataPointIndex]);

							// Sortieren Sie ttItems basierend auf den Werten
							ttItems.sort((a, b) => {
								const aValue = values[ttItems.indexOf(a)];
								const bValue = values[ttItems.indexOf(b)];
								return bValue - aValue; // Sortieren Sie in absteigender Reihenfolge
							});

							// Erstellen Sie die endgültige Tooltip-Auszeichnung
							ttItems.forEach((ttItem) => {
								items = items + ttItem.outerHTML;
							});

							// Erstellen Sie einen Container für den Tooltip
							const customTooltipContent = document.createElement('div');
							customTooltipContent.innerHTML = title + items;
							return customTooltipContent.outerHTML;
						},
					},
					noData: {
						text: '',
					},
				}}
				series={series}
				type="line"
				height={400}
			/>
		</>
	);
}

AnalyticsChart.propTypes = {
	fromDate: PropTypes.instanceOf(moment),
	toDate: PropTypes.instanceOf(moment),
	step: PropTypes.string.isRequired,
	metrics: PropTypes.array.isRequired,
	chartSeriesSelect: PropTypes.string.isRequired,
	onChangeRange: PropTypes.func.isRequired,
	maxDaysForHour: PropTypes.number.isRequired,
};

AnalyticsChart.defaultProps = {
	metrics: [],
	chartSeriesSelect: 'session_seconds',
	onChangeRange: () => {},
	maxDaysForHour: 7,
};

export default AnalyticsChart;
