import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import "video.js/dist/video-js.css";
import "./VideoJSSkin.css";
import "./VideoJSSkin2.css";

export default class VideoPlayer extends React.Component {
    componentDidMount() {
        this.player = videojs(this.videoNode, this.props, () => {});
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        // Verwenden Sie this.props.className, um die Klasse dynamisch zu setzen,
        // mit einem Standardwert von "video-js", falls keine className übergeben wurde
        const videoClassName = `video-js ${this.props.skinName || 'vjs-public'}`;

        return (
            <div data-vjs-player>
                <video
                    ref={(node) => (this.videoNode = node)}
                    className={videoClassName}
                ></video>
            </div>
        );
    }
}
