/* eslint-disable import/no-anonymous-default-export */
import base from "../base";

export default {
    styleOverrides: {
        root: {
            zIndex: "99999",
            color: base.palette.text.primary,
            //backgroundColor: 'rgba(255,255,255, 0.65)'
        },
    },
};
