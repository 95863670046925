import * as React from "react";

import Stack from "@mui/material/Stack";

import AnalyticsFilterSelect from "./AnalyticsFilterSelect";
import AnalyticsCameraSelect from "./AnalyticsCameraSelect";
import AnalyticsPlayerSelect from "./AnalyticsPlayerSelect";
import AnalyticsDeviceTypeSelect from "./AnalyticsDeviceTypeSelect";
import AnalyticsContentTypeSelect from "./AnalyticsContentTypeSelect";
import AnalyticsDateRangeSelect from "./AnalyticsDateRangeSelect";
import AnalyticsCountrySelect from "./AnalyticsCountrySelect";
import AnalyticsRegionSelect from "./AnalyticsRegionSelect";

function AnalyticsFilterBar({ apiQuery, setApiQuery, assets, maxDaysForHour, storageDateRange, onChangeRange }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                paddingTop={0.8}
                paddingBottom={2.8}
            >
                <AnalyticsFilterSelect
                    apiQuery={apiQuery}
                    setApiQuery={setApiQuery}
                    assets={assets}
                />
                {apiQuery.camera_id && (
                    <AnalyticsCameraSelect
                        cameras={assets.cameras}
                        apiQuery={apiQuery}
                        setApiQuery={setApiQuery}
                    />
                )}
                {apiQuery.player_id && (
                    <AnalyticsPlayerSelect
                        players={assets.players}
                        apiQuery={apiQuery}
                        setApiQuery={setApiQuery}
                    />
                )}
                {apiQuery.device_type && (
                    <AnalyticsDeviceTypeSelect
                        apiQuery={apiQuery}
                        setApiQuery={setApiQuery}
                    />
                )}
                {apiQuery.content_type && (
                    <AnalyticsContentTypeSelect
                        apiQuery={apiQuery}
                        setApiQuery={setApiQuery}
                    />
                )}
                {apiQuery.country_iso && (
                    <AnalyticsCountrySelect
                        apiQuery={apiQuery}
                        setApiQuery={setApiQuery}
                    />
                )}
                {apiQuery.country_iso &&
                    apiQuery.country_iso !== "" &&
                    apiQuery.region_iso &&
                    apiQuery.region_iso !== "" && (
                        <AnalyticsRegionSelect
                            apiQuery={apiQuery}
                            setApiQuery={setApiQuery}
                        />
                    )}
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                paddingTop={0.8}
                paddingBottom={2.8}
            >
                <AnalyticsDateRangeSelect
                    apiQuery={apiQuery}
                    setApiQuery={setApiQuery}
                    maxDaysForHour={maxDaysForHour}
                    storageDateRange={storageDateRange}
                    onChangeRange={onChangeRange}
                />
            </Stack>
        </Stack>
    );
}

export default AnalyticsFilterBar;
