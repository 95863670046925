import * as React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

import VideoTagSelect from "./VideoTagSelect";

function VideoTag({ tags }) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            fontSize={14}
            spacing={1}
        >
            <Grid item xs={12}>
                <VideoTagSelect
                    tags={tags ? tags : []}
                    disabled={!tags}
                    readOnly
                />
            </Grid>
        </Grid>
    );
}

VideoTag.propTypes = {
    tags: PropTypes.object,
};

VideoTag.defaultProps = {};

export default VideoTag;
