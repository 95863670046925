import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

function PlayerTokenGenerator({ playerConfig }) {
    if (playerConfig) {
        return null;
    }

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            width={"100%"}
        >
            <Paper>
                <Grid
                    container
                    justifyContent="space-between"
                    fontSize={14}
                    style={{
                        marginTop: 6,
                        //backgroundColor: 'rgba(0,0,0,.035)',
                        //padding: '5px 10px 5px 10px',
                    }}
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <TextField
                            label="Laufzeit"
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Domains"
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
}

PlayerTokenGenerator.propTypes = {
    playerConfig: PropTypes.object,
};

PlayerTokenGenerator.defaultProps = {};

export default PlayerTokenGenerator;
