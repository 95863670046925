import * as React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import PublicationProviderRegistry from './PublicationProviderRegistry';

function RestreamCameraProfile(profile, video_resolution) {
	if (video_resolution.includes(profile.resolution)) {
		return (
			<MenuItem key={profile.profile} value={profile.profile}>
				{profile.resolution}
			</MenuItem>
		);
	}
	return null;
}

function RestreamCameraProfileSelect({ formValues, handleChange, cameras }) {
	React.useEffect(() => {
		handleChange({
			target: {
				name: 'camera_input_id',
				value: '0',
			},
		});
		// eslint-disable-next-line
	}, [formValues.camera_id]);

	const publicationProviderRegistry = PublicationProviderRegistry();
	const PublicationProviderSelected = publicationProviderRegistry.find((service) => service.id === formValues.publication_provider);
	if (!PublicationProviderSelected) {
		return null;
	}
	const video_resolution = PublicationProviderSelected.stream_requirements.video_resolution;
	const cameraStreams = cameras.find((camera) => camera.id === formValues.camera_id)?.streams;

	return (
		<Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2} width={'100%'}>
			<TextField
				id="restream-publication-provider-select"
				select
				name="camera_input_id"
				value={formValues.camera_input_id}
				label="Kamera Profil"
				onChange={handleChange}
				disabled={!formValues.camera_id}
				size="small"
				fullWidth
				variant="outlined"
				color="secondary"
				InputLabelProps={{
					sx: {
						backgroundColor: '#fff',
					},
				}}
			>
				{cameraStreams && cameraStreams.length > 0 && cameraStreams.map((profile) => RestreamCameraProfile(profile, video_resolution))}
				{!cameraStreams || (cameraStreams.length === 0 && <MenuItem>Nicht verfügbar</MenuItem>)}
			</TextField>
		</Stack>
	);
}

RestreamCameraProfileSelect.propTypes = {
	formValues: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	cameras: PropTypes.array.isRequired,
};

RestreamCameraProfileSelect.defaultProps = {};

export default RestreamCameraProfileSelect;
