import React from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

export default function App() {
    const [value, setValue] = React.useState(`Neue Mitteilung...`);
    return (
        <div className="container">
            <MDEditor
                value={value}
                onChange={setValue}
                previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                }}
            />
        </div>
    );
}
