import { CSVLink } from "react-csv";
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function AnalyticsCsvButton({rows, apiQuery}) {
    if (!rows || rows.length === 0 || !apiQuery) {
        return null;
    }
    const from_date = new Date(apiQuery.from_date).toLocaleDateString();
    const to_date = new Date(apiQuery.to_date).toLocaleDateString();
    const csvData = [
        ["Name", "Zugriffe", "Wiedergabedauer (Stunden)", "Durchschnittliche Wiedergabezeit (Minuten)"] // "session_parallel"
    ];
    rows.forEach((line) => {
        csvData.push([
            line.name, 
            line.session_hits, 
            parseInt(line.session_seconds / 60 / 60).toLocaleString("de-DE"),
            (line.session_seconds_avg / 60).toFixed(2)
        ]); //line.session_parallel]);
    });
    return (
        <CSVLink data={csvData} filename={`cm-analytics-${from_date}-${to_date}.csv`} disabled={!apiQuery}>
            <IconButton aria-label="csv-download" sx={{color: "#0085e9"}} disabled={!apiQuery}>
                <DownloadForOfflineIcon />
            </IconButton>
        </CSVLink>
    );
};


export default AnalyticsCsvButton;