import * as React from "react";

import GppGoodIcon from "@mui/icons-material/GppGood";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Logo from "../images/livespotting.svg";

import TwoFactorToken from "../components/TwoFactorToken";

import { useApp } from "../context/App";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

function LoginTwoFactor() {
    const { client } = useApp();

    const [otp_token, setOtpToken] = React.useState(Array(6).fill(""));

    const [failCount, setFailCount] = React.useState(0);
    const handleLoginFailed = async () => {
        if (failCount >= 3) {
            await client.logout();
        } else {
            setFailCount(failCount + 1);
        }
    };

    const sendTwoFactor = async () => {
        if (otp_token.join("") === "") {
            return;
        } else {
            try {
                await client.loginTwoFactorAuth({
                    otp_token: otp_token.join(""),
                });
            } catch (error) {
                await handleLoginFailed();
                setOtpToken(Array(6).fill(""));
            }
        }
    };

    React.useEffect(() => {
        if (!otp_token.includes("")) {
            sendTwoFactor();
        }
        // eslint-disable-next-line
    }, [otp_token]);

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            height={"100vh"}
            width={"100%"}
            margin={"-8px -8px -8px 0px"}
        >
            <Stack></Stack>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-left"
                spacing={1}
            >
                <ServiceIcon
                    style={{
                        width: 40,
                        height: 40,
                        position: "fixed",
                        left: "1.1em",
                        top: "1.3em",
                    }}
                />
                <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    alignItems="left"
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="left"
                        marginTop={-5}
                        marginBottom={1}
                    >
                        <GppGoodIcon
                            size="large"
                            style={{
                                width: "1.5em",
                                height: "1.5em",
                                marginRight: "0px",
                                marginTop: "-1px",
                                color: "rgb(40, 172, 46)",
                            }}
                        />
                        <Typography
                            fontSize={18}
                            fontWeight={400}
                            marginLeft={0.3}
                        >
                            Zwei-Faktor-Authentifikation
                        </Typography>
                    </Stack>
                    <TwoFactorToken
                        otp_token={otp_token}
                        setOtpToken={setOtpToken}
                    />
                </Stack>
            </Stack>
            <Stack></Stack>
        </Stack>
    );
}

LoginTwoFactor.propTypes = {};

LoginTwoFactor.defaultProps = {};

export default LoginTwoFactor;
