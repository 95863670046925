import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


function ProtocolDetail({ 
    dialogOpen = true,
    setDialogOpen = () => {}, 
    mousePosition = { x: 0, y: 0 },
    selectedRow = {}, 
}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setAnchorEl(mousePosition ? document.elementFromPoint(mousePosition.x, mousePosition.y) : null);
        }
    // eslint-disable-next-line
    }, []);

    const handleCloseMenu = () => {
        setDialogOpen(false);
    };

    // {
    //     "api_method": "",
    //     "api_path": "",
    //     "api_query": "null",
    //     "api_payload": "",
    //     "editor_name": "",
    //     "editor_email": "",
    //     "editor_ip": "",
    //     "created_at": ""
    // }

    return (
        <Menu
            open={Boolean(dialogOpen && selectedRow)}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
        >
            <MenuList dense sx={{
                paddingTop: 0
            }}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                    paddingBottom={1}
                    //width={200}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={2}
                        paddingRight={1}
                        paddingTop={1}
                        paddingBottom={1}
                        fontWeight={600}
                        fontSize={16}
                        width={"100%"}
                        backgroundColor={"rgba(0,0,0,.03)"}
                    >
                        <span>{selectedRow.row.editor_name.label}</span>
                    </Stack>
                    <Divider style={{ borderWidth: 1, marginTop: 0 }} />
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={0.5}
                        fontWeight={600}
                        fontSize={14}
                        marginBottom={-1.5}
                    >
                        E-Mail Adresse
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={2}
                        paddingRight={2}
                        fontSize={14}
                        marginBottom={-1}
                    >
                        {selectedRow.row.editor_email.label}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={0.5}
                        fontWeight={600}
                        fontSize={14}
                        marginBottom={-1.5}
                    >
                        IP Adresse (v4/v6)
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={2}
                        paddingRight={2}
                        fontSize={14}
                    >
                        {selectedRow.row.editor_ip.label}
                    </Stack>
                </Stack>
            </MenuList>
        </Menu>
    );
}

ProtocolDetail.propTypes = {
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
    mousePosition: PropTypes.object,
    selectedRow: PropTypes.object,
};

export default ProtocolDetail;