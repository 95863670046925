import * as React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

import CameraTagSelect from "./CameraTagSelect";

function CameraTag({ meta }) {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            fontSize={14}
            spacing={1}
        >
            <Grid item xs={12}>
                <TextField
                    label="Video Kategorie"
                    value={meta.video_category}
                    disabled={!meta.video_category}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <CameraTagSelect
                    tags={meta.tags ? meta.tags : []}
                    disabled={!meta.tags}
                    readOnly
                />
            </Grid>
        </Grid>
    );
}

CameraTag.propTypes = {
    meta: PropTypes.object.isRequired,
};

CameraTag.defaultProps = {};

export default CameraTag;
