import * as React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import "moment-timezone";
import moment from "moment";
import "moment/locale/de";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// PAGE
import Page from "../../components/Page";
import DownloadDialog from "../../components/DownloadDialog";

// PAGE SIDEBAR
import VideoSidebar from "./VideoSidebar";
import VideoFilter from "./VideoFilter";

import { useApp } from "../../context/App";

import fallback from "../../images/fallback.png";


const ResolutionIcons = ({ video_sources }) => {
    if (video_sources.length === 0) {
        return null;
    }
    // [
    //     {
    //         "format": "hls",
    //         "codec": "h264, aac",
    //         "resolution": "1280x720",
    //         "bitrate": 530.22,
    //         "fps": 24.87
    //     }
    // ]
    const resolutions = video_sources.map((video_source) => video_source.video_height);
    resolutions.sort((a, b) => a - b);

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0.5}
        >
            {resolutions.map((resolution, index) => {
                return (
                    <Chip
                        key={index}
                        size="small"
                        label={resolution}
                        sx={{
                            color: "#fff",
                            backgroundColor: "#0085E9",
                            p: ".5em .1em",
                        }}
                    />
                );
            })}
        </Stack>
    );
};

const DownloadIcon = ({ video, downloadList }) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            {downloadList.find(
                (vendor) => vendor["id"] === video.id,
            ) && (
                <Chip
                    size="small"
                    sx={{
                        color: "#fff",
                        backgroundColor: "#0085E9",
                        p: ".5em .1em",
                        "& .MuiChip-icon": {
                            color: "#fff",
                            marginRight: "-12px",
                        },
                    }}
                    icon={<PlaylistRemoveIcon />}
                />
            )}
        </Stack>
    );
};


const DurationIcon = ({ video_duration_seconds }) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end" 
            alignItems="flex-end" 
            spacing={2}
        >
            <Chip
                size="small"
                sx={{
                    color: "#fff",
                    backgroundColor: "#0085E9",
                    p: ".5em .1em",
                }}
                label={
                    video_duration_seconds +
                    " Sek"
                }
            />
        </Stack>
    );
};

function VideoItem({ video, playerConfig, handleSelectVideo, downloadList }) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${video.video_sources[0].video_poster.replace(
            ".jpg",
            "_360.jpg",
        )}?token=${playerConfig.token}`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, []);

    return (
        <Grid
            item
            key={video.id}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            xxl={2}
            alignItems="flex-start"
            style={{ cursor: "pointer" }}
            onClick={handleSelectVideo(video)}
        >
            <Paper
                elevation={2}
                sx={{
                    position: 'relative',
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    backgroundColor: "transparent",
                    padding: 0,
                    paddingTop: "56.25%",
                    height: 0,
                    width: "auto",
                    borderRadius: 1,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={2}
                    width={"100%"}
                    height={"100%"}
                    sx={{ position: 'absolute', top: 0, left: 0 }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        paddingTop={.8}
                        paddingLeft={1.2}
                        paddingRight={1.2}
                    >
                        <ResolutionIcons video_sources={video.video_sources} />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        paddingLeft={1.2}
                        paddingRight={1.2}
                        paddingBottom={.8}
                    >
                        <DownloadIcon video={video} downloadList={downloadList} />
                        <DurationIcon video_duration_seconds={video.video_duration_seconds} />
                    </Stack>
                </Stack>
            </Paper>
            <Stack marginTop={.8} marginBottom={1.4} spacing={0}>
                <Typography 
                    variant="h5" 
                    //marginBottom={0}
                    sx={{
                        textTransform: "uppercase",
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.7)",
                        lineHeight: "1.1rem",
                        marginBottom: "0.3em",
                        marginTop: "0.3em",
                    }}
                >
                    {video.camera_meta.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {video.camera_meta.city}
                </Typography>
                {/* {video.tags && (
                    <Typography variant="body2" color="text.secondary">
                        Tags:
                        {video.tags.map((tag) => (
                            <Chip key={tag} label={tag} />
                        ))}
                    </Typography>
                )} */}
                <Typography variant="body2" color="text.secondary">
                    Erstellt:{" "}
                    {moment
                        .utc(video.created_at)
                        .local()
                        .format("DD.MM.YYYY HH:mm:ss")}
                </Typography>
            </Stack>
        </Grid>
    );
}



function VideoList({
    videos,
    page,
    pageSize,
    handleSelectVideo,
    playerConfig,
    downloadList,
}) {
    const paginateArray = (array, pageNumber, pageSize) => {
        const page = array.slice(
            (pageNumber - 1) * pageSize,
            pageNumber * pageSize,
        );
        return page;
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            marginTop={"-7px"}
        >
            {paginateArray(videos, page, pageSize).map((video) => {
                return (
                    <VideoItem key={video.id} video={video} playerConfig={playerConfig} handleSelectVideo={handleSelectVideo} downloadList={downloadList}/>
                );
            })}
        </Grid>
    );
}

VideoList.propTypes = {
    videos: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    playerConfig: PropTypes.object.isRequired,
    downloadList: PropTypes.array.isRequired,
    handleSelectVideo: PropTypes.func.isRequired,
};

VideoList.defaultProps = {};

function Video(props) {
    const navigate = useNavigate();
    const { client, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        navigate(`/video`);
    };

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = await client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

    // VIDEO OBJECT
    const { video_id } = useParams();
    const [videos, setVideos] = React.useState(false);
    const [video, setVideo] = React.useState(false);
    const handleSelectVideo = React.useCallback(
        (video) => () => {
            setVideo(video);
            navigate(`/video/${video.id}`);
            setOpenSidebar(true);
        },
        // eslint-disable-next-line
        [],
    );

    // camera search
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param_camera_id = params.get('camera_id');

    // PAGINATION
    const [limit, setLimit] = React.useState(500);
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [pageSize, setPageSize] = React.useState(50);
    const handlePageSize = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    // META SEARCH, SORT AND DATE
    const [meta_search, setMetaSearch] = React.useState(null);
    const [sort, setSort] = React.useState("created_at");
    // eslint-disable-next-line
    const handleSort = (event) => {
        setSort(event.target.value);
    };
    const [sortDirection, setSortDirection] = React.useState("desc");
    // eslint-disable-next-line
    const handleSortDirection = (event) => {
        setSortDirection(event.target.value);
    };
    const refreshFromDate = () => {
        return moment().subtract(8, "hours");
    };
    const [fromDate, setFromDate] = React.useState(refreshFromDate);
    const refreshToDate = () => {
        return moment();
    };
    const [toDate, setToDate] = React.useState(refreshToDate);
    
    function getDate(date) {
        const momentDate = moment.utc(date);
        return momentDate.format("YYYY-MM-DDTHH:mm:ss");
    }

    const onInputChange = (event, value, reason) => {
        if (event && event.type === "blur") {
            setMetaSearch("");
        } else if (reason !== "reset") {
            setMetaSearch(value);
        }
    };
    const [options, setOptions] = React.useState(null);
    const autoCompleteOptions = async () => {
        const newOptions = [];

        const addKey = (name) => {
            const value = String(name);
            if (!newOptions.includes(value)) {
                newOptions.push(value);
            }
        };

        if (meta_search && meta_search.length > 0) {
            const metaSearchLower = meta_search.toLowerCase(); // meta_search in Kleinbuchstaben umwandeln

            for (const video of videos) {
                if (video.tags) {
                    for (const tag of video.tags) {
                        if (tag.toLowerCase().includes(metaSearchLower)) {
                            addKey(tag);
                        }
                    }
                }
                if (video.camera_meta) {
                    const { name, city, region, country, tags } =
                        video.camera_meta;

                    if (name && name.toLowerCase().includes(metaSearchLower)) {
                        addKey(name);
                    }
                    if (city && city.toLowerCase().includes(metaSearchLower)) {
                        addKey(city);
                    }
                    if (
                        region &&
                        region.toLowerCase().includes(metaSearchLower)
                    ) {
                        addKey(region);
                    }
                    if (
                        country &&
                        country.toLowerCase().includes(metaSearchLower)
                    ) {
                        addKey(country);
                    }
                    if (tags) {
                        for (const tag of tags) {
                            if (tag.toLowerCase().includes(metaSearchLower)) {
                                addKey(tag);
                            }
                        }
                    }
                }
            }
        }

        const uniqueOptions = newOptions.filter(
            (value, index, array) => array.indexOf(value) === index,
        );
        const formattedOptions = uniqueOptions.map((option) => ({
            title: option,
        }));

        setOptions(formattedOptions);
    };

    // DOWNLOAD LIST
    const [downloadList, setDownloadList] = React.useState([]);
    const handleItemOnDownloadList = (video) => {
        if (downloadList.find((file) => file["id"] === video.id)) {
            setDownloadList(
                downloadList.filter((file) => file["id"] !== video.id),
            );
        } else {
            setDownloadList(downloadList.concat([video]));
        }
    };
    const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDownloadDialogOpen(true);
    };
    const handleDownloadDialogClose = () => {
        setDownloadDialogOpen(false);
    };

    const filterVideos = (videos) => {
        return(
            videos.sort((a, b) => {
                if (sort === "created_at") {
                    const dateA = new Date(a[sort]);
                    const dateB = new Date(b[sort]);
                    return sortDirection === "asc"
                        ? dateA - dateB
                        : dateB - dateA;
                } else if (sort.startsWith("camera_meta.")) {
                    const subKey = sort.replace("camera_meta.", "");
                    return sortDirection === "asc"
                        ? a.camera_meta[subKey].localeCompare(
                              b.camera_meta[subKey],
                          )
                        : b.camera_meta[subKey].localeCompare(
                              a.camera_meta[subKey],
                          );
                } else {
                    return 0;
                }
            })
        );
    };

    const filteredVideos = React.useMemo(() => {
        if (!Array.isArray(videos)) {
            return [];
        }
        setPage(1);
        return filterVideos(videos);
        // eslint-disable-next-line
    }, [videos, sort, sortDirection]);

    const getVideos = async ({refreshDate = true} = {}) => {
        const from_date = refreshDate ? getDate(refreshFromDate()) : getDate(fromDate);
        const to_date = refreshDate ? getDate(refreshToDate()) : getDate(toDate);
        const params = {
            camera_id: param_camera_id,
            meta_search: meta_search,
            from_date: !param_camera_id ? from_date : null,
            to_date: !param_camera_id ? to_date : null,
            limit: limit,
        };
        const data = await client.GetCustomerVideo(params);
        setVideos(data);
        await autoCompleteOptions();
        if (video_id && !video) {
            for (const v in data) {
                if (data[v].id === video_id) {
                    setVideo(data[v]);
                    setOpenSidebar(true);
                }
            }
        }
        setPage(1);
    };

    React.useEffect(() => {
        (async () => {
            setSelectedPage("video");
            await getPlayerConfig();
            await getVideos();
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (videos) {
            getVideos();
        }
        // eslint-disable-next-line
    }, [limit, meta_search]);

    React.useEffect(() => {
        if (videos) {
            getVideos({refreshDate: false});
        }
        // eslint-disable-next-line
    }, [fromDate, toDate]);

    return (
        <Page
            drawerWidth={550}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            headerContentLeft={
                <React.Fragment>
                    <Autocomplete
                        size="small"
                        options={options ? options : []}
                        onInputChange={onInputChange}
                        clearOnBlur={false}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) =>
                            option.title === value.title
                        }
                        onChange={(e, v) => {
                            setMetaSearch(v ? v.title : null);
                        }}
                        sx={{
                            width: "100%",
                            maxWidth: 450,
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="video_search"
                                color="primary"
                                label=""
                                variant="outlined"
                                placeholder="Suche..."
                                sx={{ 
                                    m: '.5em 1em 0.5em 0em', 
                                    boxShadow: 1, 
                                    borderRadius: 1, 
                                    background: "#fff", 
                                    border: "1px solid rgba(0,0,0,.2)"
                                }}
                                InputProps={{ 
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        noOptionsText={"Kein Ergebnis gefunden"}
                        fullWidth
                    />
                    <VideoFilter
                        sort={sort}
                        handleSort={handleSort}
                        sortDirection={sortDirection}
                        handleSortDirection={handleSortDirection}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        limit={limit}
                        setLimit={setLimit}
                    />
                    {downloadList.length > 0 && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClickDialogOpen}
                            startIcon={<FileDownloadIcon />}
                            sx={{ 
                                m: '.5em 1em 0.5em 0em',
                                padding: 0,
                                height: 40,
                                //minWidth: 40,
                                boxShadow: 1, 
                                borderRadius: 1, 
                                background: "#fff", 
                                border: "1px solid rgba(0,0,0,.2)"
                            }}
                        >
                            {downloadList.length}
                        </Button>
                    )}
                </React.Fragment>
            }
            sidebarContent={
                video ? (
                    <VideoSidebar
                        key={video.id}
                        drawerWidth={550}
                        video={video}
                        playerConfig={playerConfig}
                        handleItemOnDownloadList={handleItemOnDownloadList}
                        downloadList={downloadList}
                    />
                ) : null
            }
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                marginTop={1.5}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    width={"100%"}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0}
                        paddingBottom={5}
                    >
                        {videos && (
                            <VideoList
                                videos={filteredVideos}
                                page={page}
                                pageSize={pageSize}
                                handleSelectVideo={handleSelectVideo}
                                playerConfig={playerConfig}
                                downloadList={downloadList}
                            />
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        marginBottom={2}
                    >
                        <Paper
                            elevation={2}
                            sx={{
                                borderRadius: 2,
                                width: "auto",
                                p: "4px 8px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Pagination
                                count={
                                    filteredVideos
                                        ? Math.ceil(filteredVideos.length / pageSize)
                                        : 0
                                }
                                page={page}
                                onChange={handleChange}
                                variant="outlined"
                                shape="rounded"
                            />
                            <FormControl
                                size="small"
                            >
                                <Select
                                    value={pageSize}
                                    onChange={handlePageSize}
                                >
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={150}>150</MenuItem>
                                    <MenuItem value={250}>250</MenuItem>
                                </Select>
                            </FormControl>
                        </Paper>
                    </Stack>
                </Stack>
            </Stack>
            <DownloadDialog
                backend_url={""}
                downloadDialogOpen={downloadDialogOpen}
                handleDownloadDialogClose={handleDownloadDialogClose}
                downloadList={downloadList}
                setDownloadList={setDownloadList}
                playerConfig={playerConfig}
            />
        </Page>
    );
}

Video.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Video.defaultProps = {};

export default Video;
