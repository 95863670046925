import * as React from 'react';

import Check from '@mui/icons-material/Check'; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Desktop',
  'Mobile',
  'Tablet',
  'TV',
  'Unknown',
];

function translateNames(name) {
  switch (name) {
    case "Desktop":
      return "Desktop";
    case "Mobil":
      return "Mobil";
    case "Tablet":
      return "Tablet";
    case "TV":
      return "TV";
    case "Unknown":
      return "Unbekannt";
    default:
      return name;
  }
}

export default function AnalyticsDeviceTypeSelect({ apiQuery, setApiQuery }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setApiQuery(
      prevApiQuery => (
        { ...prevApiQuery, 
          device_type: typeof value === 'string' ? value.split(',') : value.length === 0 ? null : value 
        }
      )
    );
  };

  return (
    <div>
      <FormControl size="small" sx={{ 
        width: (apiQuery.device_type && apiQuery.device_type.length !== 0) ? "200px" : "100%",
      }}>
        {(!apiQuery.device_type ? <InputLabel id="analytics-device-type-multiple-checkbox-label">Geräte Typ</InputLabel> : null)}
        <Select
          labelId="analytics-device-type-multiple-checkbox-label"
          id="analytics-device-type-multiple-checkbox"
          multiple
          value={apiQuery.device_type ? apiQuery.device_type : []}
          onChange={handleChange}
          size="small"
          input={<OutlinedInput label={(!apiQuery.device_type ? "Geräte Typ" : null)} />}
          color={(!apiQuery.device_type ? "primary" : "secondary")}
          renderValue={(selected) => selected.map(translateNames).join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name} sx={{fontSize: 16, padding: "6px 10px 6px 10px"}}>
              <ListItemIcon>
                  {(apiQuery.device_type ? apiQuery.device_type.indexOf(name) > -1 : false) && <Check style={{color: "#fff"}} />}
              </ListItemIcon>
              {translateNames(name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}