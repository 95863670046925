import * as React from 'react';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import Select from '@mui/material/Select';


function AnalyticsDimensionSelect({ dimension, apiQuery, setApiQuery}) {
  const handleChange = (event) => {
    setApiQuery(prevApiQuery => ({ ...prevApiQuery, [dimension]: event.target.value ? event.target.value : null }));
  };
  const dimensionsSelected = [null, apiQuery.dimension1];
  if (apiQuery.dimension2) {
    dimensionsSelected.push(apiQuery.dimension2);
  }
  if (apiQuery.dimension3) {
    dimensionsSelected.push(apiQuery.dimension3);
  }

  const dimensionsSetA = [
    {
      name: "Name",
      value: "camera_id",
      requires: "camera",
    },
    {
      name: "Name",
      value: "player_id",
      requires: "player",
    },
    {
      name: "Name",
      value: "playback_user_id",
      requires: "playback_user",
    },
    {
      name: "Name",
      value: "user_email",
      requires: "user",
    },    
  ]

  const dimensionsSetB = [
    {
      name: "Contenttyp",
      value: "content_type",
      requires: null,
    },
    // {
    //   name: "Contentprofile",
    //   value: "content_profile",
    //   requires: "camera"
    // },
    {
      name: "Gerätekategorie",
      value: "device_type",
      requires: null
    },
    {
      name: "Betriebssystem",
      value: "os_family",
      requires: null
    },
    {
      name: "Betriebssystem, Version",
      value: "os_version",
      requires: null
    },
    {
      name: "Browser",
      value: "browser_family",
      requires: null
    },
    {
      name: "Browser, Version",
      value: "browser_version",
      requires: null
    },
    {
      name: "Land",
      value: "country_iso",
      requires: null,
    },
    {
      name: "Land, Region",
      value: "region_iso",
      requires: null
    },
  ]

  const value = () => {
    return apiQuery[dimension] ? apiQuery[dimension] : "";
  }

  return (
    <FormControl 
      sx={{ 
        m: '.5em 1em 0.5em 0em', 
        minWidth: 120, 
        boxShadow: 1, 
        borderRadius: 1, 
        background: "#fff", 
        opacity: apiQuery[dimension] ? 1 : 0.5
      }}
      disabled={dimension !== "dimension1"}
      size="small"
    >
      <Select
        labelId="analytics-dimension-select-label"
        id="analytics-dimension-select"
        value={value()}
        onChange={handleChange}
        color="secondary"
        displayEmpty
      >
        {dimension !== "dimension1" && (
          <MenuItem value={""}>
            {apiQuery[dimension] ? "- Dimension" : "+ Dimension"}
          </MenuItem>
        )}
        {dimensionsSetA.map((dim) => (
          dim.requires === apiQuery.segment1 && (
            <MenuItem key={dim.name} value={dim.value}>
              {dim.name}
            </MenuItem>
          )
        ))}
        {dimensionsSetB.map((dim) => (
          <MenuItem key={dim.name} value={dim.value}>
            {dim.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

AnalyticsDimensionSelect.propTypes = {
  dimension: PropTypes.string.isRequired,
  apiQuery: PropTypes.object.isRequired,
  setApiQuery: PropTypes.func.isRequired,
};

AnalyticsDimensionSelect.defaultProps = {
  dimension: "camera",
  apiQuery: {},
  setApiQuery: () => {},
};

export default AnalyticsDimensionSelect;