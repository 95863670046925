import * as React from "react";

import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function UserRole({ value, handleChange }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="standard">
            <InputLabel>Funktion</InputLabel>
            <Select
                name="role"
                label="Funktion im Unternehmen"
                value={value}
                onChange={handleChange}
                size="small"
            >
                <MenuItem value="">Bitte auswählen</MenuItem>
                <MenuItem value="billing">Buchhaltung</MenuItem>
                <MenuItem value="publisher">Herausgeber</MenuItem>
                <MenuItem value="marketing">Marketing</MenuItem>
                <MenuItem value="producer">Produzent</MenuItem>
                <MenuItem value="editor">Redaktion</MenuItem>
                <MenuItem value="developer">Software Entwickler</MenuItem>
                <MenuItem value="technical">Techniker</MenuItem>
                <MenuItem value="supporter">Technischer Support</MenuItem>
            </Select>
        </FormControl>
    );
}

UserRole.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

UserRole.defaultProps = {
    value: "",
};

export default UserRole;
