import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import {
    HashRouter as Router,
    createRoutesFromChildren,
    matchRoutes,
    Routes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as ReactDOMClient from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

import { AppProvider } from "./context/App";

// grafana
import {
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
    ReactRouterVersion,
    FaroErrorBoundary,
} from "@grafana/faro-react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

const ApiClientUrl = "https://cm.livespotting.com/api";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

// grafana
initializeFaro({
    url: "https://faro-collector-prod-eu-west-0.grafana.net/collect/c0bb8f283ce382146095c782d825974e",
    app: {
        name: "cm.livespotting.com",
        version: "1.0.0",
        environment: "production",
    },

    instrumentations: [
        ...getWebInstrumentations(),
        new TracingInstrumentation(),

        new ReactIntegration({
            router: {
                version: ReactRouterVersion.V6,
                dependencies: {
                    createRoutesFromChildren,
                    matchRoutes,
                    Routes,
                    useLocation,
                    useNavigationType,
                },
            },
        }),
    ],
});

root.render(
    <Router future={{ v7_startTransition: true }}>
        <StyledEngineProvider>
            <ThemeProvider theme={theme}>
                <AppProvider address={ApiClientUrl}>
                    <FaroErrorBoundary>
                        <CssBaseline />
                        <App />
                    </FaroErrorBoundary>
                </AppProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </Router>,
);

reportWebVitals();
